import React from 'react';
import ReactModal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { isVideo } from '../../../../utils/export';

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999'
  },
  content: {
    width: '80%',
    margin: 'auto',
    overflow: 'scroll',
    borderRadius: '0px'
  }
};

class PreviewLibraryMediaModal extends React.Component {
  render() {
    const { isOpen, onRequestClose, media } = this.props;
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={modalStyle}
      >
        <div>
          <Container fluid>
            <Row>
              <h3 className="m-0 ml-3">Preview Media</h3>
              <a
                href={media.substring(media.indexOf('/uploads'))}
                type={`video/${media.split('.')[1]}`}
                className="btn btn-primary d-inline-block ml-auto mb-3 mr-3 border"
                download={media.substring(media.lastIndexOf('-') + 1)}
              >
                Download Media
              </a>
            </Row>
            <Row
              style={{
                border: '1px solid black',
                height: '28rem',
                margin: '0 auto',
                overflow: 'hidden'
              }}
            >
              <Col xs={12} className="px-0">
                <div style={{ height: '32rem' }}>
                  {isVideo(media) ? (
                    <video
                      src={media}
                      style={{
                        width: '100%',
                        height: '95%',
                        objectFit: 'cover'
                      }}
                      controls
                    />
                  ) : (
                    <img
                      src={media}
                      alt="Preview of media"
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          <div className="my-3 ml-2">
            <button className="btn mx-2 btn-primary" onClick={onRequestClose}>
              Close
            </button>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default withRouter(PreviewLibraryMediaModal);
