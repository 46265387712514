import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProgramCard } from '../../../components/export';
import css from './Dashboard.module.css';
import { plus } from '../../../assets/icons/export';
import { withRouter } from 'react-router-dom';
import { request } from '../../../utils/export';
class Dashboard extends React.Component {
  state = {
    programs: []
  };
  componentDidMount() {
    request('/admins/programs', this.props)
      .then(json => {
        this.setState({
          programs: json.data.programData
        });
      })
      .catch(err => console.error(err));
  }

  render() {
    const { programs } = this.state;
    return <Content programs={programs} />;
  }
}
const Content = ({ programs }) => {
  return (
    <Container fluid className="mb-5">
      <Row nogutters="true" style={{ marginTop: '4rem' }}>
        <Col xs={12}>
          <div className="d-flex justify-content-between">
            <h6>
              <strong>Current Programs</strong>
            </h6>
            <Link
              className="btn btn-light border"
              to="/admin/dashboard/create-program"
            >
              <h6 className="m-0">
                <img
                  src={plus}
                  style={{ width: '0.5rem', marginRight: '0.5rem' }}
                  alt="Plus sign"
                />
                Create New
              </h6>
            </Link>
          </div>
          <hr />
          <Container className="m-0 p-0">
            <Row nogutters="true" className="m-0 p-0">
              {programs.map((program, key) => {
                const {
                  title,
                  logo,
                  createdAt,
                  status,
                  numParticipants,
                  id
                } = program;
                return (
                  <Col xs={12} lg={6} className="px-4" key={key}>
                    <ProgramCard
                      title={title}
                      image={logo}
                      date={createdAt}
                      status={status}
                      numParticipants={numParticipants}
                      isAdmin
                      programId={id}
                    />
                  </Col>
                );
              })}
              <p className="g-text-mute mb-2 text-center w-100">Create New</p>
              <Link
                className="btn btn-outline-primary w-100 px-4"
                to="/admin/dashboard/create-program"
              >
                <img src={plus} className={css.icon} alt="Plus sign" />
              </Link>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Dashboard);
