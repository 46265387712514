import React, { Component } from 'react';
import { GlobalContext } from '../../../global/GlobalContext';
import { Redirect } from 'react-router-dom';

export default class Home extends Component {
  static contextType = GlobalContext;

  render() {
    return <Redirect to="/participant/login" />;
  }
}
