import React from 'react';
import { Container, Row } from 'react-bootstrap';

export default function FullPageBackdrop(props) {
  const { children, image } = props;
  return (
    <Container
      fluid
      // Override the style if a style prop exists
      style={Object.assign(
        {
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '90vh',
          maxWidth: '98vw',
          marginBottom: '1rem',
          display: 'flex'
        },
        props.style
      )}
    >
      <Row className="flex-grow-1 p-0">{children}</Row>
    </Container>
  );
}
