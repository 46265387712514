import React from 'react';
import css from './ContinueButton.module.css';
// import { arrow, arrowLeft } from '../../assets/icons/export';
import { arrowLeft } from '../../assets/icons/export';
import { withRouter } from 'react-router-dom';

const ContinueButton = props => {
  return (
    <div
      to={props.to}
      className={`${!props.noMarginTop} float-right g-link ${
        css.transparentButton
      }`}
      style={{
        ...props.style,
        border: '1px solid #9B9AA8',
        padding: '0.3rem',
        borderRadius: '6px'
      }}
      onClick={() => {
        props.history.push(props.to);
        window.location.reload();
      }}
    >
      <span className={css.buttonText} style={{ marginRight: '0.5rem' }}>
        Continue
      </span>
      <img
        src={arrowLeft}
        className={css.convertSvgToPurpleFill}
        style={{
          width: '0.5rem',
          transform: 'rotate(180deg)',
          marginRight: '0.25rem'
        }}
        alt="Arrow"
      />{' '}
    </div>
  );
};

export default withRouter(ContinueButton);
