import React from 'react';
import ReactModal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap';
import { EditorState } from 'draft-js';
import { withRouter } from 'react-router-dom';
import { request, readRichText } from '../../../../utils/export';
import SelectionModal from './SelectionModal';
import { Editor } from '../../../../components/export';
class PreviewContentModal extends React.Component {
  state = {
    contentLabel: '',
    text: EditorState.createEmpty(),
    type: '',
    title: '',
    media: ''
  };

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.contentId !== newProps.contentId) {
      this.setState({ contentId: newProps.contentId }, () => {
        const { contentId, sessionId } = this.props;
        if (contentId && sessionId) {
          request(
            `/admins/sessions/${sessionId}/content/${contentId}/get`,
            this.props
          ).then(json => {
            if (json) {
              json = json.data.contentData;
              const { text, type, title, media } = json;
              this.setState({
                type,
                title,
                text: readRichText(text),
                media: media ? media : ''
              });
            }
          });
        }
      });
    }
  }

  renderContent = (type, media, text) => {
    switch (type) {
      case 'half text':
        return <HalfText text={text} media={media} />;
      case 'full text':
        return <FullText text={text} media={media} />;
      case 'three fourths text':
        return <ThreeFourthsText media={media} text={text} />;
      case 'quote':
        return <Quote media={media} text={text} />;
      case 'video':
        return <Video media={media} />;
      default:
        return (
          <Col className="d-flex justify-content-center align-content-center">
            <h3 className="d-inline-block m-auto">
              Please choose a slide type!
            </h3>
          </Col>
        );
    }
  };

  render() {
    const {
      isOpen,
      onRequestClose,
      isSelectionModalOpen,
      openSelectionModal,
      onSelectionRequestClose
    } = this.props;
    const { contentLabel, text, type, media, title } = this.state;

    const { renderContent } = this;

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999'
          },
          content: {
            width: '80%',
            margin: 'auto',
            overflow: 'hidden',
            borderRadius: '0px'
          }
        }}
      >
        <div>
          <div>
            <p
              className="d-inline-block"
              style={{
                fontSize: '1.5em',
                marginBottom: '1rem',
                marginLeft: '3.75rem'
              }}
            >
              {title}
            </p>
            <button
              className="btn btn-primary float-right mr-5"
              onClick={openSelectionModal}
            >
              Use Template
            </button>
            <Container fluid>
              <Row
                style={{
                  border: '1px solid black',
                  height: '28rem',
                  margin: '0 auto',
                  width: '90%',
                  overflowX: 'hidden',
                  overflowY: 'hidden'
                }}
              >
                {renderContent(type, media, text)}
              </Row>
            </Container>
          </div>
          <div className="my-3" style={{ marginLeft: '3.5rem' }}>
            <button className="btn mx-2 btn-primary" onClick={onRequestClose}>
              Close
            </button>
          </div>
        </div>
        <SelectionModal
          isOpen={isSelectionModalOpen}
          onRequestClose={onSelectionRequestClose}
          type={type}
          title={title}
          text={text}
        />
      </ReactModal>
    );
  }
}

const FullText = props => {
  const { text } = props;
  return (
    <Col
      xs={12}
      className="d-flex position-relative pl-2 pr-0"
      style={{
        height: '100%'
      }}
    >
      <Editor toolbarHidden readOnly editorState={text} />
    </Col>
  );
};

const ThreeFourthsText = props => {
  const { text, media } = props;
  return (
    <>
      <Col xs={3} className="px-0 d-flex position-relative">
        <img
          src={media}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt="Content media"
        />
      </Col>
      <Col
        xs={9}
        className="pl-2 pr-0"
        style={{
          height: '100%'
        }}
      >
        <Editor toolbarHidden readOnly editorState={text} />
      </Col>
    </>
  );
};

const HalfText = props => {
  const { text, media } = props;
  return (
    <>
      <Col xs={6} className="px-0 d-flex">
        <img
          src={media}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt="Content media"
        />
      </Col>
      <Col
        xs={6}
        className="pl-2 pr-0"
        style={{
          height: '100%'
        }}
      >
        <Editor toolbarHidden readOnly editorState={text} />
      </Col>
    </>
  );
};

const Quote = props => {
  const { text, media } = props;

  return (
    <Col xs={12} className="px-0 d-flex position-relative">
      <Container className="px-0" fluid>
        <img
          src={media}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            objectFit: 'cover'
          }}
          alt="Content media"
        />
        <Row
          noGutters
          style={{ height: '100%', margin: '2.5rem 0', overflow: 'hidden' }}
        >
          <Col
            xs={{ offset: 6, span: 6 }}
            style={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              maxHeight: '22.5rem'
            }}
          >
            <div
              className="mx-auto d-inline-block pl-2 pr-0"
              style={{
                height: '100%'
              }}
            >
              <Editor toolbarHidden readOnly editorState={text} />
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

const Video = props => {
  const { media } = props;
  return (
    <Col xs={12} className="px-0 d-flex position-relative pb-2">
      <video
        src={media}
        style={{
          width: '100%',
          height: '90%',
          objectFit: 'cover'
        }}
        controls
      />
    </Col>
  );
};

export default withRouter(PreviewContentModal);
