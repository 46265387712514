import React from 'react';
import ReactModal from 'react-modal';

class DeleteModal extends React.Component {
  state = {
    isConfirmed: false
  };

  render() {
    const {
      isOpen,
      contentLabel,
      contentText,
      onRequestClose,
      contentStyle,
      overlayStyle,
      onDelete
    } = this.props;
    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        onRequestClose={onRequestClose}
        style={{
          overlay: Object.assign(
            {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '9999'
            },
            overlayStyle
          ),
          content: Object.assign(
            {
              width: '30%',
              height: '40%',
              margin: 'auto',
              display: 'flexbox'
            },
            contentStyle
          )
        }}
      >
        <div className="text-center mt-5 font-weight-normal">
          <p>{contentText}</p>
          <div className="mx-auto mt-3">
            <button
              className="btn btn-outline-primary d-inline-block mx-4"
              onClick={onRequestClose}
            >
              Cancel
            </button>
            {!this.state.isConfirmed ? (
              <button
                className="btn btn-danger d-inline-block mx-4"
                onClick={() => this.setState({ isConfirmed: true })}
              >
                Delete
              </button>
            ) : (
              <button
                className="btn btn-danger d-inline-block mx-4"
                onClick={() => {
                  onDelete();
                  this.setState({ isConfirmed: false });
                }}
              >
                Are You <em>Sure?</em>
              </button>
            )}
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default DeleteModal;
