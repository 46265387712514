import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { caret } from '../../../../assets/icons/export';
import css from './UsersList.module.css';
class UsersList extends React.Component {
  state = {
    title: '',
    numParticipants: '',
    groupData: [],
    exportLink: '',
    programId: ''
  };

  componentDidMount() {
    const programId = this.props.match.params.programId;
    if (
      !['/admins', '/demos'].some(path =>
        this.props.location.pathname.includes(path)
      )
    ) {
      fetch(`/admins/users/programs/${programId}`, {
        method: 'GET'
      })
        .then(res => {
          if (res.ok) return res.json();
          else new Error();
        })
        .then(json => {
          const { numPatients, patientData, title } = json.data;
          this.setState({
            groupData: patientData,
            numParticipants: numPatients,
            exportLink: `/admins/users/programs/${programId}/export`,
            programId,
            title
          });
        });
      // Admins
    } else if (this.props.location.pathname.includes('/admins')) {
      fetch('/admins/users/admin-list', {
        method: 'GET'
      })
        .then(res => {
          if (res.ok) return res.json();
          else new Error();
        })
        .then(json => {
          const { numAdmins, adminData } = json.data;
          this.setState({
            title: 'Platform Administrators',
            numParticipants: numAdmins,
            groupData: adminData,
            exportLink: '/admins/users/admin-list/export',
            programId: 'admins'
          });
        });
      // Demos
    } else if (this.props.location.pathname.includes('/demos')) {
      fetch('/admins/users/demo-list', {
        method: 'GET'
      })
        .then(res => {
          if (res.ok) return res.json();
          else new Error();
        })
        .then(json => {
          const { numDemo, demoData } = json.data;
          this.setState({
            title: 'Demo Users',
            numParticipants: numDemo,
            groupData: demoData,
            exportLink: '/admins/users/demo-list/export',
            programId: 'demos'
          });
        });
    }
  }

  render() {
    const {
      groupData,
      title,
      numParticipants,
      exportLink,
      programId
    } = this.state;
    const { table } = css;
    return (
      <Container fluid style={{ marginTop: '4rem' }}>
        <Row nogutters="true">
          <Col xs={12}>
            <div className="d-flex align-content-center justify-content-between">
              <div className="d-flex align-content-center">
                <button
                  className="btn p-0 mr-1"
                  onClick={() => this.props.history.push(`/admin/manage-users`)}
                >
                  <span className="font-weight-normal">
                    <img
                      src={caret}
                      style={{
                        width: '0.75rem',
                        transform: 'rotate(180deg)',
                        marginRight: '0.25rem',
                        marginBottom: '1px'
                      }}
                      alt="Back arrow"
                    />
                    Back
                  </span>
                </button>
                <h6 className="m-0" style={{ lineHeight: '2rem' }}>
                  / User Administration / <strong>{title}</strong>
                </h6>
              </div>
              <a
                href={exportLink}
                className={`text-danger align-self-center ${css.fontBold}`}
                download
              >
                Export All Data Here to CSV
              </a>
            </div>
            <hr />
            <h6 className="my-3">
              <strong className="g-text-color">
                Total Users: {numParticipants}
              </strong>
            </h6>
            <Table className={table}>
              <tbody>
                {groupData.map((user, key) => {
                  return (
                    <User
                      userId={user.id}
                      programId={programId}
                      name={user.name}
                      date={user.createdAt}
                      key={key}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

const User = props => {
  const { programId, userId, name, date } = props;
  const { row, cell } = css;
  return (
    <tr className={row}>
      <td className={cell}>{name}</td>
      <td className={cell} style={{ width: '40rem' }}>
        Sign Up Date: {date}
      </td>
      <td className={cell} style={{ width: '10rem' }}>
        <Link to={`/admin/manage-users/users-list/${programId}/${userId}`}>
          <button className="btn btn-light btn-sm border px-5">View</button>
        </Link>
      </td>
    </tr>
  );
};

export default UsersList;
