import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import CarouselCard from './components/CarouselCard';
import { request } from '../../../utils/export';
import { plus } from '../../../assets/icons/export';
import UploadModal from './components/UploadModal';
import PreviewMediaModal from './components/PreviewMediaModal';
import { NotificationManager as Notification } from 'react-notifications';

export default class Images extends React.Component {
  state = {
    images: [],
    uploadedImages: [],
    uploadModalOpen: false,
    isMediaPreviewModalOpen: false,
    currentActiveMedia: '',
    progressState: [],
    isUploading: false
  };

  openUploadModal = () => this.setState({ uploadModalOpen: true });

  closeUploadModal = () =>
    this.setState({
      isUploading: false,
      progressState: [],
      uploadedImages: [],
      uploadModalOpen: false
    });

  componentDidMount = () => {
    request('/admins/media-library/images/get', this.props).then(json =>
      this.setState({ images: json.images })
    );
  };

  uploadImage = images => {
    let uploadedImages = this.state.uploadedImages;
    images.forEach(image => {
      return uploadedImages.push(image);
    });
    this.setState({ uploadedImages });
  };

  openMediaPreviewModal = e => {
    this.setState({
      isMediaPreviewModalOpen: true,
      currentActiveMedia: e.target.src
    });
  };

  closeMediaPreviewModal = e => {
    this.setState({ isMediaPreviewModalOpen: false });
  };

  submitImages = () => {
    const { uploadedImages } = this.state;
    if (uploadedImages.length < 1)
      return Notification.error('No images were uploaded');
    const successMessage = `Your image${
      uploadedImages.length > 1 ? 's were' : ' was'
    } successfully uploaded!`;
    /** @type {Array<{uploadVariantState: 'info' | 'success' | 'danger', progress: number}>} */
    const progress = uploadedImages.map(() => {
      return { uploadVariantState: 'info', progress: 0 };
    });
    this.setState({ progressState: progress });
    Promise.all(
      uploadedImages.map((image, index) => {
        let data = new FormData();
        data.append('media', image);
        let lastProgress = 0;
        // eslint-disable-next-line no-undef
        return axios
          .request({
            method: 'post',
            url: '/admins/media-library/new',
            data: data,
            onUploadProgress: e => {
              lastProgress = progress[index].progress;
              const p = Math.round(e.progress * 100);
              progress[index].progress = p;
              this.setState({ progressState: progress });
            }
          })
          .then(res => {
            if (res.data === 'OK') {
              progress[index].uploadVariantState = 'success';
              this.setState({ progressState: progress });
            }
            return res;
          })
          .catch(err => {
            progress[index].progress = lastProgress;
            progress[index].uploadVariantState = 'danger';
            this.setState({ progressState: progress });
          });
      })
    ).then(res => {
      res.forEach(v => {
        if ((v || {}).data === 'OK') {
          Notification.success(successMessage);
        } else Notification.error('An error occurred');
      });
    });
    // .finally(() => setTimeout(() => {
    //   this.setState({isUploading: false})
    //   return window.location.reload();
    // }, 1000));
    this.setState({ isUploading: true });
  };

  render() {
    const {
      images,
      uploadedImages,
      uploadModalOpen,
      isMediaPreviewModalOpen,
      currentActiveMedia
    } = this.state;

    const {
      openUploadModal,
      closeUploadModal,
      submitImages,
      uploadImage,
      openMediaPreviewModal,
      closeMediaPreviewModal
    } = this;
    return (
      <Container fluid style={{ margin: '4rem 0' }}>
        <Row nogutters="true">
          <Col xs={12}>
            <div className="d-flex align-content-center">
              <h6 className="d-inline-block">
                <strong>Images</strong>
              </h6>
              <button
                className="btn btn-light d-inline-block border ml-auto mr-5"
                onClick={openUploadModal}
              >
                <h6 className="m-0">
                  <img
                    src={plus}
                    style={{ width: '0.5rem', marginRight: '0.5rem' }}
                    alt="Plus sign"
                  />
                  Add New
                </h6>
              </button>
            </div>
            <hr className="mr-5" />
            {images ? (
              images.map((image, key) => {
                const { media } = image;
                return (
                  <CarouselCard
                    media={media}
                    key={key}
                    onClick={openMediaPreviewModal}
                  />
                );
              })
            ) : (
              <div
                className="d-flex pt-3 pr-3 align-items-center"
                style={{ width: '100%', height: '7.5rem' }}
              >
                <h5 className="d-inline-block mx-auto">
                  There is no content here yet!
                </h5>
              </div>
            )}
          </Col>
        </Row>
        <UploadModal
          isOpen={uploadModalOpen}
          onRequestClose={closeUploadModal}
          uploadMedia={uploadImage}
          uploadedMedia={uploadedImages}
          submitMedia={submitImages}
          progressState={this.state.progressState}
          isUploading={this.state.isUploading}
        />
        <PreviewMediaModal
          isOpen={isMediaPreviewModalOpen}
          onRequestClose={closeMediaPreviewModal}
          media={currentActiveMedia}
        />
      </Container>
    );
  }
}
