import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ContentCard from './ContentCard/ContentCard';
import SessionCard from './SessionCard/SessionCard';
import { caret } from '../../../../assets/icons/export';
import { NotificationManager as Notification } from 'react-notifications';
import { DeleteModal } from '../../../../components/export';
import { request, handleError, renderMedia } from '../../../../utils/export';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
  CreateContentModal,
  EditContentModal,
  ViewContentModal
} from '../../../../components/export';

const contentText = type =>
  `
    Are you sure you want to delete this ${type}?
    This action cannot be undone and your work will
    be lost forever.
  `;

export default class EditSession extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }
  state = {
    programId: this.props.match.params.programId,
    sessionId: this.props.match.params.sessionId,
    contentId: '',
    deleteSessionModalOpen: false,
    deleteContentModalOpen: false,
    createContentModalOpen: false,
    editContentModalOpen: false,
    viewContentModalOpen: false,
    programData: {
      title: ''
    },
    sessionData: {
      title: '',
      logo: '',
      createdAt: '',
      status: ''
    },
    mediaLibraryState: {},
    contentData: [],
    originalContentDataOrder: []
  };

  componentWillMount() {
    // this._isMounted = true;
    let mediaLibraryState;
    if (this.props.location.state) {
      // Save this so that we can send it to the CreateContentModal
      mediaLibraryState = this.props.location.state;
      const { createContentModalOpen, text, title, type } = mediaLibraryState;
      this.setState({
        createContentModalOpen,
        mediaLibraryState: { text, title, type }
      });
      // Do some cleanup
      this.props.history.replace({ state: {} });
    }
  }

  componentDidMount() {
    const { programId, sessionId } = this.state;
    request(
      `/admins/programs/${programId}/sessions/${sessionId}`,
      this.props,
      'GET'
    ).then(json => {
      const { programData, sessionData, contentData } = json.data;
      const originalContentDataOrder = contentData.map(d => d.id);
      return this.setState({
        programData,
        sessionData,
        contentData,
        originalContentDataOrder
      });
    });
  }

  componentWillUnmount = () => (this._isMounted = false);

  // Sessions
  deleteSession = () => {
    const { programId, sessionId } = this.state;
    const successMessage = 'Session was successfully deleted.';
    request(
      `/admins/programs/${programId}/sessions/${sessionId}/delete`,
      this.props,
      'DELETE',
      successMessage,
      { sessionId }
    ).then(
      res =>
        res.ok &&
        this.props.history.push(`/admin/dashboard/edit-program/${programId}`)
    );
    this.setState({ deleteSessionModalOpen: false });
  };

  handleSessionDeleteModalOpen = () => {
    this.setState({ deleteSessionModalOpen: true });
  };

  switchStatus = () => {
    const { programId, sessionId, sessionData } = this.state;
    const successMessage = `Session status was switched to ${
      sessionData.status === 'active' ? 'inactive' : 'active'
    }`;
    request(
      `/admins/programs/${programId}/sessions/${sessionId}/status`,
      this.props,
      'PUT',
      successMessage
    ).then(res => {
      if (res.ok) {
        let newSessionData = sessionData;
        newSessionData.status =
          newSessionData.status === 'active' ? 'inactive' : 'active';
        return this.setState({ sessionData: newSessionData });
      }
    });
  };

  // Content
  deleteContent = () => {
    const { sessionId, contentId } = this.state;
    const successMessage = 'Content was successfully deleted.';
    request(
      `/admins/sessions/${sessionId}/content/${contentId}/delete`,
      this.props,
      'DELETE',
      successMessage,
      { contentId }
    )
      .then(res => {
        if (res.ok) {
          return this.removeContentFromState(contentId);
        } else new Error();
      })
      .catch(err => {
        handleError(err, this.props);
        return Notification.error(
          'An error occurred while deleting your session.'
        );
      });
    this.setState({ deleteContentModalOpen: false });
  };

  clearModals = () =>
    this.setState({
      deleteSessionModalOpen: false,
      deleteContentModalOpen: false,
      createContentModalOpen: false,
      editContentModalOpen: false,
      viewContentModalOpen: false
    });

  handleContentViewModalOpen = contentId =>
    this.setState({
      viewContentModalOpen: true,
      contentId
    });

  handleContentEditModalOpen = contentId =>
    this.setState({
      editContentModalOpen: true,
      contentId
    });

  handleContentDeleteModalOpen = contentId =>
    this.setState({
      deleteContentModalOpen: true,
      contentId
    });

  handleContentCreateModalOpen = e => {};

  removeContentFromState = contentId => {
    this.setState({
      contentData: this.state.contentData.filter(item => item.id !== contentId)
    });
  };

  onSortEnd = movementData => {
    const { oldIndex, newIndex } = movementData;
    this.setState(({ contentData }) => ({
      contentData: arrayMove(contentData, oldIndex, newIndex)
    }));
    this.updateContentOrder();
  };

  updateContentOrder = () => {
    const { originalContentDataOrder, sessionId, programId } = this.state;
    const newContentDataOrder = this.state.contentData.map(d => d.id);
    if (
      JSON.stringify(newContentDataOrder) !==
      JSON.stringify(originalContentDataOrder)
    ) {
      request(
        `/admins/programs/${programId}/sessions/${sessionId}/move`,
        this.props,
        'PUT',
        null,
        {
          contentOrder: newContentDataOrder
        }
      );
    }
  };

  render() {
    const { title, logo, status, createdAt } = this.state.sessionData;
    const { programData, contentData, sessionId, programId } = this.state;
    const {
      deleteSession,
      handleSessionDeleteModalOpen,
      deleteContent,
      handleContentViewModalOpen,
      handleContentEditModalOpen,
      handleContentDeleteModalOpen,
      switchStatus
    } = this;
    return (
      <Container fluid style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Row>
          <Col xs={12}>
            <div className="d-flex align-content-center">
              <button
                className="btn p-0 mr-1"
                onClick={() =>
                  this.props.history.push(
                    `/admin/dashboard/edit-program/${programId}`
                  )
                }
              >
                <span className="font-weight-normal">
                  <img
                    src={caret}
                    style={{
                      width: '0.75rem',
                      transform: 'rotate(180deg)',
                      marginRight: '0.25rem',
                      marginBottom: '1px'
                    }}
                    alt="Back arrow"
                  />
                  Back
                </span>
              </button>
              <h6 className="m-0" style={{ lineHeight: '2rem' }}>
                / Current Programs / {programData.title} /{' '}
                <strong>Edit {title}</strong>
              </h6>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <SessionCard
                image={logo}
                title={title}
                status={status}
                date={createdAt}
                switchStatus={switchStatus}
              />
              <div className="align-self-center">
                <button
                  className="btn btn-primary px-4 mr-5"
                  onClick={() =>
                    this.setState({ createContentModalOpen: true })
                  }
                >
                  <h6 className="m-0">New Slide</h6>
                </button>
              </div>
            </div>

            {/* Create Content Modal */}
            <CreateContentModal
              isOpen={this.state.createContentModalOpen}
              contentLabel="Create content"
              onRequestClose={() =>
                this.setState({ createContentModalOpen: false })
              }
              ariaHideApp={false}
              contentId={this.state.contentId}
              sessionId={this.state.sessionId}
              mediaLibraryState={this.state.mediaLibraryState}
              clearModals={this.clearModals}
            />

            {/* Edit Content Modal */}
            <EditContentModal
              isOpen={this.state.editContentModalOpen}
              contentLabel="Edit content"
              onRequestClose={() =>
                this.setState({ editContentModalOpen: false })
              }
              ariaHideApp={false}
              contentId={this.state.contentId}
              sessionId={this.state.sessionId}
            />

            {/* View Content Modal */}
            <ViewContentModal
              isOpen={this.state.viewContentModalOpen}
              contentLabel="View content"
              onRequestClose={() =>
                this.setState({ viewContentModalOpen: false })
              }
              ariaHideApp={false}
              contentId={this.state.contentId}
              sessionId={this.state.sessionId}
            />

            {/* Delete Session Modal */}
            <DeleteModal
              isOpen={this.state.deleteSessionModalOpen}
              contentLabel="Delete session"
              onRequestClose={() =>
                this.setState({ deleteSessionModalOpen: false })
              }
              onDelete={() => deleteSession()}
              ariaHideApp={false}
              contentText={contentText('session')}
            />
            {/* Delete Content Modal */}
            <DeleteModal
              isOpen={this.state.deleteContentModalOpen}
              contentLabel="Delete content"
              onRequestClose={() =>
                this.setState({ deleteContentModalOpen: false })
              }
              onDelete={() => deleteContent()}
              ariaHideApp={false}
              contentText={contentText('content')}
            />
          </Col>
        </Row>
        <SortableList
          onSortEnd={this.onSortEnd}
          axis="xy"
          programId={programId}
          sessionId={sessionId}
          contentData={contentData}
          handleContentViewModalOpen={handleContentViewModalOpen}
          handleContentEditModalOpen={handleContentEditModalOpen}
          handleContentDeleteModalOpen={handleContentDeleteModalOpen}
        />
        <button
          className="btn btn-link text-danger py-0"
          onClick={e => handleSessionDeleteModalOpen(e)}
        >
          <h6>Delete Session</h6>
        </button>
      </Container>
    );
  }
}

const SortableList = SortableContainer(props => {
  const {
    sessionId,
    programId,
    contentData,
    handleContentViewModalOpen,
    handleContentEditModalOpen,
    handleContentDeleteModalOpen
  } = props;
  return (
    <Row className="mt-5">
      {contentData.map((c, index) => {
        let isVideo = false;
        if (c.media) {
          if (
            c.media.split('.')[1] === 'mov' ||
            c.media.split('.')[1] === 'mp4'
          )
            isVideo = true;
        }
        return (
          <SortableItem
            contentId={c.id}
            sessionId={sessionId}
            programId={programId}
            title={c.title}
            media={isVideo ? c.media : renderMedia(c.media)}
            isVideo={isVideo}
            placement={index}
            index={index}
            key={index}
            handleContentViewModalOpen={handleContentViewModalOpen}
            handleContentEditModalOpen={handleContentEditModalOpen}
            handleContentDeleteModalOpen={handleContentDeleteModalOpen}
          />
        );
      })}
    </Row>
  );
});

const SortableItem = SortableElement(props => {
  const {
    contentId,
    sessionId,
    programId,
    title,
    media,
    placement,
    isVideo,
    handleContentViewModalOpen,
    handleContentEditModalOpen,
    handleContentDeleteModalOpen
  } = props;

  return (
    <Col lg={3} sm={4} xs={12}>
      <ContentCard
        contentId={contentId}
        sessionId={sessionId}
        programId={programId}
        index={placement}
        title={title}
        image={media}
        isVideo={isVideo}
        handleDeleteModalOpen={() => handleContentDeleteModalOpen(contentId)}
        handleEditModalOpen={() => handleContentEditModalOpen(contentId)}
        handleViewModalOpen={() => handleContentViewModalOpen(contentId)}
      />
    </Col>
  );
});
