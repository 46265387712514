import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
class ManageUsers extends React.Component {
  state = {
    isLoading: false,
    numAdmin: '',
    numDemo: '',
    programData: []
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    fetch('/admins/users', {
      method: 'GET'
    })
      .then(res => {
        if (res.ok) return res.json();
        else new Error();
      })
      .then(json => {
        const { numAdmin, numDemo, programData } = json.data;
        this.setState({
          numAdmin,
          numDemo,
          programData
        });
      })
      .catch(err => {
        NotificationManager.error('Something went wrong.');
      });
    this.setState({ isLoading: false });
  }
  render() {
    const { numAdmin, numDemo, programData } = this.state;
    return (
      <Container fluid style={{ marginTop: '3.8rem' }}>
        <div className="d-flex align-content-center justify-content-between">
          <div className="d-flex align-content-center">
            <h6
              className="m-0 font-weight-normal font-weight-bold"
              style={{ lineHeight: '2rem' }}
            >
              User Administration
            </h6>
          </div>
          <a
            href="/admins/users/export"
            className="text-danger font-weight-normal"
            download
          >
            Export All User Data to CSV
          </a>
        </div>
        <hr />
        <Row nogutters="true">
          {/* Hard coded values (except numUsers) */}
          <Col md={4} xs={8}>
            <ProgramInfoCard
              programId="admins"
              title="Platform Administrators"
              numParticipants={numAdmin}
            />
          </Col>
          <Col md={4} xs={8}>
            <ProgramInfoCard
              programId="demos"
              title="Demo Users"
              numParticipants={numDemo}
            />
          </Col>
          {programData.map((program, key) => {
            return (
              <Col md={4} xs={8} key={key}>
                <ProgramInfoCard
                  programId={program.id}
                  title={program.title}
                  numParticipants={program.numParticipants}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  }
}

const ProgramInfoCard = props => {
  const { programId, title, numParticipants } = props;
  return (
    <div
      style={{
        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
        margin: '1.5rem',
        padding: '1rem',
        backgroundColor: 'white'
      }}
    >
      <h6>{title}</h6>
      <span>
        <strong>Total Users: {numParticipants}</strong>
      </span>
      <Link to={`/admin/manage-users/users-list/${programId}`}>
        <button className="btn btn-primary btn-sm d-block mt-2 px-4">
          View Users
        </button>
      </Link>
    </div>
  );
};

export default ManageUsers;
