import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import css from './UserDetails.module.css';
import { caret } from '../../../../assets/icons/export';

const ViewAnalytics = props => {
  const { row, cell } = css;
  const { analytics, showAll, analyticsIndex } = props;
  const analyticsElement = analytics[analyticsIndex];
  const analyticsProperties = [];
  for (const [key, value] of Object.entries(analyticsElement)) {
    if (key === 'assessmentSubmissions') {
      analyticsProperties.push(
        value.map(vm => {
          return (
            <div key={`${key}_${vm.submission.name}`}>
              <p>Assessment[{vm.submission.name}]:</p>
              <p className='pl-2 m-0'>Session ID: {vm.sessionId}</p>
              <p className='pl-2 m-0'>Content ID: {vm.sessionId}</p>
              <p className='p-1 pl-2'>Answers:</p>
              <p>
                {Object.entries(vm.submission)
                  .filter(v => v[0] !== 'name')
                  .map(m => {
                    return (
                      <p className='pl-3 m-0'>
                        {m[0]}: {m[1]}
                      </p>
                    );
                  })}
              </p>
            </div>
          );
        })
      );
    } else {
      analyticsProperties.push(
        <p key={key}>
          {key}: {value}
        </p>
      );
    }
  }

  return (
    <Container fluid style={{ marginTop: '4rem' }}>
      <Row nogutters="true">
        <Col xs={12}>
          <div className="d-flex align-content-center justify-content-between">
            <div className="d-flex align-content-center">
              <button className="btn p-0 mr-1" onClick={() => showAll()}>
                <span className="font-weight-normal">
                  <img
                    src={caret}
                    style={{
                      width: '0.75rem',
                      transform: 'rotate(180deg)',
                      marginRight: '0.25rem',
                      marginBottom: '1px'
                    }}
                    alt="Back arrow"
                  />
                  Back
                </span>
              </button>
            </div>
          </div>
          <hr />
          <Table responsive>
            <tbody>
              <tr className={row}>
                <td className={cell}>
                  {analyticsProperties.map(
                    analyticsProperty => analyticsProperty
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewAnalytics;
