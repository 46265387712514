import React from 'react';
import { Link } from 'react-router-dom';
import CarouselCard from './CarouselCard';
import { scrollbarHide } from './Carousel.module.css';
import { isVideo as video } from '../../../../utils/export';
const Carousel = ({ type, data, isTemplate, onClick, isVideo }) => (
  <div className="pt-2">
    <div className="d-flex">
      <span className="mr-auto font-weight-normal">{type}</span>
      <Link
        className="ml-auto g-link"
        style={{ marginRight: '4.5rem', textDecoration: 'underline' }}
        to={`/admin/media-library/${type.toLowerCase()}`}
      >
        View All
      </Link>
    </div>
    <div
      className={`d-flex ${scrollbarHide}`}
      style={{
        overflowX: 'scroll',
        maxWidth: '95%'
      }}
    >
      {data.length > 0 ? (
        data.map((d, key) => {
          return (
            <CarouselCard
              media={d.media}
              title={d.title}
              type={d.type}
              text={d.text}
              sessionId={d.sessionId}
              contentId={d._id}
              key={key}
              isTemplate={isTemplate}
              onClick={onClick}
              isVideo={d.media && video(d.media)}
            />
          );
        })
      ) : (
        <div
          className="d-flex pt-3 pr-3 align-items-center"
          style={{ width: '100%', height: '7.5rem' }}
        >
          <h5 className="d-inline-block mx-auto">
            There is no content here yet!
          </h5>
        </div>
      )}
    </div>
    <hr className="mr-5" />
  </div>
);

export default Carousel;
