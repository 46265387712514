import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Container, Row, Col, Navbar as NavbarBS } from 'react-bootstrap';
import css from './DashboardLayout.module.css';
import contentSidebar from './ContentSidebar.module.css';
import {
  home,
  user,
  mediaLibrary,
  video,
  quote,
  arrowLeft,
  questionnaire
} from '../../assets/icons/export';
import { slide as Menu } from 'react-burger-menu';

const adminLinks = [
  {
    to: '/admin/dashboard',
    icon: home,
    name: 'Home'
  },
  {
    to: '/admin/manage-users',
    icon: user,
    name: 'Users'
  },
  {
    to: '/admin/media-library',
    icon: mediaLibrary,
    name: 'Media Library'
  }
];

const miniAdminLinks = [
  {
    to: '/admin/media-library/videos',
    name: 'Videos'
  },
  {
    to: '/admin/media-library/images',
    name: 'Images'
  },
  {
    to: '/admin/media-library/templates',
    name: 'Templates'
  }
];

const burgerStyles = {
  bmBurgerButton: {
    position: 'relative',
    overflow: 'auto',
    left: window.innerWidth <= 768 ? '1rem' : '1rem',
    top: window.innerWidth <= 768 ? '6rem' : '4.5rem'
  },
  bmCrossButton: {
    marginRight: '1rem'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '25%',
    background: '#43425d',
    marginTop: '3.6rem'
  },
  bmMenu: {},
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: { marginBottom: '24rem' },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};

class DashboardLayout extends React.Component {
  state = {
    inAdmin: this.props.location.pathname.includes('/admin'),
    sidebarOpen: false
  };

  renderLayout = () => {
    const { inAdmin } = this.state;
    const { pathname } = this.props.location;
    if (inAdmin && !pathname.includes('create-program')) {
      return (
        <Row nogutters="true" style={{ minHeight: '90vh' }} className="p-0">
          <Col
            xs={3}
            md={pathname.includes('/content') ? 3 : 2}
            className={`px-0 d-none d-sm-block ${
              inAdmin ? css.navbar : contentSidebar.navbar
            }`}
            style={{ position: 'fixed', height: '100%' }}
          >
            <NavbarBS collapseOnSelect expand="xs" className="p-0">
              <ul className={contentSidebar.navList}>
                {adminLinks.map((l, key) => {
                  const { to, icon, name } = l;
                  return (
                    <AdminSidebarLink
                      to={to}
                      icon={icon}
                      name={name}
                      key={key}
                    />
                  );
                })}
                {this.props.location.pathname.includes('media-library') &&
                  miniAdminLinks.map((link, key) => (
                    <MiniAdminSidebarLink
                      to={link.to}
                      name={link.name}
                      key={key}
                    />
                  ))}
              </ul>
            </NavbarBS>
          </Col>
          <Col
            md={
              pathname.includes('/content')
                ? { span: 9, offset: 3 }
                : { span: 10, offset: 2 }
            }
            sm={{ span: 9, offset: 3 }}
            xs={{ span: 12 }}
            className="mt-3"
          >
            <div className={css.wrapper}>{this.props.children}</div>
          </Col>
        </Row>
      );
    } else if (inAdmin) {
      return <div className={css.wrapper}>{this.props.children}</div>;
    } else
      return (
        <Row nogutters="true" style={{ minHeight: '90vh' }} className="p-0">
          <Menu
            onClick={() => this.setState({ sidebarOpen: true })}
            styles={burgerStyles}
            customBurgerIcon={<BurgerToggle />}
            burgerButtonClassName={css.burgerButton}
          >
            {this.props.dashboardLinks.map((l, key) => {
              const {
                programId,
                sessionId,
                contentId,
                isLocked,
                title,
                type
              } = l;
              return (
                <PatientSidebarLink
                  to={`/participant/program/${programId}/session/${sessionId}/content/${contentId}`}
                  current={this.props.match.params.contentId === contentId}
                  isLocked={isLocked}
                  title={title}
                  type={type}
                  index={key}
                  key={key}
                />
              );
            })}
          </Menu>
          <Col xs={{ span: 12, offset: 0 }} className="p-0">
            <div className={css.wrapper}>{this.props.children}</div>
          </Col>
        </Row>
      );
  };

  render() {
    const { pathname } = this.props.location;
    const { renderLayout } = this;
    return (
      <Container
        fluid
        className={pathname.includes('create-program') && 'px-0'}
      >
        {renderLayout()}
      </Container>
    );
  }
}

const BurgerToggle = () => (
  <div className={`btn btn-primary btn-sm ${css.btnBurger}`}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem'
      }}
    >
      <img
        src={arrowLeft}
        style={{
          width: '0.75rem',
          height: '0.75rem',
          display: 'inline-block',
          margin: '0 auto'
        }}
        alt="Arrow"
      />
      <span style={{ marginLeft: '0.5rem' }}>Menu</span>
    </div>
  </div>
);

// For presenting patient content
const PatientSidebarLink = props => {
  const { isLocked, to, type, title, current } = props;
  const navLinkIconStyle = {
    fontSize: '0.75rem',
    display: 'block',
    marginLeft: '1.5rem',
    textTransform: 'capitalize',
    textAlign: 'left'
  };

  const determineImageIcon = type => {
    if (
      [
        'full text',
        'half text',
        'three fourths text',
        'quote',
        'custom columns'
      ].includes(type)
    )
      return quote;
    else if (type === 'video') return video;
    else if (type === 'assessment') return questionnaire;
    else new Error(`"type" provided (${type}) was not a valid value`);
  };

  const ActiveNavLink = withRouter(props => {
    const { icon, type, title, to } = props;
    return (
      <button
        className={css.navLink}
        style={{
          margin: 0,
          borderTop: current ? '1px solid #9B9AA8' : 'none',
          borderBottom: current ? '1px solid #9B9AA8' : 'none',
          borderLeft: current ? '8px solid white' : 'none',
          backgroundColor: current ? '#67667C' : '#43425D'
        }}
        to={to}
        onClick={() => {
          props.history.push(to);
          // window.location.reload();
        }}
      >
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <span
            className="mr-2"
            role="img"
            aria-label="green checkmark"
            style={{ color: 'transparent', textShadow: '0 0 0 green' }}
          >
            ✔️
          </span>
          <span>{title}</span>
        </div>
        <span style={navLinkIconStyle}>
          <img
            src={icon}
            className={css.contentTypeIcon}
            alt="Content type icon"
          />
          {type === 'custom columns' ? 'Column Slide' : type}
        </span>
      </button>
    );
  });

  const InactiveNavLink = props => {
    const { icon, type, title } = props;
    return (
      <div className={css.navLink} style={{ margin: 0 }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <span className="mr-2" role="img" aria-label="red circle">
            ⭕
          </span>
          <span>{title}</span>
        </div>
        <span style={navLinkIconStyle}>
          <span>
            <img
              src={icon}
              className={css.contentTypeIcon}
              alt="Content type icon"
            />
          </span>
          {type === 'custom columns' ? 'Column Slide' : type}
        </span>
      </div>
    );
  };

  return (
    <li className={css.navItem}>
      {!isLocked ? (
        <ActiveNavLink
          icon={determineImageIcon(type)}
          type={type}
          to={to}
          title={title}
        />
      ) : (
        <InactiveNavLink
          icon={determineImageIcon(type)}
          type={type}
          title={title}
        />
      )}
    </li>
  );
};

// For presenting admin routes
const AdminSidebarLink = props => {
  const { to, icon, name } = props;
  return (
    <li className={css.navItem}>
      <NavLink
        className={css.navLink}
        activeClassName={css.navLinkActive}
        to={to}
      >
        <span>
          <img src={icon} alt="Sidebar link icon" className={css.navLinkIcon} />
        </span>
        {name}
      </NavLink>
    </li>
  );
};

// Media Library Dropdown
// For presenting admin routes
const MiniAdminSidebarLink = props => {
  const { to, name } = props;
  return (
    <li className={css.navItem}>
      <NavLink
        className={css.navLink}
        activeClassName={css.navLinkActive}
        style={{ paddingLeft: '2.65rem' }}
        to={to}
      >
        {name}
      </NavLink>
    </li>
  );
};
export default withRouter(DashboardLayout);
