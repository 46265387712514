import React from 'react';
import css from './ProgramCard.module.css';
import { defaultLogo } from '../../../../../utils/export';

const ProgramCard = props => {
  const { image, name, date, numParticipants, switchStatus } = props;
  let status = props.status.toLowerCase();
  const {
    imageStyle,
    nameStyle,
    spanStyle,
    textWrapper,
    markInactiveStyle
  } = css;
  return (
    <div className="d-flex">
      <img
        className={`${imageStyle} align-self-center`}
        src={image ? image : defaultLogo}
        alt="Program logo"
      />
      <div className={textWrapper}>
        <h6 className={nameStyle}>{name}</h6>
        <span className={spanStyle}>
          Status:{' '}
          <span style={{ color: status === 'active' ? 'green' : 'red' }}>
            {status}
          </span>
        </span>
        <span className={spanStyle}>Created On: {date}</span>
        <span className={spanStyle}>
          Enrolled Participants: {numParticipants}
        </span>
        <span
          className={`btn btn-sm text-danger ${markInactiveStyle}`}
          onClick={() => switchStatus()}
        >
          Change program status
        </span>
      </div>
    </div>
  );
};

export default ProgramCard;
