import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import ContentForm from './ContentForm';
import LogoFileDrop from '../CreateProgram/LogoFileDrop';

export default class CreateContent extends React.Component {
  state = {
    sessionId: this.props.match.params.sessionId,
    title: '',
    description: '',
    type: '',
    media: '',
    text: '',
    redirect: false
  };

  submit = e => {
    e.preventDefault();
    const { title, description, media, type, sessionId, text } = this.state;
    if (!title || !media || !type || !sessionId || !text)
      return NotificationManager.error('One of the inputs is empty.');
    const data = new FormData();
    data.append('media', media);
    data.append('title', title);
    data.append('description', description);
    data.append('text', text);
    data.append('type', type);
    fetch(`/admins/sessions/${sessionId}/content/new`, {
      method: 'POST',
      body: data
    })
      .then(res => {
        if (res.ok) {
          NotificationManager.success(
            'Your new content was successfully created.'
          );
          return this.props.history.goBack();
        } else new Error();
      })
      .catch(err => {
        NotificationManager.error(
          'Something went wrong with creating this content.'
        );
      });
  };

  updateMedia = media => {
    this.setState({ media });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    return this.setState({
      [name]: value
    });
  };

  render() {
    const { updateMedia, handleChange, submit } = this;
    return (
      <Container fluid>
        <Row>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <div style={{ margin: '3rem' }}>
              <LogoFileDrop updateLogo={updateMedia} />
              <ContentForm onChange={handleChange} submit={submit} />
              <div
                className="d-flex justify-content-center"
                style={{ margin: '0 10rem' }}
              >
                <button
                  className="btn text-danger mx-5"
                  onClick={this.props.history.goBack}
                >
                  Cancel
                </button>
                <button className="btn btn-primary mx-5" onClick={submit}>
                  Finish
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
