import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { GlobalContextProvider } from './global/GlobalContext';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-notifications/lib/notifications.css';
import './global/global.css';
import { RenderRoutes } from './routes/export';
import { NotificationContainer } from 'react-notifications';
import BrowserInteractionTime from './utils/timetrackerglobal';

const history = createBrowserHistory();

ReactDOM.render(
  <BrowserInteractionTime>
    <GlobalContextProvider history={history}>
      <RenderRoutes />
      <NotificationContainer />
    </GlobalContextProvider>
  </BrowserInteractionTime>,
  document.getElementById('root')
);
