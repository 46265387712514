import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from './components/Carousel';
import { request } from '../../../utils/export';
import PreviewMediaModal from './components/PreviewMediaModal';
import PreviewContentModal from './components/PreviewContentModal';

export default class MediaLibrary extends Component {
  state = {
    videos: [],
    images: [],
    templates: [],
    showAll: true,
    showVideos: false,
    showImages: false,
    showTemplates: false,
    currentActiveMedia: '',
    currentProgramId: '',
    currentSessionId: '',
    currentContentId: '',
    isMediaPreviewModalOpen: false,
    isContentPreviewModalOpen: false,
    isSelectionModalOpen: false,
    isCurrentFocusTemplate: false
  };

  componentDidMount() {
    request('/admins/media-library/get', this.props).then(json => {
      const { videos, images, templates } = json.data.contentData;
      this.setState({ videos, images, templates });
    });
  }

  openMediaPreviewModal = e => {
    this.setState({
      isMediaPreviewModalOpen: true,
      currentActiveMedia: e.target.src
    });
  };

  closeMediaPreviewModal = e => {
    this.setState({ isMediaPreviewModalOpen: false });
  };

  openContentPreviewModal = e => {
    const contentId = e.target.getAttribute('contentid');
    const sessionId = e.target.getAttribute('sessionid');

    this.setState({
      isContentPreviewModalOpen: true,
      currentSessionId: sessionId,
      currentContentId: contentId
    });
  };

  closeContentPreviewModal = e => {
    this.setState({ isContentPreviewModalOpen: false });
  };

  openSelectionModal = e => {
    this.setState({
      isSelectionModalOpen: true
    });
  };

  closeSelectionModal = e => {
    this.setState({ isSelectionModalOpen: false });
  };

  render() {
    const {
      videos,
      images,
      templates,
      isMediaPreviewModalOpen,
      isContentPreviewModalOpen,
      isSelectionModalOpen,
      currentActiveMedia,
      currentSessionId,
      currentContentId
    } = this.state;
    const {
      openMediaPreviewModal,
      closeMediaPreviewModal,
      openContentPreviewModal,
      closeContentPreviewModal,
      openSelectionModal,
      closeSelectionModal
    } = this;
    return (
      <Container fluid style={{ marginTop: '4rem' }}>
        <Row nogutters="true">
          <Col xs={12}>
            <div className="d-flex align-content-center">
              <h6>
                <strong>Media Library</strong>
              </h6>
            </div>
            <hr className="mr-5" />
            <div>
              <Carousel
                data={images}
                isTemplate={false}
                type="Images"
                onClick={openMediaPreviewModal}
              />
              <Carousel
                data={videos}
                isTemplate={false}
                type="Videos"
                onClick={openMediaPreviewModal}
                isVideo={true}
              />
              <Carousel
                data={templates}
                isTemplate={true}
                type="Templates"
                onClick={openContentPreviewModal}
              />
              <PreviewMediaModal
                isOpen={isMediaPreviewModalOpen}
                onRequestClose={closeMediaPreviewModal}
                media={currentActiveMedia}
                selectionIsOpen={isSelectionModalOpen}
                onSelectionRequestClose={closeSelectionModal}
              />
              <PreviewContentModal
                isOpen={isContentPreviewModalOpen}
                onRequestClose={closeContentPreviewModal}
                isSelectionModalOpen={isSelectionModalOpen}
                openSelectionModal={openSelectionModal}
                onSelectionRequestClose={closeSelectionModal}
                sessionId={currentSessionId}
                contentId={currentContentId}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
