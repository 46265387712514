import { NotificationManager as Notification } from 'react-notifications';

const handleError = (err, props, message) => {
  try {
    const currentPath = props.location.pathname;
    const inAdmin = currentPath.includes('/admin');
    switch (err) {
      case 401:
        if (currentPath.includes('login')) {
          dispatchError(message, 'Your login credentials are invalid');
          return inAdmin
            ? redirect(props, '/admin/login')
            : redirect(props, '/participant/login');
        } else if (currentPath.includes('signup')) {
          dispatchError(message, 'Your invite code is invalid');
          return;
        } else {
          dispatchError(message, 'Please log in to view this page');
          return inAdmin
            ? redirect(props, '/admin/login')
            : redirect(props, '/participant/login');
        }
      case 409:
        dispatchError(message, 'This email address already exists');
        return err;
      default:
        return dispatchError(message, 'An error occurred.');
    }
  } catch (err) {
    switch (err.name) {
      case 'TypeError':
        console.error(`${err.name}: props was not passed into handleError`);
        break;
      default:
        dispatchError(`${err.name}: ${err.message}`);
    }
  }
};

const redirect = (props, path) => props.history.push(path);

const dispatchError = (message, defaultMessage) =>
  Notification.error(message ? message : defaultMessage);

export { handleError };
