import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VideoPlayer from './VideoPlayer';
import 'video-react/dist/video-react.css';
import { ContinueButton } from '../../../../components/export';

export default class Video extends Component {
  render() {
    const { media, to } = this.props;
    return (
      <Container
        fluid
        style={{
          aspectRatio: 16 / 9,
          width: '85%',
          marginBottom: '1rem',
          marginTop: '5rem',
          display: 'flex'
        }}
      >
        <Row className="flex-grow-1 p-0">
          <Col xs={12}>
            <div style={{ width: '80%', margin: 'auto' }}>
              <VideoPlayer video={media} />
              <ContinueButton to={to} style={{ margin: '0.5rem 0' }} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
