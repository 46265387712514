import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import css from './UserDetails.module.css';
import { caret } from '../../../../assets/icons/export';
import { NotificationManager } from 'react-notifications';
import ViewAnalytics from './ViewAnalytics';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';

class UserDetails extends React.Component {
  state = {
    userId: this.props.match.params.userId,
    userData: {
      firstName: '',
      lastName: '',
      role: '',
      createdAt: '',
      email: '',
      timeInApp: '',
      lastLogin: '',
      programName: '',
      analytics: []
    },
    isLoading: false,
    showAnalytics: false,
    analyticsIndex: 0,
    showDeleteModal: false
  };

  showAll = () => {
    this.setState({ showAnalytics: false });
  };
  showOnlyAnalytics = index => {
    this.setState({ showAnalytics: true, analyticsIndex: index });
  };

  showConfirmation = () => {
    this.setState({ showDeleteModal: true });
  };

  deleteUser() {
    const successMessage = `User "${this.state.userData.firstName +
      ' ' +
      this.state.userData.lastName}" was successfully deleted!`;
    fetch(`/admins/users/user/${this.state.userId}/delete`, {
      method: 'POST'
    }).then(res => {
      if (res.ok) {
        NotificationManager.success(successMessage);
        return (window.location = '/admin/manage-users');
      } else {
        NotificationManager.error('An error occurred');
      }
    });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    fetch(`/admins/users/user/${this.state.userId}`, {
      method: 'GET'
    })
      .then(res => {
        if (res.ok) return res.json();
        else new Error();
      })
      .then(json => {
        const { userData } = json.data;
        this.setState({ userData });
      })
      .catch(err => {
        NotificationManager.error('An error occurred.');
      });
    this.setState({ isLoading: false });
  }

  render() {
    const {
      firstName,
      lastName,
      role,
      createdAt,
      email,
      timeInApp,
      lastLogin,
      programName,
      analytics
    } = this.state.userData;
    const { userId, showAnalytics, analyticsIndex } = this.state;
    const { row, cell } = css;
    const analyticsInfo = analytics.map((analyticsItem, index) =>
      analyticsItem.login ? (
        <tr className={row} key={analyticsItem.login}>
          <td className={cell}>
            <p>
              {analyticsItem.login}{' '}
              <button
                className="btn btn-outline-primary btn-sm m-2 font-weight-normal"
                onClick={() => {
                  this.showOnlyAnalytics(index);
                }}
              >
                View
              </button>
            </p>
          </td>
        </tr>
      ) : (
        false
      )
    );

    const exportLink = `/admins/users/user/${userId}/export`;
    return (
      <>
        {!showAnalytics ? (
          <Container fluid style={{ marginTop: '4rem' }}>
            <Row nogutters="true">
              <Col xs={12}>
                <div className="d-flex align-content-center justify-content-between">
                  <div className="d-flex align-content-center">
                    <button
                      className="btn p-0 mr-1"
                      onClick={() => this.props.history.goBack()}
                    >
                      <span className="font-weight-normal">
                        <img
                          src={caret}
                          style={{
                            width: '0.75rem',
                            transform: 'rotate(180deg)',
                            marginRight: '0.25rem',
                            marginBottom: '1px'
                          }}
                          alt="Back arrow"
                        />
                        Back
                      </span>
                    </button>
                    <h6 className="m-0" style={{ lineHeight: '2rem' }}>
                      / User Administration / {programName} /{' '}
                      <strong>
                        {firstName} {lastName}
                      </strong>
                    </h6>
                  </div>
                  <a
                    href={exportLink}
                    className="text-danger align-self-center font-weight-normal"
                    download
                  >
                    Export User Data CSV
                  </a>
                </div>
                <hr />
                <Table responsive>
                  <tbody>
                    <tr className={row}>
                      <td className={cell}>
                        <h5>
                          <strong>
                            {firstName} {lastName}
                          </strong>
                        </h5>
                        <p>
                          User ID: <strong>{userId}</strong>
                        </p>
                        <p>
                          User Type: <strong>{role}</strong>
                        </p>
                        <p>
                          Sign Up Date: <strong>{createdAt}</strong>
                        </p>
                        <p>
                          Email: <strong>{email}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr className={row}>
                      <td className={cell}>
                        <h5>
                          <strong>Usage Data</strong>
                        </h5>
                        <p>Total Time Spent In App: {timeInApp}</p>
                        <p>Last Login: {lastLogin}</p>
                      </td>
                    </tr>
                    <tr className={row}>
                      <td className={cell}>
                        <a
                          href={exportLink}
                          className="text-danger align-self-center font-weight-normal"
                          download
                        >
                          Export User Data CSV
                        </a>
                        <button
                          onClick={this.showConfirmation}
                          className="btn btn-link text-danger d-block my-2 p-0 font-weight-normal"
                        >
                          Delete User
                        </button>
                      </td>
                    </tr>
                    {analyticsInfo}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <DeleteModal
              isOpen={this.state.showDeleteModal}
              contentLabel={'Delete User?'}
              contentText={`This action will delete the user with email: ${this.state.userData.email}.`}
              onDelete={() => this.deleteUser()}
              onRequestClose={() => this.setState({ showDeleteModal: false })}
              ariaHideApp={false}
            />
          </Container>
        ) : null}
        {showAnalytics ? (
          <ViewAnalytics
            analytics={analytics}
            showAll={this.showAll}
            analyticsIndex={analyticsIndex}
          />
        ) : null}
      </>
    );
  }
}

export default UserDetails;
