import React from 'react';
import css from './FileDrop.module.css';
import { useDropzone } from 'react-dropzone';
import { defaultLogo } from '../../utils/export';
// TODO: Add logic to turn video upload into video player after upload succeeds

const BackgroundFileDrop = props => {
  const { fileDropWrapper, fileDrop, logoStyle } = css;
  const {
    description,
    buttonText,
    overrideWrapperStyle,
    overrideUploadBoxStyle,
    media,
    isVideo
  } = props;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    // TODO: Add more video file type support
    accept: isVideo
      ? 'video/mp4, video/mov'
      : 'image/jpeg, image/png, image/jpg',
    maxSize: isVideo ? '500000000' : '5000000',
    multiple: false,
    // When the upload is successful, update state
    onDrop: logo => props.updateMedia(logo[0])
  });

  const renderUploadedImg = file => (
    <img
      key={file ? file.path : defaultLogo}
      src={file ? URL.createObjectURL(file) : defaultLogo}
      className={logoStyle}
      alt="Logo"
    />
  );

  const renderOriginalImg = image => (
    <img
      key={image ? image : defaultLogo}
      src={image ? image : defaultLogo}
      className={logoStyle}
      alt="Logo"
    />
  );

  const UploadBox = props => {
    const { description, buttonText, overrideUploadBoxStyle } = props;
    return (
      <div
        style={
          overrideUploadBoxStyle
            ? Object.assign({ margin: '0 0.5rem' }, overrideUploadBoxStyle)
            : { margin: '0 0.5rem' }
        }
      >
        <p className="text-center">
          {description
            ? description
            : 'Click or drag and drop a .png, .jpg, or .jpeg file under 5mb to upload an image'}
        </p>

        <span className="btn btn-primary my-auto px-3">
          {buttonText ? buttonText : '+ Add a Background Image'}
        </span>
      </div>
    );
  };

  const renderComponent = (acceptedFiles, media, isVideo) => {
    if (acceptedFiles.length < 1 && !media) {
      return (
        <UploadBox
          description={description}
          buttonText={buttonText}
          overrideUploadBoxStyle={overrideUploadBoxStyle}
        />
      );
    } else if (isVideo) {
      return (
        <video
          src={URL.createObjectURL(acceptedFiles[0])}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          controls
        />
      );
    } else if (acceptedFiles.length > 0) {
      return renderUploadedImg(acceptedFiles[0]);
    } else if (acceptedFiles.length < 1 && media) {
      return renderOriginalImg(media);
    }
  };

  return (
    <div className={`${fileDropWrapper}`} style={overrideWrapperStyle}>
      <div {...getRootProps({ className: `dropzone ${fileDrop}` })}>
        <input {...getInputProps()} />
        {renderComponent(acceptedFiles, media, isVideo)}
      </div>
    </div>
  );
};

export default BackgroundFileDrop;
