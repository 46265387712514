import React from 'react';
import {
  BackdropContainer,
  ContinueButton,
  Editor
} from '../../../../components/export';
import css from '../Content.module.css';
import { Col } from 'react-bootstrap';

// Component renders simple text quote with background image
const HalfText = props => {
  const { media, text, to } = props;
  return (
    <BackdropContainer image={media}>
      <Col
        xs={{ span: 6, offset: 6 }}
        className={`d-flex align-items-center p-0 ${css.shadow}`}
      >
        <div className={`${css.halfTextBoxWrapper}`}>
          <div className={`${css.textBox}`}>
            <Editor readOnly toolbarHidden editorState={text} transparent />
          </div>
          <div style={{ display: 'inline-block' }}>
            <ContinueButton to={to} />
          </div>
        </div>
      </Col>
    </BackdropContainer>
  );
};

export default HalfText;
