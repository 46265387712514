import React from 'react';
import css from './Checkbox.module.css';

const Checkbox = props => (
  <div
    className={`d-flex align-items-center justify-content-center ${
      css.container
    }`}
  >
    <input
      type="checkbox"
      name={props.name}
      id="checkbox"
      onChange={props.onChange}
      checked={props.checked}
    />
    <label htmlFor="checkbox" className={css.checkmark} />
    <span> {props.label} </span>
  </div>
);

export default Checkbox;
