import React from 'react';
import {
  BackdropContainer,
  ContinueButton,
  Editor
} from '../../../../components/export';
import css from '../Content.module.css';
import { Col } from 'react-bootstrap';
import { defaultLogo } from '../../../../utils/export';

// Component renders simple text quote with background image
const Quote = props => {
  const { media, text, to } = props;
  return (
    <BackdropContainer image={media ? media : defaultLogo}>
      <Col
        xs={{ span: 6, offset: 6 }}
        className={`d-flex align-items-center p-0`}
      >
        <div className={`${css.quoteTextBoxWrapper}`}>
          <div className={css.textBox}>
            <Editor readOnly toolbarHidden transparent editorState={text} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <ContinueButton to={to} />
          </div>
        </div>
      </Col>
    </BackdropContainer>
  );
};

export default Quote;
