import { defaultLogo } from './defaults';

const renderMedia = media =>
  media ? (media.includes('mp4') ? defaultLogo : media) : defaultLogo;

const isVideo = media => {
  return ['mp4', 'mov'].includes(media.split('.')[1]);
};

export { renderMedia, isVideo };
