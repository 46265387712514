import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CarouselCard from './components/CarouselCard';
import { request } from '../../../utils/export';
import { plus } from '../../../assets/icons/export';
import UploadModal from './components/UploadModal';
import PreviewMediaModal from './components/PreviewMediaModal';
import { NotificationManager as Notification } from 'react-notifications';
export default class Images extends React.Component {
  state = {
    videos: [],
    uploadedVideos: [],
    uploadModalOpen: false,
    isMediaPreviewModalOpen: false,
    currentActiveMedia: '',
    progressState: [],
    isUploading: false
  };
  componentDidMount = () => {
    request('/admins/media-library/videos/get', this.props).then(json =>
      this.setState({ videos: json.videos })
    );
  };

  uploadVideo = videos => {
    let uploadedVideos = this.state.uploadedVideos;
    videos.forEach(video => {
      return uploadedVideos.push(video);
    });
    this.setState({ uploadedVideos });
  };

  submitVideos = () => {
    const { uploadedVideos } = this.state;
    if (uploadedVideos.length < 1)
      return Notification.error('No videos were uploaded');
    const successMessage = `Your video${
      uploadedVideos.length > 1 ? 's were' : ' was'
    } successfully uploaded!`;
    /** @type {Array<{uploadVariantState: 'info' | 'success' | 'danger', progress: number}>} */
    const progress = uploadedVideos.map(() => {
      return { uploadVariantState: 'info', progress: 0 };
    });
    this.setState({ progressState: progress });
    uploadedVideos.forEach(video => {
      let data = new FormData();
      data.append('media', video);
      let lastProgress = 0;
      Promise.all(
        uploadedVideos.map((image, index) => {
          let data = new FormData();
          data.append('media', image);
          // eslint-disable-next-line no-undef
          return axios
            .request({
              method: 'post',
              url: '/admins/media-library/new',
              data: data,
              onUploadProgress: e => {
                lastProgress = progress[index].progress;
                const p = Math.round(e.progress * 100);
                progress[index].progress = p;
                this.setState({ progressState: progress });
              }
            })
            .then(res => {
              if (res.data === 'OK') {
                progress[index].uploadVariantState = 'success';
                this.setState({ progressState: progress });
              }
              return res;
            })
            .catch(err => {
              progress[index].progress = lastProgress;
              progress[index].uploadVariantState = 'danger';
              this.setState({ progressState: progress });
              return err;
            });
        })
      ).then(res => {
        res.forEach(v => {
          if ((v || {}).data === 'OK') {
            Notification.success(successMessage);
          } else Notification.error('An error occurred');
        });
      });
      // .finally(() => this.setState({isUploading: false}));
      this.setState({ isUploading: true });
    });
  };

  openUploadModal = () => this.setState({ uploadModalOpen: true });

  closeUploadModal = () =>
    this.setState({
      isUploading: false,
      progressState: [],
      uploadedImages: [],
      uploadModalOpen: false
    });

  openMediaPreviewModal = e => {
    this.setState({
      isMediaPreviewModalOpen: true,
      currentActiveMedia: e.target.src
    });
  };

  closeMediaPreviewModal = e => {
    this.setState({ isMediaPreviewModalOpen: false });
  };

  render() {
    const {
      videos,
      uploadedVideos,
      uploadModalOpen,
      isMediaPreviewModalOpen,
      currentActiveMedia
    } = this.state;
    const {
      openUploadModal,
      closeUploadModal,
      uploadVideo,
      submitVideos,
      openMediaPreviewModal,
      closeMediaPreviewModal
    } = this;

    return (
      <Container fluid style={{ margin: '4rem 0' }}>
        <Row nogutters="true">
          <Col xs={12}>
            <div className="d-flex align-content-center">
              <h6>
                <strong>Videos</strong>
              </h6>
              <button
                className="btn btn-light d-inline-block border ml-auto mr-5"
                onClick={openUploadModal}
              >
                <h6 className="m-0">
                  <img
                    src={plus}
                    style={{ width: '0.5rem', marginRight: '0.5rem' }}
                    alt="Plus sign"
                  />
                  Add New
                </h6>
              </button>
            </div>
            <hr className="mr-5" />
            {videos.map((video, key) => {
              const { media } = video;
              return (
                <CarouselCard
                  media={media}
                  key={key}
                  isVideo={true}
                  onClick={openMediaPreviewModal}
                />
              );
            })}
          </Col>
        </Row>
        <UploadModal
          isOpen={uploadModalOpen}
          onRequestClose={closeUploadModal}
          uploadMedia={uploadVideo}
          uploadedMedia={uploadedVideos}
          submitMedia={submitVideos}
          progressState={this.state.progressState}
          isUploading={this.state.isUploading}
          buttonText={'+ Add video(s) to the media libary'}
          isVideo
        />
        <PreviewMediaModal
          isOpen={isMediaPreviewModalOpen}
          onRequestClose={closeMediaPreviewModal}
          media={currentActiveMedia}
        />
      </Container>
    );
  }
}
