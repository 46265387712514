import React from 'react';
import {
  BackdropContainer,
  ContinueButton,
  Editor
} from '../../../../components/export';
import css from '../Content.module.css';
import { Col } from 'react-bootstrap';
import { defaultLogo } from '../../../../utils/export';
// Component renders simple text quote with background image
const ThreeFourthsText = props => {
  const { media, text, to } = props;
  return (
    <BackdropContainer image={media ? media : defaultLogo}>
      <Col
        xs={{ span: 9, offset: 3 }}
        className={`d-flex align-items-center px-0 ${css.shadow}`}
      >
        <div className={`${css.threeFourthsTextBoxWrapper}`}>
          <Editor
            readOnly
            toolbarHidden
            transparent
            editorState={text}
            editorClassName={`${css.textBox}`}
          />
          <ContinueButton to={to} />
        </div>
      </Col>
    </BackdropContainer>
  );
};

export default ThreeFourthsText;
