import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { request } from '../../../utils/export';
export default class Contact extends Component {
  state = {
    message: ''
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  submit = e => {
    e.preventDefault();
    const { message } = this.state;
    request(
      '/patients/contact',
      this.props,
      'POST',
      'Your message was successfully sent',
      {
        message
      }
    ).then(res => {
      if (res) return this.props.history.push('/participant/dashboard');
    });
  };

  render() {
    return (
      <Container fluid style={{ marginTop: '4rem' }}>
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-content-center">
              <div style={{ minWidth: '50%' }}>
                <h6
                  className="mt-4 font-weight-normal font-weight-bold"
                  style={{ lineHeight: '2rem' }}
                >
                  Contact Us
                </h6>
                <hr />
                <div className="ml-3">
                  <p>
                    We want to hear from you! Please write your message below
                    and let us know what you have to say.
                  </p>
                  <p>Admins will get back with you within 24 hours.</p>
                  <p>
                    Please include your phone number if you need to speak with
                    someone!
                  </p>
                </div>
                <form>
                  <textarea
                    type="textarea"
                    name="message"
                    maxLength={2000}
                    onChange={this.handleChange}
                    style={{
                      resize: 'none',
                      width: '100%',
                      height: '12rem'
                    }}
                  />
                  <button
                    type="submit"
                    className="btn mt-3"
                    style={{
                      backgroundColor: '#66657C',
                      color: 'white',
                      minWidth: '10rem'
                    }}
                    onClick={this.submit}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
