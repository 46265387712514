import React, { Component } from 'react';
import { DashboardLayout } from '../../../layouts/export';
import FullText from './FullText/FullText';
import Video from './Video/Video';
import HalfText from './HalfText/HalfText';
import ThreeFourthsText from './ThreeFourthsText/ThreeFourthsText';
import CustomColumns from './CustomColumns/CustomColumns';
import Quote from './Quote/Quote';
import Assessment from './Assessment/Assessment';
import { TimeTracker, readRichText } from '../../../utils/export';
import { request } from '../../../utils/export';
import { EditorState } from 'draft-js';

export default class Content extends Component {
  state = {
    programId: this.props.match.params.programId,
    sessionId: this.props.match.params.sessionId,
    contentId: this.props.match.params.contentId,
    contentData: {
      id: '',
      type: '',
      title: '',
      text: EditorState.createEmpty(),
      media: '',
      isLocked: '',
      contentColumns: '',
      assessment: ''
    },
    contentMetadata: []
  };

  componentDidMount() {
    this.updateData();
  }

  updateData = () => {
    const { programId, sessionId, contentId } = this.state;
    request(
      `/patients/programs/${programId}/sessions/${sessionId}/content/${contentId}/get`,
      this.props
    ).then(json => {
      if (json) {
        const { contentData, contentMetadata } = json.data;
        if (contentData.type === 'assessment')
          contentData['assessment'] = JSON.parse(contentData.text).assessmentName;
        return this.setState({
          contentData: {
            id: contentData.id,
            type: contentData.type,
            title: contentData.title,
            contentColumns: contentData.contentColumns,
            text: readRichText(contentData.text),
            media: contentData.media,
            isLocked: contentData.isLocked,
            assessment: contentData.assessment
          },
          contentMetadata
        });
      }
    });
  };

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.contentId !== this.props.match.contentId) {
      this.updateData();
    }
  }

  renderContent = () => {
    const {
      id,
      type,
      title,
      media,
      text,
      contentColumns
    } = this.state.contentData;
    const { programId, sessionId } = this.state;
    const { contentMetadata } = this.state;
    let nextContentIndex =
      contentMetadata.indexOf(contentMetadata.find(d => d.id === id)) + 1;
    let nextContentId =
      contentMetadata[nextContentIndex] && contentMetadata[nextContentIndex].id;
    // reached the end of the content
    if (nextContentIndex === 0) {
      nextContentId = null;
    }
    let to = '';
    if (nextContentId) {
      to = `/participant/program/${programId}/session/${sessionId}/content/${nextContentId}`;
    } else {
      to = `/participant/program/${programId}`;
    }
    switch (type) {
      case 'half text':
        return (
          <HalfText
            type={type}
            title={title}
            media={media}
            text={text}
            to={to}
          />
        );
      case 'three fourths text':
        return (
          <ThreeFourthsText
            type={type}
            title={title}
            media={media}
            text={text}
            to={to}
          />
        );
      case 'full text':
        return (
          <FullText
            title={title}
            media={media}
            type={type}
            text={text}
            to={to}
          />
        );
      case 'video':
        return (
          <Video type={type} title={title} media={media} text={text} to={to} />
        );
      case 'custom columns':
        return (
          <CustomColumns
            media={media}
            text={text}
            to={to}
            contentColumns={contentColumns}
          />
        );
      case 'quote':
        return (
          <Quote type={type} title={title} media={media} text={text} to={to} />
        );
      case 'assessment':
        return (
          <Assessment
            to={to}
            isAdmin
            contentMeta={this.state.contentData}
          />
        );
      default:
        return <div />;
    }
  };

  generateLinks = metadata => {
    const { programId, sessionId } = this.state;
    return metadata.map(d => {
      return {
        programId,
        sessionId,
        contentId: d.id,
        isLocked: d.isLocked,
        title: d.title,
        type: d.type
      };
    });
  };

  render() {
    const { renderContent } = this;
    let dashboardLinks = this.generateLinks(this.state.contentMetadata);
    return (
      <DashboardLayout
        sessionTitle="Taking A Step Back"
        dashboardLinks={dashboardLinks}
      >
        {renderContent()}
        <TimeTracker path={this.props.location.pathname} />
      </DashboardLayout>
    );
  }
}
