import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import css from './Program.module.css';
import defaultLogo from '../../../assets/images/logos/default.jpg';
import { SessionCard } from '../../../components/export';
import TimeTracker from '../../../utils/timetracker';
import { request } from '../../../utils/export';
export default class Program extends Component {
  state = {
    programId: this.props.match.params.programId,
    title: '',
    description: '',
    logo: '',
    background: '',
    sessionData: []
  };

  componentDidMount() {
    request(
      `/patients/programs/${this.state.programId}`,
      this.props,
      'GET'
    ).then(json => {
      const { logo, background, title, description } = json.data.programData;
      const { sessionData } = json.data;
      this.setState({ logo, background, title, description, sessionData });
    });
  }

  render() {
    const {
      sessionData,
      logo,
      description,
      background,
      programId
    } = this.state;
    return (
      <Container
        fluid
        className="px-0 pb-5"
        style={
          window.innerWidth <= 768 &&
          !this.props.location.pathname.includes('/content')
            ? { marginTop: '5rem' }
            : { marginTop: '3.5rem' }
        }
      >
        <Row>
          <Col className="px-0">
            <div style={{ overflow: 'hidden', display: 'block' }}>
              <img
                className={css.background}
                src={background ? background : defaultLogo}
                alt="Background"
              />
              <img
                src={logo ? logo : defaultLogo}
                className={css.logo}
                alt="Program Profile"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-10 offset-1 px-5 d-flex justify-content-center align-items-center flex-column">
            <div className={css.programDescription}>
              <p className="m-0">{description}</p>
            </div>
            {sessionData.length > 0 ? (
              sessionData.map((s, key) => {
                return (
                  <SessionCard
                    sessionId={s.id}
                    programId={programId}
                    contentId={s.contentId}
                    contentIndexReached={s.contentIndexReached}
                    numContent={s.numContent}
                    index={key}
                    title={s.title}
                    icon={s.logo}
                    description={s.description}
                    isLocked={s.isLocked}
                    key={key}
                  />
                );
              })
            ) : (
              <div>No sessions are available yet</div>
            )}
          </div>
        </Row>
        <TimeTracker path={this.props.location.pathname} />
      </Container>
    );
  }
}
