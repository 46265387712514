import { handleError } from './errors';
import { NotificationManager as Notification } from 'react-notifications';

// Defaults: method = "GET", body = {}, optionalHeaders = {}
const request = async (
  resource,
  props,
  method = 'GET',
  successMessage,
  body,
  optionalHeaders = {}
) => {
  const init = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  };
  init.headers = Object.assign(init.headers, optionalHeaders);
  if (method !== 'GET' && body) init.body = JSON.stringify(body);
  return await fetch(resource, init)
    .then(res => {
      if (res.ok) return processRes(res, successMessage);
      else throw res.status;
    })
    .catch(err => handleError(err, props));
};

const processRes = async (res, successMessage) => {
  try {
    const json = await res.json();
    successMessage && Notification.success(successMessage);
    return json;
  } catch (err) {
    // SyntaxErrors are thrown when api doesn't return json response (only status code)
    if (err.name === 'SyntaxError') {
      successMessage && Notification.success(successMessage);
      return res;
    }
    throw err;
  }
};

export { request };
