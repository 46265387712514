import {
  Home,
  PatientLogin,
  PatientSignup,
  PatientForgotPassword,
  AdminLogin,
  AdminSignup,
  AdminForgotPassword,
  ResetPassword
} from '../views/Public/export';

import Sandbox from '../views/Public/Sandbox';

const publicRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/participant/login',
    name: 'Patient Login',
    component: PatientLogin
  },
  {
    path: '/participant/signup',
    name: 'Patient Signup',
    component: PatientSignup
  },
  {
    path: '/participant/forgot-password',
    name: 'Patient Forgot Password',
    component: PatientForgotPassword
  },
  {
    path: '/admin/login',
    name: 'Admin Login',
    component: AdminLogin
  },
  {
    path: '/admin/signup',
    name: 'Admin Signup',
    component: AdminSignup
  },
  {
    path: '/admin/forgot-password',
    name: 'Admin Forgot Password',
    component: AdminForgotPassword
  },
  {
    path: '/reset/:token',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    component: Sandbox
  }
];

export default publicRoutes;
