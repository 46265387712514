import React from 'react';
import ReactModal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap';
import MediaUpload from './MediaUpload';

class UploadModal extends React.Component {
  render() {
    const {
      isOpen,
      onRequestClose,
      uploadMedia,
      uploadedMedia,
      submitMedia,
      isVideo,
      progressState,
      isUploading,
      buttonText
    } = this.props;
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '10000'
          },
          content: {
            width: '80%',
            height: '80%',
            margin: 'auto',
            borderRadius: '0px',
            overflowY: 'scroll'
          }
        }}
      >
        <Container fluid className="h-100 pb-5">
          <Row className="h-100">
            <Col xs={12} className="h-100">
              <div className="mt-2">
                <h3 className="ml-3 mb-0 d-inline-block">Upload Media</h3>
                <button
                  className="btn btn-primary d-inline-block float-right mr-3"
                  onClick={submitMedia}
                >
                  Submit
                </button>
              </div>
              <MediaUpload
                files={uploadedMedia}
                uploadMedia={uploadMedia}
                isVideo={isVideo}
                progressState={progressState}
                isUploading={isUploading}
                buttonText={buttonText || null}
              />
            </Col>
          </Row>
        </Container>
      </ReactModal>
    );
  }
}

export default UploadModal;
