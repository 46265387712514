import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { EditorState, convertToRaw, Modifier } from 'draft-js';
import { EditorState, Modifier } from 'draft-js';
import FileDrop from '../../components/ContentModals/FileDrop';
import Editor from '../../components/Editor/Editor';

const initState = {
  columns: 6,
  media: '',
  text: EditorState.createEmpty()
};

const onTab = (e, currentText) => {
  e.preventDefault();
  const tabCharacter = '   ';
  let newText = Modifier.replaceText(
    currentText.getCurrentContent(),
    currentText.getSelection(),
    tabCharacter
  );
  return newText;
};

export default function Sandbox(props) {
  const [state, setState] = useState(initState);
  // const { columns, text, media } = state;
  const { columns, text } = state;
  return (
    <>
      <Container className="border">
        <Row className="border" style={{ height: '20rem' }}>
          <Col xs={12 - columns} className="px-0 d-flex position-relative">
            <FileDrop updateMedia={media => setState({ ...state, media })} />
          </Col>
          <Col xs={columns} className="border">
            <Editor
              editorState={text}
              onEditorStateChange={text => setState({ ...state, text })}
              onTab={e => {
                const currentText = state.text;
                const newText = onTab(e, text);
                setState({
                  ...state,
                  text: EditorState.push(
                    currentText,
                    newText,
                    'insert-characters'
                  )
                });
              }}
            />
          </Col>
        </Row>
      </Container>
      <h1 className="text-center">Current content column size: {columns}</h1>
      <div style={{ textAlign: 'center' }}>
        <button
          className="mx-2"
          onClick={() => {
            setState({ ...state, columns: state.columns + 1 });
          }}
        >
          Increment
        </button>
        <button
          className="mx-2"
          onClick={() => {
            setState({ ...state, columns: state.columns - 1 });
          }}
        >
          Decrement
        </button>
      </div>
    </>
  );
}
