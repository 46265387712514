import React, { Component } from 'react';
import {
  Player,
  ControlBar,
  FullscreenToggle,
  PlayToggle,
  BigPlayButton,
  ProgressControl,
  VolumeMenuButton
} from 'video-react';

export default class VideoPlayer extends Component {
  render() {
    return (
      <Player fluid className="mx-auto" autoPlay>
        <source src={this.props.video} />
        <BigPlayButton position="center" />
        <ControlBar disableDefaultControls autoHide={true}>
          <PlayToggle />
          <VolumeMenuButton vertical />
          <ProgressControl />
          <FullscreenToggle />
        </ControlBar>
      </Player>
    );
  }
}
