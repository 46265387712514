import React from 'react';
import { Col } from 'react-bootstrap';
import css from './LeftContent.module.css';
import { withRouter } from 'react-router-dom';

const LeftContent = props => {
  let { image, description } = props;
  const { imageStyle, descriptionStyle, wrapper } = css;
  const inAdmin = props.location.pathname.includes('/admin') ? true : false;
  return (
    <Col
      xs={12}
      md={6}
      style={{
        padding: '0',
        background: inAdmin
          ? 'linear-gradient(45deg, rgba(90,85,170,1) 0%, rgba(36,35,72,1) 100%)'
          : 'linear-gradient(45deg, #00274C 0%, #144993 100%)',
        height: '100%'
      }}
    >
      <div
        className={wrapper}
        style={inAdmin ? { padding: '0' } : { padding: '2rem' }}
      >
        {inAdmin ? (
          <img
            src={image}
            style={{
              height: '100%',
              width: '100%',
              alignSelf: 'center',
              objectFit: 'contain',
              opacity: '0.8'
            }}
            alt="Background"
          />
        ) : (
          <img
            src={image}
            className={imageStyle}
            alt="Background"
            style={{ mixBlendMode: 'color-difference' }}
          />
        )}
        {description ? <p className={descriptionStyle}>{description}</p> : null}
      </div>
    </Col>
  );
};

export default withRouter(LeftContent);
