import React from 'react';
import ReactModal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap';
import css from './Edit.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, Modifier } from 'draft-js';
import { toolbarOptions } from '../options.js';
import FileDrop from '../FileDrop';
import { withRouter } from 'react-router-dom';
import { NotificationManager as Notification } from 'react-notifications';
import html2canvas from 'html2canvas';
import { readRichText, request } from '../../../utils/export';
import Editor from '../../Editor/Editor';
import UploadMediaProgressModal from '../../UploadMediaProgressModal/UploadMediaProgressModal';
// import Assessment from '../../../views/Patient/Content/Assessment/Assessment';
import assessments from '../../../global/assessments';

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.captureRef = React.createRef();
    const { contentLabel, contentText } = this.state;
    this.defaultText = { contentLabel, contentText };
  }
  state = {
    isConfirmed: false,
    contentLabel: '',
    contentText: '',
    text: EditorState.createEmpty(),
    type: '',
    title: '',
    media: '',
    assessment: '',
    sessionId: this.props.sessionId,
    contentId: this.props.contentId,
    toolbarHidden: false,
    contentColumns: '',
    uploadContentLabel: 'Submission Progress',
    uploadContentText: 'Upload In Progress',
    isUploading: false,
    isFailed: false,
    uploadProgressState: { uploadVariantState: 'info', progress: 0 }
  };

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.contentId !== newProps.contentId) {
      this.setState({ contentId: newProps.contentId }, () => {
        const { contentId, sessionId } = this.state;
        if (contentId && sessionId) {
          request(
            `/admins/sessions/${sessionId}/content/${contentId}/get`,
            this.props
          ).then(json => {
            if (json) {
              const {
                text,
                type,
                title,
                media,
                contentColumns
              } = json.data.contentData;
              if (text.indexOf('assessmentName'))
                this.setState({ assessment: JSON.parse(text).assessmentName });
              this.setState({
                type,
                title,
                contentColumns,
                text: readRichText(text),
                media: media ? media : ''
              });
            }
          });
        }
      });
    }
  }

  onTab = e => {
    e.preventDefault();
    const tabCharacter = '   ';
    let currentState = this.state.text;
    let newContentState = Modifier.replaceText(
      currentState.getCurrentContent(),
      currentState.getSelection(),
      tabCharacter
    );
    this.setState({
      text: EditorState.push(currentState, newContentState, 'insert-characters')
    });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'type') {
      const isAssessment = e.target.selectedOptions[0].hasAttribute(
        'assessment'
      );
      if (isAssessment) {
        return this.setState({
          [name]: 'assessment',
          assessment: value
        });
      }
    }
    return this.setState({
      [name]: value
    });
  };

  convertToBlob = async canvas => {
    return new Promise(resolve => {
      canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.4);
    });
  };

  setToolbarHidden = async () => {
    return new Promise(resolve => {
      this.setState({ toolbarHidden: true }, () => {
        resolve();
      });
    });
  };

  cancelToken = new AbortController();
  submitModal = async event => {
    const data = new FormData();
    let {
      type,
      title,
      text,
      media,
      contentId,
      sessionId,
      contentColumns
    } = this.state;

    if (
      type === '' ||
      title === '' ||
      text === '' ||
      (type !== 'full text' && type !== 'assessment' && media === '')
    ) {
      return Notification.error('One of your inputs are invalid');
    }

    if (type === 'full text' || type === 'assessment') {
      await this.setToolbarHidden();
      const canvas = await html2canvas(this.captureRef.current);
      const blob = await this.convertToBlob(canvas);
      media = new File(
        [blob],
        `${
          type === 'full text'
            ? 'content'
            : this.state.assessment.toLowerCase() + '_assessment'
        }.jpg`
      );
    }

    data.append('type', type);
    data.append('title', title);
    contentColumns && data.append('contentColumns', contentColumns);
    let editorContent = convertToRaw(text.getCurrentContent());
    if (type === 'assessment')
      editorContent['assessmentName'] = this.state.assessment;
    data.append('text', JSON.stringify(editorContent));
    data.append('media', media);

    const successMessage = `Your content titled "${title}" was successfully edited!`;
    let lastProgress = 0;
    this.setState({ contentLabel: media.name });
    const progress = { ...this.state.uploadProgressState };
    if (this.cancelToken.signal.aborted)
      this.cancelToken = new AbortController();
    // eslint-disable-next-line no-undef
    axios
      .request({
        method: 'put',
        url: `/admins/sessions/${sessionId}/content/${contentId}/edit`,
        data: data,
        signal: this.cancelToken.signal,
        onUploadProgress: e => {
          lastProgress = progress.progress;
          const p = Math.round(e.progress * 100);
          progress.progress = p;
          this.setState({ uploadProgressState: progress });
        }
      })
      .then(res => {
        if (res.data === 'OK') {
          progress.uploadVariantState = 'success';
          this.setState({ uploadProgressState: progress });
          Notification.success(successMessage);
          if (type === 'full text') {
            this.setState({ media: '' });
          }
          if (!this.state.isUploading)
            return window.location.reload();
        } else new Error();
        return res;
      })
      .catch(err => {
        progress.progress = lastProgress;
        progress.uploadVariantState = 'danger';
        this.setState({
          isFailed: true,
          uploadProgressState: progress,
          contentText: `Your submission ${
            err.code !== 'ERR_NETWORK' ? 'was cancelled.' : 'has failed.'
          }`,
          contentLabel: `Submission ${
            err.code !== 'ERR_NETWORK' ? 'CANCELLED' : 'FAILED'
          }`
        });
        Notification.error('Something went wrong with creating this slide.');
        return err;
      });
    this.setState({ isUploading: type !== 'full text' && type !== 'assessment' });
  };

  onEditorStateChange = text => {
    this.setState({ text });
  };

  updateMedia = media => {
    this.setState({ media });
  };

  renderContent = (
    updateMedia,
    onEditorStateChange,
    text,
    type,
    media,
    contentColumns
  ) => {
    switch (type) {
      case 'half text':
        return (
          <HalfText
            updateMedia={updateMedia}
            onEditorStateChange={onEditorStateChange}
            text={text}
            media={media}
            onTab={this.onTab}
          />
        );
      case 'full text':
        return (
          <FullText
            ref={this.captureRef}
            updateMedia={updateMedia}
            onEditorStateChange={onEditorStateChange}
            text={text}
            toolbarHidden={this.state.toolbarHidden}
            onTab={this.onTab}
          />
        );
      case 'three fourths text':
        return (
          <ThreeFourthsText
            updateMedia={updateMedia}
            onEditorStateChange={onEditorStateChange}
            text={text}
            media={media}
            onTab={this.onTab}
          />
        );
      case 'quote':
        return (
          <Quote
            updateMedia={updateMedia}
            onEditorStateChange={onEditorStateChange}
            text={text}
            media={media}
            onTab={this.onTab}
          />
        );
      case 'video':
        return <Video updateMedia={updateMedia} media={media} />;
      case 'custom columns':
        return (
          <CustomColumns
            updateMedia={updateMedia}
            onEditorStateChange={onEditorStateChange}
            text={text}
            onTab={this.onTab}
            contentColumns={contentColumns}
          />
        );
      case 'assessment':
        return (
          <AssessmentRef
            toolbarHidden={this.state.toolbarHidden}
            ref={this.captureRef}
            assessmentName={this.state.assessment}
            title={this.state.title}
          />
        );
      default:
        return (
          <Col className="d-flex justify-content-center align-content-center">
            <h3 className="d-inline-block m-auto">
              Please choose a slide type!
            </h3>
          </Col>
        );
    }
  };

  componentWillUnmount() {
    this.setState({
      isConfirmed: false,
      contentLabel: '',
      contentText: '',
      text: EditorState.createEmpty(),
      type: '',
      title: '',
      media: ''
    });
  }

  render() {
    const { isOpen, onRequestClose, contentStyle } = this.props;

    const {
      contentLabel,
      text,
      type,
      title,
      media,
      contentColumns
    } = this.state;

    const {
      handleChange,
      submitModal,
      updateMedia,
      onEditorStateChange,
      renderContent
    } = this;
    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999'
          },
          content: Object.assign(
            {
              width: '80%',
              margin: 'auto',
              overflow: 'hidden',
              borderRadius: '0px'
            },
            contentStyle
          )
        }}
      >
        <div>
          <form className="d-block">
            <input
              className="d-inline-block g-no-focus"
              name="title"
              style={{
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: 'none',
                fontSize: '1.5rem',
                marginBottom: '1rem',
                marginLeft: '3.5rem'
              }}
              value={title}
              onChange={handleChange}
            />
            <select
              name="type"
              onChange={handleChange}
              defaultValue={type}
              key={Math.floor(Math.random() * 1000)}
              className={`mx-4 ${css.customSelect}`}
            >
              <option>Choose a slide type</option>
              <option disabled>Static</option>
              <option value="half text">1/2 text 1/2 image</option>
              <option value="three fourths text">3/4 text 1/4 image</option>
              <option value="custom columns">Custom columns</option>
              <option value="quote">Quote</option>
              <option value="full text">Text Only</option>
              <option disabled style={{ color: 'purple', fontSize: '1.5rem' }}>
                Media
              </option>
              <option value="video">Video</option>
              <option disabled>Assessments</option>
              {Object.entries(assessments).filter(f => f[0] !== 'TEMPLATE').map(e => (
                <option value={e[0]} key={e[1].shortName}>{e[1].shortName}</option>
              ))}
              {/* <option value="ATQ" assessment="true">
                ATQ
              </option>
              <option value="BADS" assessment="true">
                BADS
              </option>
              <option value="EPDS" assessment="true">
                EPDS
              </option>
              <option
                value="User Engagement Scale Short Form"
                assessment="true"
              >
                User Engagement Scale Short Form
              </option>
              <option value="Therapy Evaluation Form" assessment="true">
                Therapy Evaluation Form
              </option>
              <option value="GAD7" assessment="true">
                GAD7
              </option>
              <option value="UCLA LONELINESS SCALE VERSION 3" assessment="true">
                UCLA LONELINESS SCALE VERSION 3
              </option>
              <option value="PHQ-2" assessment="true">
                PHQ-2
              </option>
              <option value="PHQ-8" assessment="true">
                PHQ-8
              </option>
              <option value="PHQ-9" assessment="true">
                PHQ-9
              </option>
              <option value="Group Cohesiveness Scale" assessment="true">
                Group Cohesiveness Scale
              </option>
              <option
                value="Parenting Sense of Competence Scale"
                assessment="true"
              >
                Parenting Sense of Competence Scale
              </option> */}
            </select>
            {type === 'custom columns' && (
              <div className="d-inline-block">
                <p className="mb-1">
                  Column Ratio {12 - contentColumns}:{contentColumns}
                </p>
                <button
                  className={css.customButton}
                  onClick={e => {
                    e.preventDefault();
                    if (contentColumns < 11)
                      this.setState({ contentColumns: contentColumns + 1 });
                  }}
                >
                  +
                </button>
                <button
                  className={css.customButton}
                  onClick={e => {
                    e.preventDefault();
                    if (contentColumns > 1)
                      this.setState({
                        contentColumns: contentColumns - 1
                      });
                  }}
                >
                  -
                </button>
              </div>
            )}
          </form>
          <div>
            <Container fluid>
              <Row
                style={{
                  border: '1px solid black',
                  height: '28rem',
                  margin: '0 auto',
                  width: '90%',
                  overflow: 'hidden'
                }}
              >
                {renderContent(
                  updateMedia,
                  onEditorStateChange,
                  text,
                  type,
                  media,
                  contentColumns
                )}
              </Row>
            </Container>
          </div>
          <div className="my-3" style={{ marginLeft: '3.5rem' }}>
            <button className="btn btn-primary px-4 mx-2" onClick={submitModal}>
              Save
            </button>
            <button
              className="btn mx-2 g-link-special"
              onClick={onRequestClose}
            >
              Cancel
            </button>
          </div>

          <UploadMediaProgressModal
            isFailed={this.state.isFailed}
            isUploading={this.state.isUploading}
            contentLabel={this.state.uploadContentLabel}
            contentText={this.state.uploadContentText}
            // contentStyle={{}}
            // overlayStyle={{}}
            onCancel={() => {
              if (
                !!this.cancelToken &&
                !this.cancelToken.signal.aborted &&
                !this.state.isFailed &&
                this.state.uploadProgressState.uploadVariantState !== 'success'
              ) {
                this.cancelToken.abort();
                return (this.cancelToken = new AbortController());
              }
              this.setState(
                Object.assign(
                  {
                    isUploading: false,
                    isFailed: false,
                    uploadProgressState: {
                      uploadVariantState: 'info',
                      progress: 0
                    }
                  },
                  this.defaultText
                )
              );
              window.location.reload();
            }}
            progressState={this.state.uploadProgressState}
          />
        </div>
      </ReactModal>
    );
  }
}

const HalfText = props => {
  const { updateMedia, onEditorStateChange, text, media, onTab } = props;
  return (
    <>
      <Col xs={6} className="px-0 d-flex position-relative">
        <FileDrop updateMedia={updateMedia} media={media} />
      </Col>
      <Col
        xs={6}
        style={{
          background: '#ffffffc9',
          padding: '0',
          borderLeft: '1px gray solid',
          height: '100%',
          overflowY: '100%'
        }}
      >
        <Editor
          editorState={text}
          onEditorStateChange={onEditorStateChange}
          onTab={onTab}
        />
      </Col>
    </>
  );
};

const FullText = React.forwardRef((props, ref) => {
  const { onEditorStateChange, text, toolbarHidden, onTab } = props;
  return (
    <Col ref={ref} xs={12} className="px-0 d-flex position-relative">
      <Editor
        toolbarHidden={toolbarHidden}
        toolbar={toolbarOptions}
        editorState={text}
        onEditorStateChange={onEditorStateChange}
        onTab={onTab}
      />
    </Col>
  );
});

const ThreeFourthsText = props => {
  const { updateMedia, onEditorStateChange, text, media, onTab } = props;
  return (
    <>
      <Col xs={3} className="px-0 d-flex position-relative">
        <FileDrop updateMedia={updateMedia} media={media} />
      </Col>
      <Col
        xs={9}
        style={{
          background: '#ffffffc9',
          padding: '0',
          borderLeft: '1px gray solid',
          height: '100%',
          overflowY: '100%'
        }}
      >
        <Editor
          toolbar={toolbarOptions}
          editorState={text}
          onEditorStateChange={onEditorStateChange}
          onTab={onTab}
        />
      </Col>
    </>
  );
};

const CustomColumns = props => {
  const {
    updateMedia,
    onEditorStateChange,
    text,
    onTab,
    contentColumns
  } = props;
  return (
    <>
      <Col
        xs={contentColumns}
        style={{
          background: '#ffffffc9',
          padding: '0',
          borderLeft: '1px gray solid',
          height: '100%'
        }}
      >
        <Editor
          editorState={text}
          onEditorStateChange={onEditorStateChange}
          onTab={onTab}
        />
      </Col>
      <Col xs={12 - contentColumns} className="px-0 d-flex position-relative">
        <FileDrop updateMedia={updateMedia} />
      </Col>
    </>
  );
};

const Quote = props => {
  const { updateMedia, onEditorStateChange, text, media, onTab } = props;
  return (
    <>
      <Col xs={12} className="px-0 d-flex position-relative">
        <FileDrop
          updateMedia={updateMedia}
          media={media}
          overrideWrapperStyle={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            objectFit: 'cover'
          }}
        />
        <div
          style={{
            width: '50%',
            marginLeft: 'auto',
            zIndex: 1,
            padding: '2.5rem 0'
          }}
        >
          <Editor
            toolbar={toolbarOptions}
            editorState={text}
            editorStyle={{
              padding: '0 0.5rem 0.5rem 1rem',
              maxHeight: '30rem'
            }}
            onEditorStateChange={onEditorStateChange}
            onTab={onTab}
          />
        </div>
      </Col>
    </>
  );
};

const Video = props => {
  const { updateMedia } = props;
  return (
    <Col xs={12} className="px-0 d-flex position-relative">
      <FileDrop
        updateMedia={updateMedia}
        isVideo={true}
        buttonText="Upload Video"
        description="Drag and drop or click to upload a .mp4 or .mov file under 500mb"
      />
    </Col>
  );
};

// const AssessmentRef = React.forwardRef((props, ref) => {
//   const { assessmentName, title } = props;
//   // const assess = assessments[assessmentName.toUpperCase()];
//   return (
//     <Col ref={ref} xs={12} className="px-0 d-flex position-relative">
//       <Assessment contentMeta={{ assessment: assessmentName, title: title }} />
//     </Col>
//   );
// });

const AssessmentRef = React.forwardRef((props, ref) => {
  const { assessmentName } = props;
  const assess = assessments[assessmentName.toUpperCase()];
// console.debug(assess);
  return (
      <Col ref={ref} xs={12} className="px-0 d-flex position-relative">
        <Container dangerouslySetInnerHTML={{ __html: assess.overview }} className={`px-0 align-self-center ${css.preview}`} fluid>
        </Container>
      </Col>
  );
  // return (
  //   <Col ref={ref} xs={12} className="px-0 d-flex position-relative">
  //     <Container className="px-0 align-self-center text-center" fluid>
  //       <p
  //         className="assessment-name"
  //         style={{ textDecoration: 'underline' }}
  //         ref={e =>
  //           !!e &&
  //           !!e.style &&
  //           e.style.setProperty('font-weight', 'bold', 'important')
  //         }
  //       >
  //         {assessmentName}
  //       </p>
  //       Introduction:
  //       <br />
  //       <p>{assess.intro}</p>
  //       Ratings:
  //       <p>
  //         {'multipleChoice' in assess
  //           ? 'This is a Multiple Choice Assessment'
  //           : 'multipleRatingChoices' in assess
  //           ? 'This Assessment has different rating options per question.'
  //           : Object.keys(assess.rateMap)
  //               .map(k =>
  //                 assess.rateMap[k] !== '' ? k + '=' + assess.rateMap[k] : ''
  //               )
  //               .join(', ')}
  //       </p>
  //       # of Questions:{' '}
  //       {
  //         (
  //           assess.questions ||
  //           assess.multipleChoice ||
  //           assess.multipleRatingChoices
  //         ).length
  //       }
  //       <br />
  //       <br />
  //       Completion:<p>{assess.outro}</p>
  //     </Container>
  //   </Col>
  // );
});

export default withRouter(Edit);
