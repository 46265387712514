import React, { Component } from 'react';
export default class Session extends Component {
  render() {
    return (
      <>
        <h1 className="mt-5">Hi</h1>Hi
      </>
    );
  }
}
