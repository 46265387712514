import { optionContainer } from './options.module.css';

const options = ['fontSize', 'inline', 'list', 'image'];

const inline = {
  options: ['bold', 'underline', 'italic'],
  bold: {
    className: optionContainer
  },
  underline: {
    className: optionContainer
  },
  italic: {
    className: optionContainer
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
  }
};

const list = {
  options: ['unordered'],
  unordered: { className: optionContainer }
};

const uploadCallback = async file => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve({ data: { link: reader.result } });
    };
  });
};

const image = {
  uploadCallback,
  urlEnabled: false,
  previewImage: true,
  inputAccept: 'image/jpeg,image/jpg,image/png',
  defaultSize: {
    height: '150',
    width: '200'
  }
};

let toolbarOptions = {
  options,
  inline,
  list,
  image
};

export { toolbarOptions };
