import React from 'react';
import { Editor as DefaultEditor } from 'react-draft-wysiwyg';
import { toolbarOptions } from '../ContentModals/options';

const Editor = props => {
  let {
    style,
    readOnly,
    toolbarHidden,
    toolbar,
    editorState,
    wrapperStyle,
    toolbarStyle,
    editorStyle,
    onEditorStateChange,
    onTab,
    transparent
  } = props;

  wrapperStyle = wrapperStyle ? wrapperStyle : {};
  toolbarStyle = toolbarStyle ? toolbarStyle : {};
  editorStyle = editorStyle ? editorStyle : {};
  style = style ? style : {};
  return (
    <DefaultEditor
      onTab={onTab}
      style={Object.assign(style, { paddingLeft: '1rem' })}
      readOnly={readOnly}
      toolbarHidden={toolbarHidden}
      toolbar={toolbar ? toolbar : toolbarOptions}
      editorState={editorState}
      wrapperStyle={Object.assign(wrapperStyle, {
        backgroundColor: transparent ? 'transparent' : 'white',
        height: '100%',
        width: '100%'
      })}
      toolbarStyle={Object.assign(toolbarStyle, {
        padding: '0.3rem',
        marginBottom: '0',
        display: 'flex',
        alignContent: 'center'
      })}
      editorStyle={Object.assign(editorStyle, {
        padding: '0 0.5rem 2rem 1rem',
        backgroundColor: transparent ? 'transparent' : 'white'
      })}
      onEditorStateChange={onEditorStateChange}
      placeholder="Your slide content goes here"
      {...props}
    />
  );
};

export default Editor;
