import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import LeftContent from '../Components/LeftContent/LeftContent';
import { Container, Row, Col } from 'react-bootstrap';
import { isEmail } from 'validator';
import { patientLanding } from '../../../assets/images/export';

export default function PatientForgotPassword(props) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [email, setEmail] = useState('');

  const handleChange = e => setEmail(e.target.value);

  const submit = e => {
    e.preventDefault();
    if (isEmail(email)) {
      fetch('/auth/password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ email })
      })
        // TODO: Turn this into something useful
        .then(res => {
          if (res.ok) return setIsSubmitted(true);
          else throw Error;
        })
        .catch(err => {
          return NotificationManager.error('Something went wrong.');
        });
    } else NotificationManager.error('Email is invalid.');
  };
  return (
    <Container fluid style={{ height: '100%', background: '#FFFFFF' }}>
      <Row style={{ height: '100%' }}>
        <LeftContent image={patientLanding} />
        <Col
          xs={12}
          md={6}
          className="border text-center d-flex align-items-center"
          style={{ height: '100%' }}
        >
          <div className="mx-auto">
            <h1 className="g-header">Entertain Me Well</h1>
            {isSubmitted ? (
              <p className="text-center mt-5">
                Thank you! You should receive an email to reset your password
                soon.
              </p>
            ) : (
              <>
                <p>
                  Enter your email and we will send you a password reset
                  link.
                </p>
                <form onSubmit={submit}>
                  <div className="mx-5">
                    <input
                      type="text"
                      name="email"
                      className="my-3 g-input-box"
                      placeholder="Email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="my-4">
                    <input
                      type="submit"
                      value="Send Request"
                      className="mx-2 btn btn-primary px-5"
                    />
                  </div>
                </form>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
