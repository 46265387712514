import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { ContinueButton, Editor } from '../../../../components/export';
import css from '../Content.module.css';

export default function CustomColumns(props) {
  const { media, text, to, contentColumns } = props;
  return (
    <Container
      style={{
        height: '90vh',
        maxWidth: '98vw',
        marginBottom: '1rem',
        marginTop: '1rem',
        display: 'flex'
      }}
    >
      <Row className="flex-grow-1 p-0">
        <Col
          xs={contentColumns}
          className={`d-flex align-items-center px-0`}
          style={{
            backgroundColor: '#ffffffdf',
            width: '100%'
          }}
        >
          <div className={`${css.customColumnsTextBoxWrapper}`}>
            <Editor
              readOnly
              toolbarHidden
              editorState={text}
              editorClassName={`${css.textBox}`}
            />
            <ContinueButton to={to} />
          </div>
        </Col>
        <Col xs={12 - contentColumns} className="px-0 d-flex position-relative">
          <img
            src={media}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            alt="Content media"
          />
        </Col>
      </Row>
    </Container>
  );
}
