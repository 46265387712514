import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

const readRichText = text => {
  return EditorState.createWithContent(convertFromRaw(JSON.parse(text)));
};

const writeRichText = text => {
  return JSON.stringify(convertToRaw(text.getCurrentContent()));
};

export { readRichText, writeRichText };
