import React from 'react';
import css from './BackgroundFileDrop.module.css';
import { useDropzone } from 'react-dropzone';

const BackgroundFileDrop = props => {
  const { fileDropWrapper, fileDrop } = css;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxSize: '5000000',
    multiple: false,
    // When the upload is successful, update state
    onDrop: logo => props.updateBackground(logo[0])
  });

  const renderBackground = acceptedFiles.map(file => (
    <img
      key={file.path}
      src={URL.createObjectURL(file)}
      className={fileDropWrapper}
      alt="Logo"
    />
  ));

  return (
    <div
      className={fileDropWrapper}
      style={{
        backgroundImage:
          acceptedFiles.length === 0
            ? '#f6f6f6'
            : `url(${URL.createObjectURL(acceptedFiles[0])})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <div {...getRootProps({ className: `dropzone ${fileDrop}` })}>
        <input {...getInputProps()} />
        {acceptedFiles.length === 0 ? <UploadBox /> : renderBackground}
      </div>
    </div>
  );
};

const UploadBox = () => (
  <>
    <p className="text-center">
      Click or drag and drop a .png, .jpg, or .jpeg file under 5mb to upload an
      image
    </p>
    <span className="btn btn-primary my-auto">+ Add a Background Image</span>
  </>
);

export default BackgroundFileDrop;
