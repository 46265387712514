import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import SessionForm from './SessionForm';
import LogoFileDrop from '../CreateProgram/LogoFileDrop';
import UploadMediaProgressModal from '../../../../components/UploadMediaProgressModal/UploadMediaProgressModal';

export default class CreateSession extends React.Component {
  state = {
    programId: this.props.match.params.programId,
    title: '',
    description: '',
    logo: '',
    contentLabel: 'Submission Progress',
    contentText: 'Upload In Progress',
    isUploading: false,
    isFailed: false,
    uploadProgressState: { uploadVariantState: 'info', progress: 0 }
  };

  constructor() {
    super(...arguments);
    const { contentLabel, contentText } = this.state;
    this.defaultText = { contentLabel, contentText };
  }

  cancelToken = new AbortController();
  submit = e => {
    e.preventDefault();
    const { title, description, logo, programId } = this.state;
    if (title === '' || description === '' || logo === '') {
      return NotificationManager.error(
        'One of your inputs is blank or invalid.'
      );
    } else {
      const data = new FormData();
      data.append('logo', logo);
      data.append('title', title);
      data.append('description', description);
      let lastProgress = 0;
      this.setState({ contentLabel: logo.name });
      const progress = { ...this.state.uploadProgressState };
      if (this.cancelToken.signal.aborted)
        this.cancelToken = new AbortController();
      // eslint-disable-next-line no-undef
      axios
        .request({
          method: 'post',
          url: `/admins/programs/${programId}/sessions/new`,
          data: data,
          signal: this.cancelToken.signal,
          onUploadProgress: e => {
            lastProgress = progress.progress;
            const p = Math.round(e.progress * 100);
            progress.progress = p;
            this.setState({ uploadProgressState: progress });
          }
        })
        .then(res => {
          if (res.data === 'OK') {
            progress.uploadVariantState = 'success';
            this.setState({ uploadProgressState: progress });
            NotificationManager.success(
              'Your new session was successfully created.'
            );
          } else new Error();
          return res;
        })
        .catch(err => {
          progress.progress = lastProgress;
          progress.uploadVariantState = 'danger';
          this.setState({
            isFailed: true,
            uploadProgressState: progress,
            contentText: `Your submission ${
              err.code !== 'ERR_NETWORK' ? 'was cancelled.' : 'has failed.'
            }`,
            contentLabel: `Submission ${
              err.code !== 'ERR_NETWORK' ? 'CANCELLED' : 'FAILED'
            }`
          });
          if ((err.response || { status: 5000 }).status === 409)
            NotificationManager.error(
              'Your submission conflicts with a Program that already exists.'
            );
          else
            NotificationManager.error(
              'Something went wrong with creating this session.'
            );
          return err;
        });
      this.setState({ isUploading: true });
    }
  };

  updateLogo = logo => {
    this.setState({ logo });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    return this.setState({
      [name]: value
    });
  };

  redirectHome = () => {
    this.setState({ redirect: true });
  };

  render() {
    const { updateLogo, handleChange, submit } = this;
    return (
      <Container fluid>
        <Row>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <div style={{ margin: '3rem' }}>
              <LogoFileDrop updateLogo={updateLogo} />
              <SessionForm onChange={handleChange} />
              <div
                className="d-flex justify-content-center"
                style={{ margin: '0 10rem' }}
              >
                <button
                  className="btn text-danger mx-5"
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </button>
                <button className="btn btn-primary mx-5" onClick={submit}>
                  Continue To Content
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <UploadMediaProgressModal
          isFailed={this.state.isFailed}
          isUploading={this.state.isUploading}
          contentLabel={this.state.contentLabel}
          contentText={this.state.contentText}
          // contentStyle={{}}
          // overlayStyle={{}}
          onCancel={() => {
            if (
              !!this.cancelToken &&
              !this.cancelToken.signal.aborted &&
              !this.state.isFailed &&
              this.state.uploadProgressState.uploadVariantState !== 'success'
            ) {
              this.cancelToken.abort();
              return (this.cancelToken = new AbortController());
            }
            this.setState(
              Object.assign(
                {
                  isUploading: false,
                  isFailed: false,
                  uploadProgressState: {
                    uploadVariantState: 'info',
                    progress: 0
                  }
                },
                this.defaultText
              )
            );
            return this.props.history.push(
              `/admin/dashboard/edit-program/${this.state.programId}`
            );
          }}
          progressState={this.state.uploadProgressState}
        />
      </Container>
    );
  }
}
