import React from 'react';
import css from './SessionCard.module.css';
import { lock } from '../../assets/icons/export';
import sessionImg from './SessionImgEx.jpg';
import { Link } from 'react-router-dom';

const SessionCard = props => {
  const styles = {
    lockedIcon: {
      width: '3rem',
      margin: '0 auto'
    }
  };

  const {
    programId,
    sessionId,
    contentId,
    index,
    title,
    icon,
    description,
    isLocked,
    numContent,
    contentIndexReached
  } = props;

  const determineText = () => {
    if (contentIndexReached === 0) return 'Begin Session';
    if (contentIndexReached === -1 || contentIndexReached === numContent - 1)
      return 'Review Session';
    if (contentIndexReached < numContent) return 'Continue Session';
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          padding: '1rem',
          margin: '1rem 0',
          border: '1px #e3e3ea solid',
          borderRadius: '3px',
          backgroundColor: '#F5F5F9'
          // maxWidth: '90%'
        }}
      >
        <div className="row">
          <div className="col-12 col-lg-3">
            <p>Session {parseInt(index) + 1}</p>
            <img
              src={icon ? icon : sessionImg}
              alt="Session"
              className={`mx-auto d-block ${css.sessionCardImg}`}
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className={css.sessionCardText}>
              <p className={css.sessionCardTitle}>{title}</p>
              <p className={css.sessionCardBody}>{description}</p>
            </div>
          </div>
          <div className="col-12 col-lg-3 align-items-center d-flex">
            {isLocked ? (
              <img src={lock} style={styles.lockedIcon} alt="A lock" />
            ) : contentId ? (
              <Link
                to={`/participant/program/${programId}/session/${sessionId}/content/${contentId}`}
                className="text-white btn btn-sm mx-auto px-5"
                style={{ background: '#3CC480' }}
              >
                {determineText()}
              </Link>
            ) : (
              <button
                className="text-white btn btn-primary btn-sm mx-auto px-5"
                style={{ background: '#3CC480' }}
              >
                No Content Yet
              </button>
            )}
          </div>
        </div>
      </div>
      <hr style={{ margin: '0 2rem' }} />
    </>
  );
};

export default SessionCard;
