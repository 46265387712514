import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CarouselCard from './components/CarouselCard';
import { request, isVideo } from '../../../utils/export';
import PreviewContentModal from './components/PreviewContentModal';

export default class Images extends React.Component {
  state = {
    templates: [],
    isContentPreviewModalOpen: false,
    isSelectionModalOpen: false,
    currentContentId: '',
    currentSessionId: ''
  };
  componentDidMount = () => {
    request('/admins/media-library/templates/get', this.props).then(json =>
      this.setState({ templates: json.templates })
    );
  };
  openContentPreviewModal = e => {
    const contentId = e.target.getAttribute('contentid');
    const sessionId = e.target.getAttribute('sessionid');

    this.setState({
      isContentPreviewModalOpen: true,
      currentSessionId: sessionId,
      currentContentId: contentId
    });
  };

  closeContentPreviewModal = e => {
    this.setState({
      isContentPreviewModalOpen: false
    });
  };

  openSelectionModal = e => {
    this.setState({
      isSelectionModalOpen: true
    });
  };

  closeSelectionModal = e => {
    this.setState({
      isSelectionModalOpen: false
    });
  };

  render() {
    const {
      templates,
      isContentPreviewModalOpen,
      isSelectionModalOpen,
      currentContentId,
      currentSessionId
    } = this.state;

    const {
      openContentPreviewModal,
      closeContentPreviewModal,
      openSelectionModal,
      closeSelectionModal
    } = this;

    return (
      <Container fluid style={{ margin: '4rem 0' }}>
        <Row nogutters="true">
          <Col xs={12}>
            <div className="d-flex align-content-center">
              <h6>
                <strong>Templates</strong>
              </h6>
            </div>
            <hr className="mr-5" />
            {templates.map((t, key) => {
              return (
                <CarouselCard
                  media={t.media}
                  title={t.title}
                  type={t.type}
                  text={t.text}
                  programId={t.programId}
                  sessionId={t.sessionId}
                  contentId={t._id}
                  key={key}
                  isTemplate={true}
                  isVideo={t.media && isVideo(t.media)}
                  onClick={openContentPreviewModal}
                />
              );
            })}
          </Col>
        </Row>
        <PreviewContentModal
          isOpen={isContentPreviewModalOpen}
          onRequestClose={closeContentPreviewModal}
          isSelectionModalOpen={isSelectionModalOpen}
          openSelectionModal={openSelectionModal}
          onSelectionRequestClose={closeSelectionModal}
          sessionId={currentSessionId}
          contentId={currentContentId}
        />
      </Container>
    );
  }
}
