import { Dashboard, Program, Contact, Content } from '../views/Patient/export';

// Patients can view and sign up for programs/courses
const patientRoutes = [
  {
    path: '/participant/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/participant/program/:programId',
    name: 'Program',
    component: Program
  },
  {
    path:
      '/participant/program/:programId/session/:sessionId/content/:contentId',
    name: 'Content',
    component: Content
  },
  {
    path: '/participant/contact',
    name: 'Contact',
    component: Contact
  }
];

export default patientRoutes;
