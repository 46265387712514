import React from 'react';
import css from './ContentCard.module.css';
import { defaultLogo } from '../../../../../utils/export';
export default class Content extends React.Component {
  componentDidMount = () => {
    if (this.props.isVideo) {
      document.getElementById(this.props.contentId).currentTime = 10;
    }
  };
  render() {
    const {
      contentId,
      title,
      image,
      index,
      handleDeleteModalOpen,
      handleEditModalOpen,
      handleViewModalOpen,
      isVideo
    } = this.props;
    const { imageStyle, titleStyle } = css;
    return (
      <div style={{ display: 'table-row' }}>
        {isVideo ? (
          <video src={image} className={imageStyle} id={contentId} />
        ) : (
          <img
            className={imageStyle}
            src={image ? image : defaultLogo}
            alt={`Logo for content titled "${title}"`}
          />
        )}

        <h6 className={titleStyle}>
          {index + 1}: {title ? title : 'Not Named'}
        </h6>
        <button
          className="btn btn-outline-primary btn-sm m-2 font-weight-normal"
          onClick={e => handleViewModalOpen(e)}
        >
          View
        </button>
        <button
          className="btn btn-outline-primary btn-sm m-2 font-weight-normal"
          onClick={e => handleEditModalOpen(e)}
        >
          Edit
        </button>
        <button
          className="btn btn-outline-primary btn-sm m-2 font-weight-normal"
          onClick={e => handleDeleteModalOpen(e)}
          name={contentId}
        >
          Delete
        </button>
        <hr />
      </div>
    );
  }
}
