import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { GlobalContext } from '../../../global/GlobalContext';
import { Container, Row, Col } from 'react-bootstrap';
import LeftContent from '../Components/LeftContent/LeftContent';
import { adminLanding } from '../../../assets/images/export';
import { request } from '../../../utils/export';
import { Checkbox } from '../../../components/export';
export default class AdminLogin extends Component {
  static contextType = GlobalContext;

  state = {
    email: '',
    password: '',
    remember: false
  };

  handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    return this.setState({
      [name]: value
    });
  };

  toggleForgot = () =>
    this.setState(prevState => ({ forgotPass: !prevState.forgotPass }));

  submit = e => {
    e.preventDefault();
    const { email, password, remember } = this.state;
    if (email === '' || password === '')
      return NotificationManager.error("Email or password can't be blank.");
    request('/auth/local/login', this.props, 'POST', '', {
      email,
      password,
      remember
    }).then(json => {
      if (json) {
        localStorage.setItem('token', json.data.userInfo.token);
        this.context.login();
        return this.props.history.push('/admin/dashboard');
      }
    });
  };

  render() {
    const { submit, handleChange } = this;
    const { email, password, remember } = this.state;

    return (
      <Container fluid style={{ height: '100%', background: '#FFFFFF' }}>
        <Row style={{ height: '100%' }}>
          <LeftContent image={adminLanding} />
          <Col
            xs={12}
            md={6}
            className="border text-center d-flex align-items-center"
            style={{ height: '100%', background: '#FFF' }}
          >
            <div className="mx-auto">
              <h1 className="g-header">Entertain Me Well</h1>
              <h4 className="g-header">Team Manager</h4>
              <p className="g-text-mute font-weight-light">
                Welcome! If you're new, click sign up to create your account.
              </p>
              <form
                onSubmit={submit}
                className="mx-auto"
                style={{ maxWidth: '25rem' }}
              >
                <div className="mx-2">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="g-input-box"
                    onChange={handleChange}
                    value={email}
                  />
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="g-input-box"
                    onChange={handleChange}
                    value={password}
                  />
                </div>
                <div className="d-flex">
                  <div className="d-inline-block mr-auto">
                    <Checkbox
                      checked={remember}
                      onChange={handleChange}
                      name="remember"
                      label="Remember me"
                    />
                  </div>
                  <Link
                    className="ml-auto d-inline-block g-link"
                    to="/admin/forgot-password"
                  >
                    Forgot Password
                  </Link>
                </div>
                <div className="my-4 d-flex">
                  <input
                    type="submit"
                    className="btn btn-signup mx-1 w-100"
                    value="Login"
                  />
                  <Link to="/admin/signup" className="d-inline-block w-100">
                    <button className="btn btn-outline-primary mx-1 w-100">
                      Sign up
                    </button>
                  </Link>
                </div>
              </form>
              <p>
                Are you a study participant? Login{' '}
                <Link className="g-link" to="/participant/login">
                  <u>here</u>
                </Link>
                .
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
