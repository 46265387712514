import React from 'react';
import { Navbar } from '../export';
import { withRouter } from 'react-router-dom';
import { DashboardLayout } from '../export';

function Layout(props) {
  const patientNavLinks = [
    { path: '/participant/dashboard', name: 'Dashboard' }
  ];
  const adminNavLinks = [
    { path: '/admin/dashboard', name: 'Dashboard' },
    { path: '/admin/manage-users', name: 'Manage Users' },
    { path: '/admin/media-library', name: 'Media Library' }
  ];

  let inAdmin = false;
  if (props.location.pathname.includes('/admin')) {
    inAdmin = true;
  }
  return (
    <>
      <Navbar navLinks={inAdmin ? adminNavLinks : patientNavLinks} />
      {inAdmin ? (
        <DashboardLayout inAdmin={inAdmin} links={props.dashboardLinks}>
          {props.children}
        </DashboardLayout>
      ) : (
        <div>{props.children}</div>
      )}
    </>
  );
}

export default withRouter(Layout);
