import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { GlobalContext } from '../../../global/GlobalContext';
import { Container, Row, Col } from 'react-bootstrap';
import LeftContent from '../Components/LeftContent/LeftContent';
import { patientLanding } from '../../../assets/images/export';

export default class ResetPassword extends Component {
  static contextType = GlobalContext;

  state = {
    password: '',
    confirmPassword: '',
    redirect: false
  };

  handleChange = e => {
    return this.setState({
      [e.target.name]: e.target.value
    });
  };

  submit = e => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;
    if (password === '' || confirmPassword === '')
      return NotificationManager.error("Password can't be blank.");
    if (password !== confirmPassword)
      return NotificationManager.error(
        "Both fields don't have the same password"
      );
    fetch('/auth/password-reset/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password,
        confirmPassword,
        token: this.props.match.params.token
      })
    }).then(res => {
      if (res.ok) {
        NotificationManager.success(
          'Success! Password Changed. Please login again.'
        );
        return this.setState({ redirect: true });
      } else
        return NotificationManager.error(
          'Your password reset link has expired. Please get a new one from the login page.'
        );
    });
  };

  render() {
    const { submit, handleChange } = this;
    const { password, confirmPassword, redirect } = this.state;
    if (redirect) return <Redirect to="/participant/login" />;
    return (
      <Container fluid style={{ height: '100%', backgroundColor: '#fff' }}>
        <Row style={{ height: '100%' }}>
          <LeftContent image={patientLanding} />
          <Col
            xs={12}
            md={6}
            className="border text-center d-flex align-items-center"
            style={{ height: '100%' }}
          >
            <div className="mx-auto">
              <h1 className="g-header">Entertain Me Well</h1>
              <p className="g-text-mute font-weight-light">
                Welcome! Please reset your password here.
              </p>
              <form onSubmit={submit}>
                <div className="mx-2">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="g-input-box"
                    onChange={handleChange}
                    value={password}
                  />
                </div>
                <div className="mx-2">
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className="g-input-box"
                    onChange={handleChange}
                    value={confirmPassword}
                  />
                </div>
                <div className="my-4 d-flex">
                  <input
                    type="submit"
                    className="btn btn-primary mx-1 w-100"
                    value="Change Password"
                  />
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
