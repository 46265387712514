import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { GlobalContext } from '../../../global/GlobalContext';
import LeftContent from '../Components/LeftContent/LeftContent';
import { Container, Row, Col } from 'react-bootstrap';
import { request } from '../../../utils/export';
import { adminLanding } from '../../../assets/images/export';
import { Checkbox } from '../../../components/export';
import { checkbox } from '../../../assets/icons/export';
export default class AdminSignup extends Component {
  static contextType = GlobalContext;

  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirm: '',
    isUserInfoSubmitted: false,
    inviteCode: '',
    currentAgreeStatus: false,
    isConfirmed: false,
    isCodeSubmitted: false,
    didAgreeToTerms: false,
    redirect: false
  };

  submitUserInfo = e => {
    e.preventDefault();
    const {
      inviteCode,
      firstName,
      lastName,
      email,
      password,
      passwordConfirm
    } = this.state;

    if (password !== passwordConfirm)
      return NotificationManager.error('Passwords do not match.');
    if (
      password === '' ||
      passwordConfirm === '' ||
      firstName === '' ||
      lastName === '' ||
      !email.includes('@')
    )
      return NotificationManager.error('An input is missing.');
    request('/auth/local/signup', this.props, 'POST', '', {
      inviteCode,
      firstName,
      lastName,
      email,
      password
    }).then(json => {
      if (json && json.data) {
        localStorage.setItem('token', json.data.userInfo.token);
        return this.setState({ isUserInfoSubmitted: true });
      }
    });
  };

  submitInviteCode = e => {
    e.preventDefault();
    const { inviteCode } = this.state;
    request(`/auth/local/admin/${inviteCode}`, this.props).then(res => {
      if (res) return this.setState({ isCodeSubmitted: true });
    });
  };

  handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    return this.setState({ [name]: value });
  };

  resetInviteCode = () => {
    return this.setState({
      inviteCode: '',
      isCodeSubmitted: false,
      isConfirmed: false
    });
  };

  resetUserInfo = () => {
    return this.setState({
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      isUserInfoSubmitted: false
    });
  };

  completeSignup = () => {
    this.context.login();
    return this.props.history.push('/admin/dashboard');
  };

  includeCheckbox = (field, type) => {
    if (field !== '') {
      if (type === 'email' && !field.includes('@')) return;
      return {
        background: `url(${checkbox}) no-repeat scroll 2px 4px`,
        backgroundPosition: 'right',
        backgroundSize: '1rem'
      };
    }
  };

  render() {
    const {
      handleChange,
      submitUserInfo,
      submitInviteCode,
      resetInviteCode,
      includeCheckbox,
      completeSignup
    } = this;
    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      inviteCode,
      isUserInfoSubmitted,
      isCodeSubmitted,
      isConfirmed,
      currentAgreeStatus,
      didAgreeToTerms
    } = this.state;
    return (
      <Container fluid style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <LeftContent image={adminLanding} />
          <Col
            xs={12}
            md={6}
            className="text-center d-flex align-items-center"
            style={{ height: '100%', background: '#FFFFFF' }}
          >
            <div className="mx-auto">
              {isUserInfoSubmitted ? (
                <h3 className="g-header">
                  You've successfully created your account!
                </h3>
              ) : (
                <>
                  <h1 className="g-header">Entertain Me Well</h1>
                  <h4 className="g-header">Team Manager</h4>
                </>
              )}
              {!isCodeSubmitted ? (
                <InviteCodeForm
                  inviteCode={inviteCode}
                  handleChange={handleChange}
                  onSubmit={submitInviteCode}
                />
              ) : !isConfirmed ? (
                <Confirmation
                  onSubmit={() =>
                    this.setState({
                      isConfirmed: true
                    })
                  }
                  onCancel={resetInviteCode}
                />
              ) : !didAgreeToTerms ? (
                <Terms
                  handleChange={handleChange}
                  onCancel={resetInviteCode}
                  currentAgreeStatus={currentAgreeStatus}
                  onContinue={() => this.setState({ didAgreeToTerms: true })}
                  redirectToSignIn={() =>
                    this.props.history.push('/admin/login')
                  }
                />
              ) : !isUserInfoSubmitted ? (
                <UserInfoForm
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  password={password}
                  passwordConfirm={passwordConfirm}
                  handleChange={handleChange}
                  onSubmit={submitUserInfo}
                  onClick={resetInviteCode}
                  includeCheckbox={includeCheckbox}
                />
              ) : (
                <ConfirmationPage onClick={completeSignup} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const InviteCodeForm = props => {
  const { inviteCode, handleChange, onSubmit } = props;
  return (
    <div style={{ maxWidth: '60%', display: 'block', margin: '1.5rem auto' }}>
      <form onSubmit={onSubmit}>
        <p>
          Welcome to Entertain Me Well! This web app is part of a private study.
          Please enter your invite code below to continue to sign up for your
          account.
        </p>
        <div className="mx-5">
          <input
            type="text"
            name="inviteCode"
            className="my-3 g-input-box"
            placeholder="Invite Code"
            value={inviteCode}
            onChange={handleChange}
          />
        </div>
        <div className="my-4 d-flex">
          <input
            type="submit"
            value="Continue"
            className="mx-2 btn btn-signup w-100"
          />
          <Link to="/admin/login" className="mx-2 w-100">
            <button className="btn btn-outline-primary w-100">Cancel</button>
          </Link>
        </div>
      </form>
    </div>
  );
};

const Confirmation = props => {
  const { onCancel, onSubmit } = props;
  return (
    <div style={{ maxWidth: '60%', display: 'block', margin: '1.5rem auto' }}>
      <p className="mt-2 mb-3 g-text-color">
        You're about to sign up as an Entertain Me Well
      </p>
      <strong className="my-3 g-text-color">Team Administrator</strong>
      <p className="mt-3 mb-5 g-text-color">
        If everything looks in order, please hit continue below to complete your
        enrollment. If this does not look correct to you, please select cancel
        below and contact your program administrator.
      </p>
      <div className="my-4 d-flex">
        <button className="mx-2 btn btn-signup w-100" onClick={onSubmit}>
          Continue
        </button>
        <button className="btn btn-outline-primary w-100" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

const ConfirmationPage = props => {
  return (
    <div style={{ marginTop: '4rem' }}>
      <p className="font-weight-normal">You're all set. Shall we get going?</p>
      <p className="font-weight-normal">
        Click OK below to continue to your account
      </p>
      <p className="font-weight-normal">and explore your dashboard.</p>
      <button
        className="btn btn-primary w-100"
        onClick={props.onClick}
        style={{ maxWidth: '10rem', marginTop: '3rem' }}
      >
        OK
      </button>
    </div>
  );
};

const UserInfoForm = props => {
  const {
    firstName,
    lastName,
    email,
    password,
    passwordConfirm,
    handleChange,
    onSubmit,
    onClick,
    includeCheckbox
  } = props;
  return (
    <>
      <div
        style={{
          display: 'block',
          margin: '1.5rem auto',
          maxWidth: '20rem'
        }}
      >
        <input
          type="text"
          name="firstName"
          className="my-3 g-input-box"
          placeholder="First Name"
          value={firstName}
          onChange={handleChange}
          style={includeCheckbox(firstName, 'firstName')}
        />

        <input
          type="text"
          name="lastName"
          className="my-3 g-input-box"
          placeholder="Last Name"
          value={lastName}
          onChange={handleChange}
          style={includeCheckbox(lastName, 'lastName')}
        />
        <input
          type="text"
          name="email"
          className="my-3 g-input-box"
          placeholder="Email"
          value={email}
          onChange={handleChange}
          style={includeCheckbox(email, 'email')}
        />
        <input
          type="password"
          name="password"
          className="my-3 g-input-box"
          placeholder="Password"
          value={password}
          onChange={handleChange}
          style={includeCheckbox(password, 'password')}
        />
        <input
          type="password"
          name="passwordConfirm"
          className="my-3 g-input-box"
          placeholder="Confirm Password"
          value={passwordConfirm}
          onChange={handleChange}
          style={includeCheckbox(passwordConfirm, 'passwordConfirm')}
        />
      </div>
      <div className="my-4 d-flex">
        <button className="mx-2 btn btn-signup w-100" onClick={onSubmit}>
          Continue
        </button>
        <button className="btn btn-outline-primary w-100" onClick={onClick}>
          Cancel
        </button>
      </div>
    </>
  );
};

const Terms = props => {
  const {
    handleChange,
    redirectToSignIn,
    onCancel,
    currentAgreeStatus,
    onContinue
  } = props;
  return (
    <div style={{ maxWidth: '100%', display: 'block', margin: '1.5rem auto' }}>
      <p class="g-text-mute">
        Please agree to the terms of service and consent
      </p>
      <div
        style={{
          overflowY: 'scroll',
          border: '1px solid black',
          maxHeight: '20rem',
          width: '65%',
          margin: '0 auto'
        }}
      >
        <TOS />
      </div>
      <div
        className="d-block text-left"
        style={{ width: '65%', margin: '1.5rem auto' }}
      >
        <Checkbox
          onChange={handleChange}
          checked={currentAgreeStatus}
          name="currentAgreeStatus"
          label="I agree with these terms and conditions"
        />
      </div>
      <div className="my-4 d-flex" style={{ width: '60%', margin: '0 auto' }}>
        <button
          className="mx-2 btn btn-signup w-100"
          onClick={onContinue}
          disabled={!currentAgreeStatus}
        >
          Continue
        </button>
        <button className="btn btn-outline-primary w-100" onClick={onCancel}>
          Cancel
        </button>
      </div>
      <button className="btn btn-link g-text-color" onClick={redirectToSignIn}>
        Already have an account? Sign in.
      </button>
    </div>
  );
};

const TOS = () => (
  <>
    <p>
      <i>Last Revision Date – July 10, 2019</i>
    </p>

    <p>
      <b>Terms of Service </b>
    </p>

    <p>
      The Regents of the University of Michigan (“Michigan”) are making the
      Materials (as defined below) available to you (“You” or “Your”) through
      the Entertain Me Well website located at www.entertainmewell.com (“EMW”).
      Please read these terms of service carefully before using EMW or the EMW
      Materials. These terms of service govern Your access to and use of EMW and
      the EMW Materials and create a binding agreement ( “Agreement”) between
      You and Michigan.
      <br />
      <br />
      IF YOU DON’T UNDERSTAND HOW THIS AGREEMENT APPLIES TO YOUR ACCESS TO OR
      USE OF THE EMW MATERIALS, OR YOU THINK THAT YOUR ACCESS TO OR USE OF THE
      EMW MATERIALS WOULD BE IN VIOLATION OF THIS AGREEMENT, YOU SHOULD CONTACT
      EMW (see section 11) BEFORE YOU ACCESS OR USE THE EMW MATERIALS. YOU MUST
      BE AUTHORIZED TO ACCEPT THESE TERMS AND CONDITIONS AS AN INDIVIDUAL. IF
      YOU DON’T OR CAN’T ACCEPT THESE TERMS AND CONDITIONS PLEASE STOP HERE AND
      DO NOT ACCESS OR USE EMW OR THE EMW MATERIALS. EMW IS NOT INTENTED FOR USE
      BY ANYONE UNDER THE AGE OF 13. IF YOU ARE UNDER 13, YOU ARE NOT PERMITTED
      TO USE EMW.{' '}
    </p>

    <p>
      <b>I. EMW Materials</b>
    </p>

    <p>
      As used in this Agreement, “EMW Materials” means the videos, images, text,
      and related materials provided through or in connection with EMW. Except
      for the access and use rights granted in this Agreement, or as otherwise
      indicated to You separately in writing by Michigan, You have no right,
      title, or interest in or to EMW or the EMW Materials.
    </p>

    <p>
      <b>2. Your Account/Access</b>
    </p>

    <p>
      Before You can access EMW, You need to register and create an account or
      have one created for You by your group leader. By registering for an
      account, You will be sure that all information You provide to EMW or your
      group leader (<i>"Registration Data"</i>) is complete and accurate.
      Michigan reserves the right to refuse access to EMW to anyone at any time
      at its sole discretion. You agree to keep Your Registration Data current
      and complete so that Michigan may send important notices about Your
      account and EMW services from time to time.
    </p>

    <p>
      Regardless of the physical location from which You access or use EMW and
      the EMW Materials, You will be considered to be accessing or using
      information technology of the University of Michigan.{' '}
    </p>

    <p>
      <b>3. Copyright and Other Restrictions</b>
    </p>

    <p>
      Michigan respects copyright law and requires You to do the same. Michigan
      expressly prohibits the use of EMW for the illegal transmission of
      copyrighted material, and will, if notified by content owners, disable
      copyrighted material from being transmitted via EMW.
    </p>

    <p>
      You agree that you will not modify or alter the EMW Materials except as
      authorized in writing. The EMW Materials may contain a proprietary tagging
      system that You agree not to use with any other materials. You also agree
      not to bypass, delete, or disable any copy protection mechanisms or any
      security mechanisms in EMW or the EMW Materials.
    </p>

    <p>
      Any use of EMW or the EMW Materials other than as specifically authorized
      herein, without the express prior written permission of Michigan, is
      prohibited.
    </p>

    <p>
      <b>4. Code of Conduct</b>
    </p>

    <p>
      You are responsible for all data and content that You access from EMW or
      create using the EMW Materials (collectively, “Data”). In addition to any
      other terms and conditions of this Agreement, YOU AGREE TO ABIDE BY THE
      FOLLOWING RULES:
    </p>

    <p className="text-left">
      ● You will not share passwords or other access information or devices or
      otherwise authorize any third party to access or use EMW or the EMW
      Materials on Your behalf.
    </p>

    <p className="text-left">
      ● You will not engage in unlawful, illegal, or fraudulent activity,
      including, without limitation, by engaging in unauthorized access or
      unauthorized use of EMW or the EMW Materials or any accounts, computers,
      or networks related to EMW.
    </p>

    <p className="text-left">
      ● You will not damage, disrupt, interfere with, diminish, or render
      inaccessible or unusable EMW or the EMW Materials, EMW or others’
      equipment or software or others’ data, or attempt to do so, or encourage
      or assist others to do so.
    </p>

    <p className="text-left">
      ● You will not initiate a denial of service attack against EMW or the EMW
      Materials or release a virus, trojan horse, worms or other malware or
      spyware.
    </p>

    <p className="text-left">
      ● You will not use the EMW Materials to perpetrate a hoax or engage in
      phishing schemes or forgery or other similar falsification or manipulation
      of data.
    </p>

    <p className="text-left">
      ● You will not use the EMW Materials to abuse, harass, stalk, threaten, or
      otherwise violate the legal rights of others.
    </p>

    <p className="text-left">
      ● You will not use the EMW Materials to libel or defame others.
    </p>

    <p className="text-left">
      ● You will not resell or charge others for the EMW Materials, either
      directly or indirectly.
    </p>

    <p className="text-left">
      ● You will abide by reasonable administrative directives issued from time
      to time concerning the access or use of EMW or the EMW Materials.
    </p>

    <p>
      <b>5. Suspension and Termination of Your Use of EMW</b>
    </p>

    <p>
      If You breach or violate the terms and conditions of this Agreement, Your
      access to EMW and use of the EMW Materials may be suspended or terminated
      without prior notice to You. You acknowledge that if Your access to and/or
      use of EMW and the EMW Materials is suspended or terminated, it is
      possible that You may no longer have access to the Data that You created.
    </p>

    <p>
      You agree that if You breach this Agreement, Michigan may take any
      available legal actions.
    </p>

    <p>
      <b>6. Changes to Agreement</b>
    </p>

    <p>
      Michigan reserves the right to update and revise this Agreement at any
      time. Michigan will change the “Effective Date” at the top of this page so
      You can determine if the terms of this Agreement have changed since Your
      last visit. You should routinely check the web page to find out if any
      changes have been made that affect Your use of the EMW Materials. Unless
      otherwise specified, changes made to this Agreement will be effective when
      they are posted to the web page. IF YOU DO NOT AGREE WITH ANY CHANGES THAT
      HAVE BEEN MADE, YOU MUST TERMINATE YOUR USE OF EMW ANDTHE EMW MATERIALS.
    </p>

    <p>
      <b>7. Monitoring</b>
    </p>

    <p>
      Michigan may monitor Your use of EMW and the EMW Materials to assess
      compliance with the terms of this Agreement.
    </p>

    <p>
      <b>8. Disclaimer and Limitation of Liability</b>
    </p>

    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EMW AND THE EMW
      MATERIALS ARE BEING PROVIDED TO YOU ON AN “AS IS” BASIS WITHOUT ANY
      REPRESENTATIONS, WARRANTIES OR CONDITIONS, WHETHER EXPRESS OR IMPLIED,
      INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
      PURPOSE, SATISFACTORY QUALITY, TITLE OR NON-INFRINGEMENT. MICHIGAN MAKES
      NO REPRESENTATIONS OR WARRANTIES REGARDING THE SUITABILITY OF EMW OR THE
      EMW MATERIALS FOR YOUR PURPOSES.
    </p>

    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WHATEVER THE LEGAL
      BASIS FOR THE CLAIM, NEITHER MICHIGAN, NOR ANY OF ITS RESPECTIVE
      AFFILIATES, AGENTS, EMPLOYEES OR CONTRACTORS, WILL BE LIABLE TO YOU FOR
      ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, SPECIAL, OR INCIDENTAL
      DAMAGES ARISING IN CONNECTION WITH THIS AGREEMENT, EVEN IF ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES OR IF SUCH POSSIBILITY WAS REASONABLY
      FORESEEABLE.
      <br />
      <br />
      IF YOU ARE DISSATISFIED WITH EMW AND/OR THE EMW MATERIALS FOR ANY REASON,
      OR WITH ANY OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOUR SOLE AND
      EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING EMW AND THE EMW
      MATERIALS.
    </p>

    <p>
      THE EMW MATERIALS HAVE NOT BEEN EVALUATED BY THE FEDERAL DRUG
      ADMINISTRATION NOR APPROVED BY ANY OTHER GOVERNMENT OR OFFICIAL BODY.
      NOTHING IN THE EMW MATERIALS IS INTENDED TO BE A SUBSTITUTE FOR
      PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IF YOU HAVE CONCERNS
      ABOUT YOUR HEALTH, YOU SHOULD TALK TO YOUR DOCTOR. IF YOU THINK YOU ARE
      HAVING A MEDICAL EMERGENCY, CALL YOUR HEALTH CARE PROFESSIONAL, OR 911,
      IMMEDIATELY.{' '}
    </p>

    <p>
      <b>9. Governing Law</b>
    </p>

    <p>
      This Agreement will be governed by and construed in accordance with the
      laws of the State of Michigan.
    </p>

    <p>
      <b>10. General</b>
    </p>

    <p>
      Your access to and use of EMW and EMW Materials are governed by this
      Agreement and any other terms agreed to in writing. In the event You have
      any questions about this Agree ment, You should contact
      emwsupport@entertainmewell.com.{' '}
    </p>

    <p>
      If any part of this Agreement is determined to be invalid or unenforceable
      then the invalid or unenforceable provision will be deemed superseded by a
      valid enforceable provision that most closely matches the intent of the
      original provision, and the remainder of this Agreement will continue in
      effect. The failure of Michigan to exercise or enforce any right or
      provision of this Agreement will not constitute a waiver of such right or
      provision. You are prohibited from assigning this Agreement or
      transferring Your rights under this Agreement to anyone else.
    </p>
  </>
);
