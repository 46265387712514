import {
  CreateContent,
  CreateProgram,
  CreateSession,
  // EditContent,
  EditProgram,
  EditSession,
  Dashboard,
  MediaLibrary,
  Videos,
  Images,
  Templates,
  ManageUsers,
  UserDetails,
  UsersList
} from '../views/Admin/export';

// Admins can create, edit, and delete any existing courses
const adminRoutes = [
  {
    path: '/admin/dashboard/create-program',
    name: 'Create Program',
    component: CreateProgram
  },
  {
    path: '/admin/dashboard/:programId/create-session',
    name: 'Create Session',
    component: CreateSession
  },
  {
    path: '/admin/dashboard/:sessionId/create-content',
    name: 'Create Content',
    component: CreateContent
  },
  {
    path: '/admin/dashboard/edit-program/:programId',
    name: 'Edit Program',
    component: EditProgram
  },
  {
    path: '/admin/dashboard/:programId/edit-session/:sessionId',
    name: 'Edit Session',
    component: EditSession
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/admin/media-library',
    name: 'Media Library',
    component: MediaLibrary
  },
  {
    path: '/admin/media-library/videos',
    name: 'Media Library - Videos',
    component: Videos
  },
  {
    path: '/admin/media-library/images',
    name: 'Media Library - Images',
    component: Images
  },
  {
    path: '/admin/media-library/templates',
    name: 'Media Library - Templates',
    component: Templates
  },
  {
    path: '/admin/manage-users',
    name: 'Manage Users',
    component: ManageUsers
  },
  {
    path: '/admin/manage-users/users-list/:programId',
    name: 'Users List',
    component: UsersList
  },
  {
    path: '/admin/manage-users/users-list/:programId/:userId',
    name: 'Users',
    component: UserDetails
  }
];

export default adminRoutes;
