import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import ReactModal from 'react-modal';
import { image } from '../../assets/icons/export';

class UploadMediaProgressModal extends React.Component {
  state = {
    isConfirmed: false
  };

  render() {
    const {
      isFailed,
      isUploading,
      contentLabel,
      contentText,
      contentStyle,
      overlayStyle,
      onCancel,
      /** @type {Array<{uploadVariantState: 'info' | 'success' | 'danger', progress: number}>} */
      progressState
    } = this.props;
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={isUploading}
        contentLabel={contentLabel}
        onRequestClose={() =>
          !isFailed ? this.setState({ isConfirmed: true }) : onCancel()
        }
        style={{
          overlay: Object.assign(
            {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '9999'
            },
            overlayStyle
          ),
          content: Object.assign(
            {
              position: 'relative',
              inset: '50%',
              transform: `translate(-50%, -50%) scale(${window.outerWidth > 2000 ? '3' : window.outerWidth > 1500 ? '2' : '1'})`,
              margin: 'auto',
              display: 'inline-block',
              minWidth: '300px',
              maxWidth: '800px'
            },
            contentStyle
          )
        }}
      >
        <div className="text-center mt-2 font-weight-normal flex flex-column">
          <p>{contentText}</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '10px',
              flexWrap: 'wrap-reverse',
              flexBasis: 'max-content',
              maxWidth: '330px'
            }}
          >
            <div>
              <img
                src={image}
                className="mr-1"
                alt={'Uploading Image:' + contentLabel}
              />
              <span style={{overflowWrap: 'anywhere'}}>{contentLabel}</span>
            </div>
            <div style={{ width: '50%', height: '1rem', alignSelf: 'center', minWidth: '150px', maxWidth: '400px' }}>
              <p
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  width: '46%',
                  minWidth: '150px',
                  maxWidth: '400px',
                  margin: 0,
                  lineHeight: 'calc(1rem - 1px)'
                }}
              >
                {progressState.progress}%
              </p>
              <ProgressBar
                animated
                variant={progressState.uploadVariantState}
                now={progressState.progress}
              />
            </div>
          </div>
          <div className="mx-auto mt-3">
            {progressState.uploadVariantState === 'success' ? (
              <button
                className={
                  'btn btn-' +
                  progressState.uploadVariantState +
                  ' d-inline-block mx-4'
                }
                onClick={onCancel}
              >
                Ok
              </button>
            ) : !this.state.isConfirmed ? (
              <button
                className={
                  'btn btn-' +
                  progressState.uploadVariantState +
                  ' d-inline-block mx-4'
                }
                onClick={() => this.setState({ isConfirmed: true })}
              >
                Cancel
              </button>
            ) : (
              <button
                className={
                  'btn btn-' +
                  progressState.uploadVariantState +
                  ' d-inline-block mx-4'
                }
                onClick={() => {
                  onCancel();
                  this.setState({ isConfirmed: false });
                }}
              >
                Are You <em>Sure?</em>
              </button>
            )}
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default UploadMediaProgressModal;
