import React from 'react';
import { PrivateRoute } from '../components/export';
import { publicRoutes, patientRoutes, adminRoutes } from './export';
import { Error404 } from '../views/Public/export';
import ScrollToTop from '../components/ScrollToTop';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

export default function RenderRoutes() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop>
        <Switch>
          {/* Render the publicRoutes */}
          {publicRoutes.map((prop, key) => (
            <Route
              exact
              path={prop.path}
              key={key}
              component={prop.component}
              name={prop.name}
            />
          ))}
          {/* Render the patientRoutes */}
          {patientRoutes.map((prop, key) => (
            <PrivateRoute
              exact
              path={prop.path}
              key={key}
              component={prop.component}
              name={prop.name}
            />
          ))}
          {/* Render the adminRoutes */}
          {adminRoutes.map((prop, key) => (
            <PrivateRoute
              exact
              path={prop.path}
              key={key}
              component={prop.component}
              name={prop.name}
            />
          ))}
          <Route component={Error404} />;
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}
