import React from 'react';
import { Link } from 'react-router-dom';
import css from './SessionCard.module.css';
import { defaultLogo } from '../../../../../utils/export';

export default function Session(props) {
  const { programId, sessionId, title, image, handleModalOpen } = props;
  const { imageStyle, titleStyle } = css;
  return (
    <div>
      <img
        className={imageStyle}
        src={image ? image : defaultLogo}
        alt={`Logo for course titled "${title}"`}
      />
      <h6 className={titleStyle}>{title}</h6>
      <Link
        className="btn btn-outline-primary btn-sm m-2 font-weight-normal"
        to={`/admin/dashboard/${programId}/edit-session/${sessionId}`}
      >
        Edit
      </Link>
      <button
        className="btn btn-outline-primary btn-sm m-2 font-weight-normal"
        onClick={e => handleModalOpen(e)}
        name={sessionId}
      >
        Delete
      </button>
      <hr />
    </div>
  );
}
