import React, { useEffect } from 'react';
import css from './MediaUpload.module.css';
import { useDropzone } from 'react-dropzone';
import ProgressBar from 'react-bootstrap/ProgressBar';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const MediaUpload = props => {
  const { files, uploadMedia, isVideo, progressState, isUploading } = props;
  const { getRootProps, getInputProps } = useDropzone({
    // TODO: Add more video file type support
    accept: isVideo
      ? 'video/mp4, video/mov'
      : 'image/jpeg, image/png, image/jpg',
    maxSize: isVideo ? '500000000' : '5000000',
    multiple: true,
    onDrop: acceptedFiles => {
      uploadMedia(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const thumbs = files.map((file, index) => {
    const defaultProgressState = { uploadVariantState: 'danger', progress: 0 };
    if (file.type.includes('video')) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100px' }}
        >
          <div style={thumb} key={file.name}>
            <div style={thumbInner}>
              <video src={file.preview} style={img} />
            </div>
          </div>
          <ProgressBar
            style={{ display: !isUploading ? 'none' : 'flex' }}
            animated
            variant={
              (progressState[index] || defaultProgressState).uploadVariantState
            }
            now={(progressState[index] || defaultProgressState).progress}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100px' }}
        >
          <div style={thumb} key={file.name}>
            <div style={thumbInner}>
              <img src={file.preview} style={img} alt="Media thumbnail" />
            </div>
          </div>
          <ProgressBar
            style={{ display: !isUploading ? 'none' : 'flex' }}
            animated
            variant={
              (progressState[index] || defaultProgressState).uploadVariantState
            }
            now={(progressState[index] || defaultProgressState).progress}
          />
        </div>
      );
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const { buttonText } = props;

  return (
    <section className="container" style={{ height: '60%' }}>
      <div {...getRootProps({ className: `dropzone ${css.customDropzone}` })}>
        <input {...getInputProps()} />
        <div className={css.dropzoneContent}>
          <p className="text-center d-block">
            {isVideo
              ? 'Drag and drop or click to upload a .mov, or .mp4 file under 500mb to upload a video'
              : 'Drag and drop or click to upload a .jpeg, .jpg, or .png file under 5mb to upload an image'}
          </p>
          <button
            className="btn btn-primary mx-auto"
            style={{ display: 'list-item' }}
          >
            {buttonText ? buttonText : '+ Add image(s) to the media libary'}
          </button>
        </div>
      </div>
      <aside style={Object.assign({ gap: '15px' }, thumbsContainer)}>
        {thumbs}
      </aside>
    </section>
  );
};

export default MediaUpload;
