import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import assessments from '../../../../global/assessments';
import { withRouter, Prompt } from 'react-router-dom';
import { questionnaire } from '../../../../assets/icons/export';
import './Assessment.module.css';
import classes from './Assessment.module.css';
import TimeTracker from '../../../../utils/timetracker';
import { NotificationManager as Notification } from 'react-notifications';
import UploadMediaProgressModal from '../../../../components/UploadMediaProgressModal/UploadMediaProgressModal';

const zip = (...arr) =>
  Array(Math.max(...arr.map(a => a.length)))
    .fill()
    .map((_, i) => arr.map(a => a[i]));

export default class Assessment extends React.Component {
  state = {
    assessementFinished: false,
    currentQuestionSet: [],
    currentQuestionIndex: 0,
    setSize: 5,
    progress: {},
    questionCount: 0,
    isBlocking: true,
    setIsBlocking: null
  };

  // componentDidMount() {
  //   console.log(this.props);
  // }
  updateProgress(number, answer) {
    let progress = {};
    progress[number] = answer;
    progress = Object.assign(this.state.progress, progress);
    this.setState({ progress });
  }

  getProgress() {
    return Object.keys(this.state.progress).length;
  }

  getAnswer(number, proposal) {
    return this.state.progress[number] === proposal;
  }

  nextSet(data) {
    if (this.state.currentQuestionIndex !== 0)
      return this.setState({ assessementFinished: true });
    const count = (
      data.questions ||
      data.multipleChoice ||
      data.multipleRatingChoices ||
      Object.values(data.questionSets)
        .map(m => m.questions || m.multipleChoice || m.multipleRatingChoices)
        .flat()
    ).length;
    const newSet =
      data.questions ||
      data.multipleChoice ||
      data.multipleRatingChoices ||
      Object.values(data.questionSets)
        .map(m => m.questions || m.multipleChoice || m.multipleRatingChoices)
        .flat();
    // .slice(
    //   this.state.currentQuestionIndex,
    //   this.state.setSize + this.state.currentQuestionIndex
    // );
    this.setState({
      currentQuestionSet: newSet,
      currentQuestionIndex: newSet.length,
      // this.state.currentQuestionIndex + this.state.setSize,
      assessementFinished: newSet.length === 0,
      questionCount: count
    });
  }

  prevSet(data) {
    // const prevSet = this.state.currentQuestionSet;
    // let startSlice =
    //   this.state.currentQuestionIndex -
    //   this.state.setSize * (prevSet.length < this.state.setSize ? 2 : 1);
    // startSlice = startSlice === 1 ? 0 : startSlice;
    // const newSet = (
    //   data.questions ||
    //   data.multipleChoice ||
    //   data.multipleRatingChoices ||
    //   Object.values(data.questionSets)
    //     .map(m => m.questions || m.multipleChoice || m.multipleRatingChoices)
    //     .flat()
    // ).slice(startSlice, startSlice + this.state.setSize);
    this.setState({
      // currentQuestionSet: newSet,
      currentQuestionIndex: 0 //startSlice + (startSlice === 0 ? 1 : 0)
    });
  }

  renderQuestions(currentQuestions, data, isAdmin) {
    const rateKeys = Object.keys(data.rateMap || {});
    const isMultipleChoice = 'multipleChoice' in data;
    const isPartitioned = 'questionSets' in data;
    const labelsFilter = ['increment', 'suffix', 'incremental'];
    let setOfFirstsInSets = [];
    let setOfRemindersInSets = [];
    if (isPartitioned) {
      const sets = Object.values(data.questionSets);
      setOfFirstsInSets = sets
        .map(m => m.multipleRatingChoices.map(c => c.question))
        .map(m => m.filter((_, fi) => fi === 0))
        .flat();
      setOfRemindersInSets = sets.map(m => m.reminder).flat();
    }

    return currentQuestions.map((q, i) => {
      const isDynamicRatings =
        ('multipleRatingChoices' in data &&
          typeof q.ratingChoices !== 'string') ||
        (isPartitioned && 'ratingChoices' in q);
      // const questionNumber =
      //   i +
      //   Math.max(this.state.currentQuestionIndex - this.state.setSize, 0) +
      //   1;
      const questionNumber = i + 1;
      const isString = typeof q === 'string';
      const isCustomRating = typeof q.ratingChoices === 'object';
      const isDoubleLabel =
        'labels' in
        (typeof q.ratingChoices === 'string' ? {} : q.ratingChoices || {});

      return (
        <Row
          key={'question_' + i}
          className="mb-3 align-items-center justify-content-between"
          style={{ paddingLeft: isMultipleChoice ? '240px' : 'initial' }}
        >
          {isPartitioned && setOfFirstsInSets.includes(q.question) ? (
            <div className="mb-3">
              <Col xs={8}>
                <button
                  style={{ float: 'right' }}
                  className="w-100 text-wrap btn btn-outline-primary btn-sm m-0 font-weight-normal mt-1 pr-4 pl-4"
                  dangerouslySetInnerHTML={{
                    __html:
                      setOfRemindersInSets[
                        setOfFirstsInSets.indexOf(q.question)
                      ]
                  }}
                ></button>
              </Col>
            </div>
          ) : null}
          <div
            className={`col-${
              isDoubleLabel || isCustomRating ? '12 mt-3' : isString ? 5 : 6
            } text-left`}
            style={{ maxWidth: !isCustomRating ? '400px' : 'initial' }}
          >
            {questionNumber}. {isString ? q : q.question}
          </div>
          {isString || (!isMultipleChoice && !isDynamicRatings) ? (
            <div
              className={`${classes.minMaxWidth}`}
              style={{ transform: 'translateX(-30px)' }}
            >
              <div
                className={`${classes.minMaxWidth} m-0 row justify-content-between`}
              >
                {[...rateKeys, `${rateKeys.length + (rateKeys[0] === '0' ? 0 : 1)}`].map(k => {
                  return (
                    <div key={k} className="col p-0">
                      <label>
                        {k}
                        <input
                          className="ml-1"
                          type="radio"
                          name={'question_' + questionNumber}
                          id={'question_' + questionNumber}
                          checked={this.getAnswer(questionNumber, k)}
                          onChange={e =>
                            e.target.checked &&
                            this.updateProgress(questionNumber, k)
                          }
                        />
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : isMultipleChoice ? (
            <div className="col-8 mt-4">
              {([...q.choices, 'Prefer Not to Answer'] || []).map((k, i) => {
                return (
                  <Row key={k} className="p-0 justify-content-center ">
                    <input
                      className="ml-1"
                      type="radio"
                      name={'question_' + questionNumber}
                      id={'question_' + questionNumber}
                      checked={this.getAnswer(questionNumber, `${i}`)}
                      onChange={e =>
                        e.target.checked &&
                        this.updateProgress(questionNumber, `${i}`)
                      }
                    />
                    <label
                      style={{ transform: 'translateY(-0.4em)' }}
                      for={'question_' + questionNumber}
                      className="col-5 text-left"
                    >
                      {k}
                    </label>
                  </Row>
                );
              })}
            </div>
          ) : isDynamicRatings ? (
            <div className="col-8" style={{ margin: '1.5rem auto' }}>
              <Row
                style={{ columnGap: '1.5em', rowGap: '2.5em' }}
                className="p-0 justify-content-between mb-5"
              >
                {[
                  ...Object.entries(
                    typeof q.ratingChoices === 'string'
                      ? data[q.ratingChoices]
                      : 'range' in q.ratingChoices
                      ? 'increment' in q.ratingChoices.labels
                        ? Object.fromEntries([
                            ...zip(
                              [...Array(q.ratingChoices.range[1]).keys()].slice(
                                1
                              ),
                              [
                                ...Array(
                                  q.ratingChoices.labels.incremental.reduce(
                                    (a, b) => (a = b + a),
                                    1
                                  )
                                ).keys()
                              ]
                                .filter(
                                  f =>
                                    f % q.ratingChoices.labels.increment === 0
                                )
                                .map(m => m + q.ratingChoices.labels.suffix)
                            )//,
                            // Object.entries(q.ratingChoices.labels)
                            //   .filter(f => !labelsFilter.includes(f[0]))
                            //   .flat(1)
                          ])
                        : Object.assign(
                            {},
                            q.ratingChoices.labels,
                            Object.fromEntries(
                              zip(
                                ...[
                                  [
                                    ...Array(
                                      q.ratingChoices.range.reduce(
                                        (a, r) => a + r,
                                        0
                                      )
                                    ).keys()
                                  ]
                                    .slice(q.ratingChoices.range[0])
                                    .filter(
                                      f =>
                                        !Object.keys(q.ratingChoices.labels)
                                          .map(m => parseInt(m))
                                          .includes(f)
                                    ),
                                  Array(q.ratingChoices.range[1]).fill('')
                                ]
                              ).filter(f => !!f[0])
                            )
                          )
                      : q.ratingChoices
                  ),
                  [undefined, 'Prefer Not to Answer']
                ].map((entry, entryIndex, idx, arr) => {
                  const [k, e] = entry;

                  return (
                    <label
                      key={
                        'question_' +
                        questionNumber +
                        '_' +
                        (k || `${entryIndex + 1}`)
                      }
                    >
                      {k || `${entryIndex + 1}`}
                      <input
                        className="ml-1"
                        type="radio"
                        name={'question_' + questionNumber}
                        id={'question_' + questionNumber}
                        checked={this.getAnswer(
                          questionNumber,
                          k || `${entryIndex + 1}`
                        )}
                        onChange={e =>
                          e.target.checked &&
                          this.updateProgress(
                            questionNumber,
                            k || `${entryIndex + 1}`
                          )
                        }
                      />
                      {isDoubleLabel || isCustomRating ? <span>{e}</span> : ''}
                    </label>
                  );
                })}
              </Row>
            </div>
          ) : null}
        </Row>
      );
    });
  }

  render() {
    const { contentMeta, to } = this.props;
    const { assessment, title } = contentMeta;
    const assessData = assessments[assessment.toUpperCase()];
    const isAdmin = (to || '').indexOf('participant') === -1;
    if (isAdmin) {
      styles.reminder = Object.assign({}, styles.reminder, {
        '--gutter': '23vw',
        width: '100vw'
      });
    }

    return (
      <Container
        style={Object.assign(
          { backgroundColor: 'white' },
          isAdmin
            ? {}
            : {
                // height: '92vh',
                marginBottom: '3.5rem',
                marginTop: '-0.5rem'
              }
        )}
        fluid
      >
        <Prompt
          when={this.state.isBlocking} // <-- blocking condition
          message="You will lose any progress you've made if you go back to the previous screen."
        />
        <Row className="justify-content-center text-center align-content-center h-100 p-0">
          <Col className="p-0" style={{ maxWidth: '1024px' }}>
            <Container
              fluid
              className={`${classes.backfill}`}
              hidden={this.state.currentQuestionIndex !== 0}
            >
              <Col xs={10}>
                <img
                  style={{
                    width: '2rem',
                    position: 'relative',
                    top: '-1rem',
                    left: '-11vw'
                  }}
                  src={questionnaire}
                  alt={`Assessment ${assessment}`}
                />
              </Col>
              <div
                className="text-justify col-6 m-auto"
                dangerouslySetInnerHTML={{ __html: assessData.intro }}
              ></div>
              <Row className="justify-content-center">
                <button
                  className="btn btn-outline-primary btn-sm m-2 font-weight-normal mt-3 pr-4 pl-4"
                  onClick={() => this.nextSet(assessData)}
                >
                  Start
                </button>
              </Row>
            </Container>
            <Container
              fluid
              hidden={
                this.state.assessementFinished ||
                this.state.currentQuestionIndex === 0
              }
            >
              <Row
                className="justify-content-between"
                style={{
                  marginTop: '6rem',
                  position: 'sticky',
                  top: '3.5rem',
                  zIndex: 1030,
                  background: 'white'
                }}
              >
                <Col xs={1}>
                  <img
                    className="h-75"
                    src={questionnaire}
                    alt={`Assessment ${assessment.toUpperCase()}`}
                  />
                </Col>
                <Col xs={5} className="p-0">
                  <button
                    style={{ float: 'right', transform: 'translateX(-30px)' }}
                    className="btn btn-outline-primary btn-sm m-0 font-weight-normal mt-1 pr-4 pl-4"
                  >
                    Progress: {this.getProgress()} of {this.state.questionCount}
                  </button>
                </Col>
              </Row>
              <Row
                hidden={!assessData.showReminderAndScale}
                style={Object.assign(
                  {
                    position: 'sticky',
                    top: '6.5rem',
                    backgroundColor: 'white',
                    zIndex: 1030
                  },
                  styles.reminder
                )}
                className="justify-content-between align-items-lg-center"
              >
                <Row
                  style={{ maxWidth: '1024px' }}
                  className={`justify-content-between ${classes.scaleBanner}`}
                >
                  <Col
                    xs={4}
                    dangerouslySetInnerHTML={{
                      __html:
                        assessData.reminder !== ''
                          ? 'Reminder: ' + assessData.reminder
                          : ''
                    }}
                  ></Col>
                  <Row
                    style={{ transform: 'translateX(-30px)' }}
                    className={`mb-1 mt-1 p-0`}
                  >
                    <Row
                      className={`${classes.minMaxWidth} justify-content-around m-0 p-0`}
                    >
                      {[
                        ...Object.entries(assessData.rateMap || {}),
                        [undefined, 'Prefer Not to Answer']
                      ].map((e, i) => {
                        return (
                          <span className="col p-0 text-wrap" key={e[0]}>
                            {e[0] || i + (Object.keys(assessData.rateMap || {})[0] === '0' ? 0 : 1)}{!!e[1] ? ' = ' + e[1] : ''}
                          </span>
                        );
                      })}
                    </Row>
                  </Row>
                </Row>
              </Row>
              <div
                className={`pt-4`}
                style={{
                  overflowY: 'scroll',
                  overflowX: 'hidden'
                }}
              >
                {this.renderQuestions(
                  this.state.currentQuestionSet,
                  assessData,
                  isAdmin
                )}
              </div>
              <Row
                className="justify-content-between border-top"
                style={isAdmin ? {} : styles.footer}
              >
                <button
                  className="btn btn-outline-primary btn-sm m-2 font-weight-normal mt-3 pr-4 pl-4"
                  onClick={() => this.prevSet(assessData)}
                >
                  &lt; Previous
                </button>
                <button
                  className={`${classes.continueBtn} ${Object.keys(this.state.progress).length !== this.state.currentQuestionSet.length ? classes.disabled : ''} btn btn-outline-primary btn-sm m-2 font-weight-normal mt-3 pr-4 pl-4`}
                  onClick={() => Object.keys(this.state.progress).length !== this.state.currentQuestionSet.length ? alert("You must answer every question to continue.") : this.nextSet(assessData)}
                >
                  Continue
                </button>
              </Row>
            </Container>
            <Container
              fluid
              hidden={!this.state.assessementFinished}
              className={`${classes.backfill}`}
            >
              <Row
                className="justify-content-center"
                dangerouslySetInnerHTML={{ __html: assessData.outro }}
              ></Row>
              <Row className="justify-content-center">
                <FinishButton
                  to={to}
                  assessmentName={assessment}
                  title={title}
                  scores={this.state.progress}
                  blockingState={this.state.isBlocking}
                  unBlockHistory={state => this.setState({ isBlocking: state })}
                />
              </Row>
            </Container>
          </Col>
        </Row>
        <TimeTracker path={`assessment/${assessData.name}`} />
      </Container>
    );
  }
}

let styles = {
  footer: {
    position: 'fixed',
    width: '100vw',
    left: '15px',
    padding: '0 6rem',
    bottom: '0',
    backgroundColor: 'white'
  },
  reminder: {
    // position: 'relative',
    fontSize: '0.95rem',
    minHeight: '2rem'
  }
};

const FinishButton = withRouter(props => {
  if (
    !props.history.length ||
    props.history.location.pathname.indexOf('content') < 0
  )
    return (
      <button className="finish-btn btn btn-outline-primary btn-sm mt-4 m-2 font-weight-normal pr-4 pl-4">
        Continue
      </button>
    );
  const [contentLabel, setContentLabel] = React.useState(props.title);
  const [contentText, setContentText] = React.useState(props.title);
  const [isFailed, setIsFailed] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [progressState, setProgressState] = React.useState({
    uploadVariantState: 'info',
    progress: 0
  });
  const [sessionId, contentId] = props.history.location.pathname
    .match(/\/session\/(.*)\/content\/(.*)$/g)[0]
    .split('/')
    .filter((_, i) => i % 2 === 0 && i !== 0);

  React.useEffect(() => {
    return props.history.listen(function(location, action) {
      console.debug('HISTORY:', ...arguments);
      if (action === 'PUSH' && props.blockingState && !isUploading)
        window.location.reload();
    });
  }, []);

  const successMessage = `Your answers were successfully saved!`;
  let lastProgress = 0;
  let progress = progressState;
  let cancelToken = new AbortController();

  const submitScores = async (name, submission) => {
    if (cancelToken.signal.aborted) cancelToken = new AbortController();

    // setIsUploading(true);
    // eslint-disable-next-line no-undef
    return axios
      .request({
        method: 'post',
        url: `/patients/analytics/assessment-score`,
        data: {
          sessionId,
          contentId,
          submission: Object.assign({ name }, submission)
        },
        signal: cancelToken.signal,
        onUploadProgress: e => {
          lastProgress = progressState.progress;
          const p = Math.round(e.progress * 100);
          progress.progress = p;
          setProgressState(progress);
        }
      })
      .then(res => {
        if (res.data === 'OK') {
          progress.uploadVariantState = 'success';
          setProgressState(progress);
          Notification.success(successMessage);
          setTimeout(() => {
            props.unBlockHistory(false);
            props.history.push(props.to);
            window.location.reload();
          }, 3000)
        } else new Error();
        return res;
      })
      .catch(err => {
        progress.progress = lastProgress;
        progress.uploadVariantState = 'danger';
        setIsFailed(true);
        setProgressState(progress);
        setContentText(
          `Your submission ${
            err.code !== 'ERR_NETWORK' ? 'was cancelled.' : 'has failed.'
          }`
        );
        setContentLabel(
          `Submission ${err.code !== 'ERR_NETWORK' ? 'CANCELLED' : 'FAILED'}`
        );
        Notification.error('Something went wrong with creating this slide.');
        return err;
      });
  };

  return (
    <div
      to={props.to}
      onClick={() => {
        submitScores(props.assessmentName, props.scores).then(res => {
          if (isFailed) return;
          props.unBlockHistory(false);
        });
      }}
    >
      <button className="btn btn-outline-primary btn-sm mt-4 m-2 font-weight-normal pr-4 pl-4">
        Continue
      </button>
      <UploadMediaProgressModal
        isFailed={isFailed}
        isUploading={isUploading}
        contentLabel={contentLabel}
        contentText={contentText}
        onCancel={() => {
          if (
            !!cancelToken &&
            !cancelToken.signal.aborted &&
            !isFailed &&
            progressState.uploadVariantState !== 'success'
          ) {
            cancelToken.abort();
            return (cancelToken = new AbortController());
          }
          props.history.push(props.to);
          window.location.reload();
        }}
        progressState={progressState}
      />
    </div>
  );
});
