import React from 'react';
import css from './SessionForm.module.css';

const SessionForm = props => {
  const { submit, onChange } = props;
  const { programForm, title, description, inputWrapper } = css;
  return (
    <form onSubmit={submit} className={programForm}>
      <div className={inputWrapper}>
        <label>Enter the Title of Your Session</label>
        <input type="text" name="title" onChange={onChange} className={title} />
      </div>
      <div className={inputWrapper}>
        <label>
          Enter a description you would like your users to see when they view
          this session. (2000 character limit).
        </label>
        <textarea
          name="description"
          onChange={onChange}
          className={description}
          maxLength={2000}
        />
      </div>
    </form>
  );
};

export default SessionForm;
