import React from 'react';
import { renderMedia } from '../../../../utils/export';

const CarouselCard = props => {
  const {
    media,
    title,
    sessionId,
    contentId,
    isTemplate,
    onClick,
    isVideo
  } = props;

  const renderCard = () => {
    // If a template but not a video
    if (isTemplate && !isVideo) {
      return (
        <div className="d-inline-block pt-3 pr-3 g-link">
          <img
            src={renderMedia(media)}
            style={{
              width: '14rem',
              height: '8rem',
              borderRadius: '4px',
              border: '1px lightgray solid'
            }}
            alt="Carousel card"
            contentid={contentId}
            sessionid={sessionId}
            onClick={onClick}
          />
          <p className="mt-2 mb-3">{title ? title : ''}</p>
        </div>
      );
    }
    // If not a template but is a video
    else if (!isTemplate && isVideo) {
      return (
        <div className="d-inline-block pt-3 pr-3 g-link">
          <video
            src={media}
            style={{
              width: '14rem',
              height: '8rem',
              borderRadius: '4px',
              border: '1px lightgray solid',
              objectFit: 'cover'
            }}
            onClick={onClick}
          />

          <p className="mt-2 mb-3">{title ? title : ''}</p>
        </div>
      );
      // If a template and also a video
    } else if (isTemplate && isVideo) {
      return (
        <div className="d-inline-block pt-3 pr-3 g-link">
          <video
            src={media}
            style={{
              width: '14rem',
              height: '8rem',
              borderRadius: '4px',
              border: '1px lightgray solid',
              objectFit: 'cover'
            }}
            contentid={contentId}
            sessionid={sessionId}
            onClick={onClick}
          />
          <p className="mt-2 mb-3">{title ? title : ''}</p>
        </div>
      );
    } else {
      return (
        <div className="d-inline-block pt-3 pr-3 g-link">
          <img
            src={renderMedia(media)}
            style={{
              width: '14rem',
              height: '8rem',
              borderRadius: '4px',
              border: '1px lightgray solid'
            }}
            alt="Carousel card"
            onClick={onClick}
          />
          <p className="mt-2 mb-3">{title ? title : ''}</p>
        </div>
      );
    }
  };
  return renderCard();
};

export default CarouselCard;
