import React, { Component } from 'react';
import { GlobalContext } from '../../../global/GlobalContext';
import { Container, Row, Col } from 'react-bootstrap';
import { ProgramCard } from '../../../components/export';
import TimeTracker from '../../../utils/timetracker';
import { request } from '../../../utils/export';
export default class Dashboard extends Component {
  static contextType = GlobalContext;
  state = {
    programData: []
  };

  componentDidMount = () => {
    request('/patients/programs', this.props).then(json => {
      this.setState({ programData: json.data.programData });
    });
  };

  render() {
    const { programData } = this.state;
    return (
      <Container
        className="pt-4"
        style={
          window.innerWidth <= 768
            ? { marginTop: '5rem' }
            : { marginTop: '3.65rem' }
        }
      >
        <Row>
          <Col xs={{ span: 10, offset: 1 }} style={{ padding: '0 4rem' }}>
            <h6>
              <strong>My Programs</strong>
            </h6>
            <hr />
          </Col>
          {programData.map((program, key) => {
            return (
              <Col
                lg={key % 2 !== 1 ? { span: 5, offset: 1 } : { span: 5 }}
                xs={{ span: 10, offset: 1 }}
                style={{ padding: '0 4rem' }}
                key={key}
              >
                <ProgramCard
                  image={program.logo}
                  title={program.title}
                  programId={program.id}
                  date={program.enrolled}
                />
              </Col>
            );
          })}
        </Row>
        <TimeTracker path={this.props.location.pathname} />
      </Container>
    );
  }
}
