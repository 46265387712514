const assessments = {
  "ATQ": {
    "overview": "<p><bold>Assessment Name:</bold>  Automatic Thoughts Questionnaire (ATQ)</p><p><bold>Intro Message:</bold>  The following questionnaire gives various examples of thoughts that pop into people’s heads. Please read each thought and indicate how frequently, if at all, the thought has occurred to you <bold>OVER THE PAST WEEK</bold>. Please read each item carefully and give consideration when selecting your answers.</p><p><bold>Scoring:</bold>  1= not at all, 2= sometimes, 3= moderately often, 4= often, 5= all the time, 6= prefer not to answer</p><p><bold>Number of Questions:</bold>  30</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Automatic Thoughts Questionnaire (ATQ)",
    "shortName": "ATQ",
    "showReminderAndScale": true,
    "intro":
      "The following questionnaire gives various examples of thoughts that pop into peoples heads. Please read each thought and indicate how frequently, if at all, the thought has occurred to you <bold>OVER THE PAST WEEK</bold>. Please read each item carefully and give consideration when selecting your answers:",
    "reminder":
      "Indicate how frequently, if at all, the thought has occurred to you <bold>OVER THE PAST WEEK</bold>.",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "1": "not at all",
      "2": "sometimes",
      "3": "moderately often",
      "4": "often",
      "5": "all the time"
    },
    "calcMap": {
      "forward": {
        "1": "not at all",
        "2": "sometimes",
        "3": "moderately often",
        "4": "often",
        "5": "all the time"
      },
      "reverse": {
        "5": "1",
        "4": "2",
        "3": "3",
        "2": "4",
        "1": "5"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "forward",
        "3": "forward",
        "4": "forward",
        "5": "forward",
        "6": "forward",
        "7": "forward",
        "8": "forward",
        "9": "forward",
        "10": "forward",
        "11": "forward",
        "12": "forward",
        "13": "forward",
        "14": "forward",
        "15": "forward",
        "16": "forward",
        "17": "forward",
        "18": "forward",
        "19": "forward",
        "20": "forward",
        "21": "forward",
        "22": "forward",
        "23": "forward",
        "24": "forward",
        "25": "forward",
        "26": "forward",
        "27": "forward",
        "28": "forward",
        "29": "forward",
        "30": "forward"
      }
    },
    "questions": [
      "I feel like I'm up against the world.",
      "I'm no good.",
      "Why can't I ever succeed?",
      "No one understands me.",
      "I've let people down.",
      "I don't think I can go on.",
      "I wish I were a better person.",
      "I'm so weak.",
      "My life is not going the way I want.",
      "I'm so disappointed in myself.",
      "Nothing feels good anymore.",
      "I can't understand this anymore.",
      "I can't get started.",
      "What's wrong with me?",
      "I wish I were somewhere else.",
      "I can't get things together.",
      "I hate myself.",
      "I'm worthless.",
      "I wish I could just disappear.",
      "What's the matter with me?",
      "I'm a loser.",
      "My life is a mess.",
      "I'm a failure.",
      "I'll never make it.",
      "I feel so helpless.",
      "Something has to change.",
      "There must be something wrong with me.",
      "My future is bleak.",
      "It's just not worth it.",
      "I can't finish anything."
    ]
  },
  "TEMPLATE": {
    "showReminderAndScale": true,
    "intro": "",
    "reminder": "",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "1": "not at all",
      "2": "sometimes",
      "3": "moderately often",
      "4": "often",
      "5": "all the time",
      "6": "prefer not to answer"
    },
    "questions": []
  },
  "BADS": {
    "overview": "<p><bold>Assessment Name:</bold>  Behavioral Activation for Depression Scale (BADS)</p><p><bold>Intro Message:</bold>  Please read each statement carefully and then select the number which best describes how much the statement was true for you <bold>DURING THE PAST WEEK, INCLUDING TODAY</bold>.</p><p><bold>Scoring:</bold>  0= none, 1= , 2= a little, 3= , 4= a lot, 5= , 6= completely, 7= prefer not to answer</p><p><bold>Number of Questions:</bold>  25</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Behavioral Activation for Depression Scale (BADS)",
    "shortName": "BADS",
    "showReminderAndScale": true,
    "intro":
      "Please read each statement carefully and then select the number which best describes how much the statement was true for you <bold>DURING THE PAST WEEK, INCLUDING TODAY</bold>.",
    "reminder":
      "Consider your responses how they applied <bold>during the past week, including today</bold>.",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "0": "not at all",
      "1": "",
      "2": "a little",
      "3": "",
      "4": "a lot",
      "5": "",
      "6": "completely"
    },
    "calcMap": {
      "forward": {
        "0": "not at all",
        "1": "",
        "2": "a little",
        "3": "",
        "4": "a lot",
        "5": "",
        "6": "completely"
      },
      "reverse": {
        "6": "0",
        "5": "1",
        "4": "2",
        "3": "3",
        "2": "4",
        "1": "5",
        "0": "6"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "forward",
        "3": "forward",
        "4": "forward",
        "5": "forward",
        "6": "forward",
        "7": "forward",
        "8": "forward",
        "9": "forward",
        "10": "forward",
        "11": "forward",
        "12": "forward",
        "13": "forward",
        "14": "forward",
        "15": "forward",
        "16": "forward",
        "17": "forward",
        "18": "forward",
        "19": "forward",
        "20": "forward",
        "21": "forward",
        "22": "forward",
        "23": "forward",
        "24": "forward",
        "25": "forward"
      }
    },
    "questions": [
      "I stayed in bed for too long even though I had things to do.",
      "There were certain things that I needed to do that I didn't do.",
      "I am content with the amount and types of things I did.",
      "I engaged in a wide and diverse array of activities.",
      "I made good decisions about what type of activities and/or situations I put myself in.",
      "I was active, but did not accomplish any of my goals for the day.",
      "I was an active person and accomplished the goals I set out to do.",
      "Most of what I did was to escape from or avoid something unpleasant.",
      "I did things to avoid feeling sadness or other painful emotions.",
      "I tried not to think about certain things.",
      "I did things even though they were hard because they fit in with my long-term goals for myself.",
      "I did something that was hard to do but it was worth it.",
      "I spent a long time thinking of over and over about my problems.",
      "I kept trying to think of ways to solve a problem but never tried any of the solutions.",
      "I frequently spent time thinking about my past, people who have hurt me, mistakes I've made, and other bad things in my history.",
      "I did not see any of my friends.",
      "I was withdrawn and quiet, even around people I know well.",
      "I was not social, even though I had opportunities to be.",
      "I push people away with my negativity.",
      "I did things to cut myself off from other people.",
      "I took time off work/school/chores responsibilities simply because I was too tired or didn't feel like going in.",
      "My work/schoolwork/chores/responsibilities suffered because I was not as active as I needed to be.",
      "I structured my day's activities.",
      "I only engage in activities that would distract me from feeling bad.",
      "I began to feel badly when others around me expressed negative feelings or experiences."
    ]
  },
  "EPDS": {
    "overview": "<p><bold>Assessment Name:</bold>  Edinburgh Postnatal Depression Scale (EPDS)</p><p><bold>Intro Message:</bold>  As you are pregnant or have recently had a baby, we would like to know how you are feeling. Please check the answer that comes closest to how you have felt <bold>IN THE PAST 7 DAYS</bold>, not just how you feel today.</p><p><bold>Scoring:</bold>  Questions 1, 2, & 4 (without an *) Are scored 0, 1, 2 or 3 with the top box scored as 0 and the bottom box scored as 3. Questions 3, 5­, 10 (marked with an *) Are reverse scored, with the top box scored as a 3 and the bottom box scored as 0.</p><p><bold>Number of Questions:</bold>  10</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Edinburgh Postnatal Depression Scale (EPDS)",
    "shortName": "EPDS",
    "showReminderAndScale": false,
    "intro":
      "As you were pregnant or have recently had a baby, we would like to know how you are feeling. Please check the answer that comes closest to how you have felt <bold>IN THE PAST 7 DAYS</bold>, not just how you feel today.",
    "reminder": "Check the answer that comes closest to how you have felt <bold>IN THE PAST 7 DAYS</bold>, not just how you feel today.",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "calcMap": {
      "calc": "Object.values({{answers}}).reduce((a, v) => a + parseInt(v, 10), 0)"
    },
    "multipleChoice": [
      {
        "question": "I have been able to laugh and see the funny side of things.",
        "choices": [
          "As much as I always could",
          "Not quite so much now",
          "Definitely not so much now",
          "Not at all"
        ]
      },
      {
        "question": "I have looked forward with enjoyment to things.",
        "choices": [
          "As much as I ever did",
          "Rather less than I used to",
          "Definitely less than I used to",
          "Hardly at all"
        ]
      },
      {
        "question": "I have blamed myself unnecessarily when things went wrong.",
        "choices": [
          "No, never",
          "Not very often",
          "Yes, some of the time",
          "Yes, most of the time"
        ]
      },
      {
        "question": "I have been anxious or worried for no good reason.",
        "choices": [
          "No, not at all",
          "Hardly ever",
          "Yes, sometimes",
          "Yes, very often"
        ]
      },
      {
        "question": "I have felt scared or panicky for no very good reason.",
        "choices": [
          "No, not at all",
          "No, not much",
          "Yes, sometimes",
          "Yes, quite a lot"
        ]
      },
      {
        "question": "Things have been getting on top of me.",
        "choices": [
          "No, I have been coping as well as ever",
          "No, most of the time I have coped quite well",
          "Yes, sometimes I haven't coping as well as usual",
          "Yes, most of the time I haven't been able to cope at all"
        ]
      },
      {
        "question": "I have been so unhappy that I have had difficulty sleeping.",
        "choices": [
          "No, not at all",
          "Not very often",
          "Yes, sometimes",
          "Yes, most of the time"
        ]
      },
      {
        "question": "I felt sad or miserable.",
        "choices": [
          "No, not at all",
          "Not very often",
          "Yes, quite often",
          "Yes, most of the time"
        ]
      },
      {
        "question": "I've been so unhappy that I've been crying.",
        "choices": [
          "No, never",
          "Only occasionally",
          "Yes, quite often",
          "Yes, most of the time"
        ]
      },
      {
        "question": "The thought of harming myself has occurred to me.",
        "choices": [
          "Never",
          "Hardly ever",
          "Sometimes",
          "Yes, quite often"
        ]
      }
    ]
  },
  "USER ENGAGEMENT SCALE -- SHORT FORM": {
    "overview": "<p><bold>Assessment Name:</bold>  User Engagement Scale - Short Form</p><p><bold>Intro Message</bold>:  The following questions ask about your feedback on the Entertain Me Well computer program. This was the computer program that the facilitator used to guide each session with. The response choices are: strongly disagree, disagree, neither agree nor disagree, agree, or strongly agree. Please choose the option that best fits your answer.</p><p><bold>Scoring:</bold>  1= strongly disagree , 2= disagree, 3= neither agree nor disagree, 4= agree, 5= strongly agree, 6= prefer not to answer</p><p><bold>Number of Questions:</bold>  12</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "User Engagement Scale",
    "shortName": "SHORTFORM",
    "showReminderAndScale": true,
    "intro":
      "The following questions ask about your feedback of the Entertainment Me Well computer program. This was the computer program that the facilitator used to guide each session with. The response choices are: strongly disagree, disagree, neither agree nor disagree, agree, or strongly agree. Please choose the option that best fits your answer.",
    "reminder": "",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "1": "strongly disagree",
      "2": "disagree",
      "3": "neither agree nor disagree",
      "4": "agree",
      "5": "strongly agree"
    },
    "calcMap": {
      "forward": {
        "1": "strongly disagree",
        "2": "disagree",
        "3": "neither agree nor disagree",
        "4": "agree",
        "5": "strongly agree"
      },
      "reverse": {
        "5": "1",
        "4": "2",
        "3": "3",
        "2": "4",
        "1": "5"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0) / 12;",
      "questions": {
        "1": "forward",
        "2": "forward",
        "3": "forward",
        "4": "reverse",
        "5": "reverse",
        "6": "reverse",
        "7": "forward",
        "8": "forward",
        "9": "forward",
        "10": "forward",
        "11": "forward",
        "12": "forward"
      }
    },
    "questions": [
      "I lost myself in this experience.",
      "The time I spent using computer program just slipped away.",
      "I was absorbed in this experience.",
      "I felt frustrated while using this computer program.",
      "I found this computer program confusing to use.",
      "Using this computer program was taxing.",
      "This computer program was attractive.",
      "This computer program was aesthetically appealing.",
      "This computer program appealed to my senses.",
      "Using computer program was worthwhile.",
      "My experience was rewarding.",
      "I felt interested in this experience."
    ]
  },
  "THERAPY EVALUATION FORM": {
    "overview": "<p><bold>Assessment Name:</bold>  Therapy Evaluation Form - (CEQ)</p><p><bold>Intro Message:</bold>  In the following assessment, we would like you to indicate how much you believe, <bold><underline>RIGHT NOW</underline></bold>, that the therapy you are receiving will help to improve your lifestyle / functioning. Belief usually has two aspects to it: (1) what one <bold><underline>thinks</bold></underline> will happen and (2) what one <bold><underline>feels</bold></underline> will happen. Sometimes these are similar; sometimes they are different. Please answer the questions below. In the first set, answer in terms of what you <bold><underline>think</bold></underline>. In the second set, answer in terms of what you really and truly <bold><underline>feel</bold></underline>. We do not want your course conveners to ever see these ratings, so please keep the sheet covered when you are done.</p><p><bold>Scoring:</bold>  Variable</p><p><bold>Number of Questions:</bold>  6</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Therapy Evaluation Form (CEQ)",
    "shortName": "CEQ",
    "showReminderAndScale": false,
    "intro":
      "In the following assessment, we would like you to indicate how much you believe, <bold><underline>RIGHT NOW</underline></bold>, that the therapy you are receiving will help to improve your lifestyle / functioning. Belief usually has two aspects to it: (1) what one <bold><underline>thinks</bold></underline> will happen and (2) what one <bold><underline>feels</bold></underline> will happen. Sometimes these are similar; sometimes they are different. Please answer the questions below. In the first set, answer in terms of what you <bold><underline>think</bold></underline>. In the second set, answer in terms of what you really and truly <bold><underline>feel</bold></underline>. We do not want your course conveners to ever see these ratings, so please keep the sheet covered when you are done.",
    "reminder": "",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "calcMap": {
      "calc": "'NO_VALUE'"
    },
    "questionSets": {
      "Set1": {
        "reminder": "<bold>Set 1</bold>: In the first set answer the terms of what you <bold><underline>think</underline></bold>.",
        "multipleRatingChoices": [
          {
            "question":
              "At this point, how logical does the course offered to you seem?",
            "ratingChoices": {
              "range": [1, 9],
              "labels": {
                "1": "Not at all logical",
                "5": "Somewhat logical",
                "9": "Very Logical"
              }
            }
          },
          {
            "question":
              "At this point, how useful do you think this course will be in raising the quality of your functioning?",
            "ratingChoices": {
              "range": [1, 9],
              "labels": {
                "1": "Not at all useful",
                "5": "Somewhat useful",
                "9": "Very useful"
              }
            }
          },
          {
            "question":
              "How confident would you be in the recommending of this course to a friend who experiences similar problems?",
            "ratingChoices": {
              "range": [1, 9],
              "labels": {
                "1": "Not at all confident",
                "5": "Somewhat confident",
                "9": "Very confident"
              }
            }
          },
          {
            "question":
              "By the end of this course, how much improvement in your functioning do you think will occur?",
            "ratingChoices": {
              "range": [1, 12],
              "labels": {
                "incremental": [0, 100],
                "increment": 10,
                "suffix": "%"
              }
            }
          }
        ]
      },
      "Set2": {
        "reminder": "<bold>Set 2</bold>: For this set, close your eyes for a few moments, and try to identify what you really <bold><underline>feel</bold></underline> about the course and its likely success. Then answer the following questions.",
        "multipleRatingChoices": [
          {
          "question":
            "At this point, how much do you really feel that the course will help you to improve your functioning?",
          "ratingChoices": {
            "range": [1, 9],
            "labels": {
              "1": "Not at all",
              "5": "Somewhat",
              "9": "Very"
            }
          }
        },
        {
          "question":
            "By the end of this course, how much improvement in your functioning do you really feel will occur?",
          "ratingChoices": {
            "range": [1, 12],
            "labels": {
              "incremental": [0, 100],
              "increment": 10,
              "suffix": "%"
            }
          }
        }]
      }
    }
  },
  "GAD7": {
    "overview": "<p><bold>Assessment Name:</bold>  GAD7 - Anxiety Assessment</p><p><bold>Intro Message:</bold>  Over the last two weeks, how often have you been bothered by the following problems?</p><p><bold>Scoring:</bold>  0= not at all, 1= several days, 2= more than half the days, 3= nearly every day, 4= prefer not to answer</p><p><bold>Number of Questions:</bold>  8</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Generalized Anxiety Disorder 7 (GAD-7)",
    "shortName": "GAD7",
    "showReminderAndScale": true,
    "intro": "Over the last <bold>two weeks</bold>, how often have you been bothered by the following problems?",
    "reminder": "Over the last <bold>two weeks</bold>, how often have you been bothered by the following problems?",
    "boxQuestion":
      "Over the last two weeks, how often have you been bothered by the following problems?",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "0": "not at all",
      "1": "several days",
      "2": "more than half the days",
      "3": "nearly every day"
    },
    "calcMap": {
      "forward": {
        "0": "not at all",
        "1": "several days",
        "2": "more than half the days",
        "3": "nearly every day"
      },
      "reverse": {
        "3": "0",
        "2": "1",
        "1": "2",
        "0": "3"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "forward",
        "3": "forward",
        "4": "forward",
        "5": "forward",
        "6": "forward",
        "7": "forward"
      }
    },
    "multipleRatingChoices": [
      {
        "question": "Feeling nervous, anxious, or on edge.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Not being able to stop or control worrying.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Worrying too much about different things.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Trouble relaxing.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Being so restless that it is hard to sit still.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Becoming easily annoyed or irritable.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Feeling afraid as if something awful might happen.",
        "ratingChoices": "rateMap"
      },
      {
        "question":
          "If you checked any problems, how difficult have these made it for you to do your work, take care of things at home, or get along with people?",
        "ratingChoices": {
          "1": "Not difficult at all",
          "2": "Somewhat difficult",
          "3": "Very difficult",
          "4": "Extremely difficult"
        }
      }
    ]
  },
  "UCLA LONELINESS SCALE VERSION 3": {
    "overview": "<p><bold>Assessment Name:</bold>  UCLA Loneliness Scale - v3</p><p><bold>Intro Message:</bold>  In the following questions, please indicate how often each of the statements below is descriptive of you.</p><p><bold>Scoring:</bold>  1= never, 2= rarely, 3= sometimes, 4= often, 5= prefer not to answer</p><p><bold>Number of Questions:</bold>  20</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "UCLA Loneliness Scale",
    "shortName": "UCLA",
    "showReminderAndScale": true,
    "intro":
      "In the following questions, please indicate how often each of the statements below are descriptive of you.",
    "reminder": "Please indicate how often each of the statements below are descriptive of you.",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "1": "never",
      "2": "rarely",
      "3": "sometimes",
      "4": "often"
    },
    "calcMap": {
      "forward": {
        "1": "never",
        "2": "rarely",
        "3": "sometimes",
        "4": "often"
      },
      "reverse": {
        "1": "4",
        "2": "3",
        "3": "2",
        "4": "1"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "reverse",
        "2": "forward",
        "3": "forward",
        "4": "forward",
        "5": "reverse",
        "6": "reverse",
        "7": "forward",
        "8": "forward",
        "9": "reverse",
        "10": "reverse",
        "11": "forward",
        "12": "forward",
        "13": "forward",
        "14": "forward",
        "15": "reverse",
        "16": "reverse",
        "17": "forward",
        "18": "forward",
        "19": "reverse",
        "20": "reverse"
      }
    },
    "questions": [
      "How often do you feel that you are 'in tune' with the people around you?",
      "How often do you feel that you like companionship?",
      "How often do you feel that there is no one you can turn to?",
      "How often do you feel alone?",
      "How often do you feel part of a group of friends?",
      "How often do you feel that you have a lot in common with the people around you?",
      "How often do you feel that you are no longer close to anyone?",
      "How often do you feel that your interests and ideas are not shared by those around you?",
      "How often do you feel outgoing and friendly?",
      "How often do you feel close to people?",
      "How often do you feel left out?",
      "How often do you feel that your relationships with others are not meaningful?",
      "How often do you feel that no one really knows you well?",
      "How often do you feel isolated from others?",
      "How often do you feel you can find companionship when you want it?",
      "How often do you feel that there are people who really understand you?",
      "How often do you feel shy?",
      "How often do you feel that people are around you but not with you?",
      "How often do you feel that there are people you can talk to?",
      "How often do you feel that there are people you can turn to?"
    ]
  },
  "PHQ-2": {
    "overview": "<p><bold>Assessment Name:</bold>  Patient Health Questionnaire 2 - (PHQ-2)</p><p><bold>Intro Message:  Over the last 2 weeks</bold>, how often have you been bothered by any of the following problems?</p><p><bold>Scoring:</bold>  0= never, 1= rarely, 2= sometimes, 3= often, 4= prefer not to answer</p><p><bold>Number of Questions:</bold>  2</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Patient Health Questionnaire 2 (PHQ-2)",
    "shortName": "PHQ-2",
    "showReminderAndScale": true,
    "intro":
      "<bold>Over the last 2 weeks</bold>, how often have you been bothered by any of the following problems?",
    "reminder": "<bold>Over the last 2 weeks,</bold> how often have you been bothered by any of the following problems?",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "0": "not at all",
      "1": "several days",
      "2": "more than half the days",
      "3": "nearly every day"
    },
    "calcMap": {
      "forward": {
        "0": "not at all",
        "1": "several days",
        "2": "more than half the days",
        "3": "nearly every day"
      },
      "reverse": {
        "3": "0",
        "2": "1",
        "1": "2",
        "0": "3"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "forward"
      }
    },
    "questions": [
      "Little interest or pleasure in doing things.",
      "Feeling down, depressed, or hopeless."
    ]
  },
  "PHQ-8": {
    "overview": "<p><bold>Assessment Name:</bold>  Patient Health Questionnaire 8 - (PHQ-8)</p><p><bold>Intro Message:  Over the last 2 weeks</bold>, how often have you been bothered by any of the following problems? Read each item carefully, and select your response.</p><p><bold>Scoring:</bold>  0= not at all, 1= several days, 2= more than half the days, 3= nearly every day, 4= prefer not to answer</p><p><bold>Number of Questions:</bold>  8</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Patient Health Questionnaire 8 (PHQ-8)",
    "shortName": "PHQ-8",
    "showReminderAndScale": true,
    "intro":
      "Over the last <bold>2 weeks</bold>, how often have you been bothered by any of the following problems? Read each item carefully, and select to response.",
    "reminder": "<bold>Over the last 2 weeks,</bold> how often have you been bothered by any of the following problems?",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "0": "not at all",
      "1": "several days",
      "2": "more than half the days",
      "3": "nearly every day"
    },
    "calcMap": {
      "forward": {
        "0": "not at all",
        "1": "several days",
        "2": "more than half the days",
        "3": "nearly every day"
      },
      "reverse": {
        "3": "0",
        "2": "1",
        "1": "2",
        "0": "3"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "forward",
        "3": "forward",
        "4": "forward",
        "5": "forward",
        "6": "forward",
        "7": "forward",
        "8": "forward"
      }
    },
    "questions": [
      "Little interest or pleasure in doing things.",
      "Feeling down, depressed, or helpless.",
      "Trouble falling or staying asleep, or sleeping too much.",
      "Feeling tired or having little energy.",
      "Poor appetite or overeating.",
      "Feeling bad about yourself - or that you are a failure or have let yourself or your family down.",
      "Trouble concentrating on things, such as reading the newspaper or watching television.",
      "Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual."
    ]
  },
  "PHQ-9": {
    "overview": "<p><bold>Assessment Name:</bold>  Patient Health Questionnaire 9 - (PHQ-9)</p><p><bold>Intro Message:  Over the last 2 weeks</bold>, how often have you been bothered by any of the following problems? Read each item carefully, and select your response.</p><p><bold>Scoring:</bold>  0= not at all, 1= several days, 2= more than half the days, 3= nearly every day, 4= prefer not to answer</p><p><bold>Number of Questions:</bold>  9</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Patient Health Questionnaire 9 (PHQ-9)",
    "shortName": "PHQ-9",
    "showReminderAndScale": true,
    "intro":
      "Over the last <bold>2 weeks</bold>, how often have you been bothered by any of the following problems? Read each item carefully, and select your response.",
    "reminder": "<bold>Over the last 2 weeks,</bold> how often have you been bothered by any of the following problems?",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "0": "not at all",
      "1": "several days",
      "2": "more than half the days",
      "3": "nearly every day"
    },
    "calcMap": {
      "forward": {
        "0": "not at all",
        "1": "several days",
        "2": "more than half the days",
        "3": "nearly every day"
      },
      "reverse": {
        "3": "0",
        "2": "1",
        "1": "2",
        "0": "3"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "forward",
        "3": "forward",
        "4": "forward",
        "5": "forward",
        "6": "forward",
        "7": "forward",
        "8": "forward",
        "9": "forward"
      }
    },
    "multipleRatingChoices": [
      {
        "question": "Little interest or pleasure in doing things.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Feeling down, depressed, or hopeless.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Trouble falling or staying asleep or sleeping too much.",
        "ratingChoices": "rateMap"
      },
      {
        "question": "Feeling tired or having little energy.",
        "ratingChoices": "rateMap"
      },
      { "question": "Poor appetite or over eating.", "ratingChoices": "rateMap" },
      {
        "question":
          "Feeling bad about yourself - or that you are a failure or have let yourself or your family down.",
        "ratingChoices": "rateMap"
      },
      {
        "question":
          "Trouble concentrating on things, such as reading the newspaper or watching television.",
        "ratingChoices": "rateMap"
      },
      {
        "question":
          "Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual.",
        "ratingChoices": "rateMap"
      },
      {
        "question":
          "Thoughts that you would be better off dead or that you want to hurt yourself.",
        "ratingChoices": "rateMap"
      },
      {
        "question":
          "If you checked off any problem on this questionnaire, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?",
        "ratingChoices": {
          "1": "Not difficult at all",
          "2": "Somewhat difficult",
          "3": "Very difficult",
          "4": "Extremely difficult"
        }
      }
    ]
  },
  "GROUP COHESIVENESS SCALE": {
    "overview": "<p><bold>Assessment Name:</bold>  Group Cohesiveness Scale - (GCS)</p><p><bold>Intro Message:</bold>  In the following statements, please indicate how strongly you agree with each of the following statements concerning your experience with the group so far.</p><p><bold>Scoring:</bold>  1= strongly agree, 2= , 3= agree, 4= , 5= strongly agree, 6= prefer not to answer</p><p><bold>Number of Questions:</bold>  7</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Group Cohesiveness Scale (GCS)",
    "shortName": "GCS",
    "showReminderAndScale": true,
    "intro": "In the following statements, please indicate how strongly you agree with each of the following statements concerning your experience with the group so far.",
    "reminder": "Please indicate how strongly you agree with each of the following statements concerning your experience with the group <bole>so far</bold>.",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "1": "strongly disagree",
      "2": "",
      "3": "agree",
      "4": "",
      "5": "strongly agree"
    },
    "calcMap": {
      "forward": {
        "1": "strongly disagree",
        "2": "",
        "3": "agree",
        "4": "",
        "5": "strongly agree"
      },
      "reverse": {
        "5": "1",
        "4": "2",
        "3": "3",
        "2": "4",
        "1": "5"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "forward",
        "3": "forward",
        "4": "forward",
        "5": "forward",
        "6": "forward",
        "7": "forward"
      }
    },
    "questions": [
      "I feel accepted by the group.",
      "In my group, we trust each other.",
      "The members like and care about each other.",
      "The members try to understand why they do the things they do; try to reason it out.",
      "The members feel a sense of participation.",
      "The members appear to do things the way they think will be acceptable to the group.",
      "The members reveal sensitive personal information or feelings."
    ]
  },
  "PSOC": {
    "overview": "<p><bold>Assessment Name:</bold>  Parenting Sense of Competence Scale</p><p><bold>Intro Message:</bold>  In the following statements, please rate the extent to which you agree or disagree with each of them.</p><p><bold>Scoring:</bold>  1= strongly disagree , 2= disagree, 3= somewhat disagree , 4= somewhat agree, 5= agree , 6= strongly agree, 7= prefer not to answer</p><p><bold>Number of Questions:</bold>  17</p><p><bold>Completion Message:</bold>  Thank you for completing the questionnaire! All of your answers have been recorded. Click the button below to continue your session.</p>",
    "longName": "Parenting Sense of Competence Scale (PSOC)",
    "shortName": "PSOC",
    "showReminderAndScale": true,
    "intro": "In the following statements, please rate the extent to which you agree or disagree with each of them.",
    "reminder": "Please rate the extent to which you agree or disagree with each of them.",
    "outro":
      "<p class='m-0 w-100'>Thank you for completing the questionnaire!</p><p class='m-0 w-100'>All of your answers have been recorded.</p><p class='m-0 w-100'>Click the button below to continue your session.</p>",
    "rateMap": {
      "1": "strongly disagree",
      "2": "disagree",
      "3": "somewhat disagree",
      "4": "somewhat agree",
      "5": "agree",
      "6": "strongly agree"
    },
    "calcMap": {
      "forward": {
        "1": "strongly disagree",
        "2": "disagree",
        "3": "somewhat disagree",
        "4": "somewhat agree",
        "5": "agree",
        "6": "strongly agree"
      },
      "reverse": {
        "6": "1",
        "5": "2",
        "4": "3",
        "3": "4",
        "2": "5",
        "1": "6"
      },
      "calc": "const answers = JSON.parse('{{answers}}'); const calcMap = JSON.parse('{{calcMap}}'); Object.entries(calcMap.questions).reduce((a, e) => {return (a + (e[1] === 'reverse' ? parseInt(calcMap.reverse[answers[e[0]]], 10) || 0 : parseInt(answers[e[0]], 10) || 0));}, 0);",
      "questions": {
        "1": "forward",
        "2": "reverse",
        "3": "reverse",
        "4": "reverse",
        "5": "reverse",
        "6": "forward",
        "7": "forward",
        "8": "reverse",
        "9": "reverse",
        "10": "forward",
        "11": "forward",
        "12": "reverse",
        "13": "forward",
        "14": "reverse",
        "15": "forward",
        "16": "reverse",
        "17": "forward"
      }
    },
    "questions": [
      "The problems of taking care of a child are easy to solve once you know how your actions affect your child, an understanding I have acquired.",
      "Even though being a parent could be rewarding, I am frustrated now while my child is at his / her present age.",
      "I go to bed the same way I wake up in the morning, feeling I have not accomplished a whole lot.",
      "I do not know why it is, but sometimes when I'm supposed to be in control, I feel more like the one being manipulated.",
      "My mother was better prepared to be a good mother than I am.",
      "I would make a fine model for a new mother to follow in order to learn what she would need to know in order to be a good parent.",
      "Being a parent is manageable, and any problems are easily solved.",
      "A difficult problem in being a parent is not knowing whether you're doing a good job or a bad one.",
      "Sometimes I feel like I'm not getting anything done.",
      "I meet my own personal expectations for expertise and caring for my child.",
      "If anyone can find the answer to what is troubling my child, I am the one.",
      "My talents and interests are in other areas, not being a parent.",
      "Considering how long I've been a mother, I feel thoroughly familiar with this role.",
      "Is being a mother of a child we're only more interesting, I would be motivated to do a better job as a parent.",
      "I honestly believe I have all the skills necessary to be a good mother to my child.",
      "Being a parent makes me tense and anxious.",
      "Being a good mother is a reward in itself."
    ]
  }
};

export default assessments;
