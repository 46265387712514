import React from 'react';
import { Link } from 'react-router-dom';

const ProgramCard = props => {
  const {
    title,
    date,
    image,
    status,
    numParticipants,
    programId,
    key,
    isAdmin
  } = props;
  const linkStyle = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: '3px',
    borderBottomLeftRadius: '3px'
  };

  if (isAdmin) {
    return (
      <div className="mb-5" key={key}>
        <h6>{title}</h6>
        <p className="mb-2">
          Status:{' '}
          {status.toLowerCase() === 'active' ? (
            <span style={{ color: 'green' }}>Active</span>
          ) : (
            <span style={{ color: 'red' }}>Not Active</span>
          )}
        </p>
        <p className="mb-2">Created On: {date}</p>
        <p className="mb-2">Enrolled Participants: {numParticipants}</p>
        <div className="card" style={{ backgroundColor: 'transparent' }}>
          <img
            src={image}
            alt="Card"
            className="card-img"
            style={{
              height: '16rem',
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
              objectFit: 'cover'
            }}
          />
          <Link
            to={`/admin/dashboard/edit-program/${programId}`}
            className="btn btn-primary"
            style={linkStyle}
          >
            Manage This Program
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="mb-5" key={key}>
      <h6>{title}</h6>
      <p>Enrolled: {date}</p>
      <div className="card">
        <img
          src={image}
          alt="Card"
          className="card-img"
          style={{
            height: '12rem',
            objectFit: 'cover',
            borderBottomRightRadius: '0',
            borderBottomLeftRadius: '0'
          }}
        />
        <Link
          to={`/participant/program/${programId}`}
          className="btn btn-primary"
          style={linkStyle}
        >
          Begin Program
        </Link>
      </div>
    </div>
  );
};

export default ProgramCard;
