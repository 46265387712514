import React from 'react';
import ReactModal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { request, writeRichText } from '../../../../utils/export';
import { Link } from 'react-router-dom';
import css from './SelectionModal.module.css';

class SelectionModal extends React.Component {
  state = {
    programId: '',
    programs: [],
    sessionId: '',
    sessions: []
  };

  componentDidMount = () => {
    request('/admins/media-library/programs', this.props).then(json => {
      this.setState({ programs: json.data.programData });
    });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'programId') {
      request(`/admins/media-library/${value}/sessions`, this.props).then(
        json => {
          this.setState({
            sessions: json.data.sessionData
          });
        }
      );
    }
    return this.setState({
      [name]: value
    });
  };

  render() {
    const { isOpen, onRequestClose, type, text, title } = this.props;
    const { programId, sessionId, sessions, programs } = this.state;
    const { handleChange } = this;
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '10000'
          },
          content: {
            width: '40%',
            height: '60%',
            margin: 'auto',
            overflowX: 'hidden',
            overflowY: 'scroll',
            borderRadius: '0px'
          }
        }}
      >
        <div>
          <div>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <h3 className="d-block text-center mt-2">
                    Insert Content Location
                  </h3>
                </Col>
              </Row>
              <Row style={{ height: '7.5rem', margin: '2.5rem 0' }}>
                <Col xs={12} className="d-flex justify-content-center">
                  <form>
                    <h6>Select Program</h6>
                    <select
                      name="programId"
                      onChange={handleChange}
                      defaultValue="Select Program"
                      className={`${css.customSelect} mb-4`}
                    >
                      <option value="Select Program" disabled>
                        Select Program
                      </option>
                      {programs.map((p, key) => (
                        <option key={key} value={p._id}>
                          {p.title}
                        </option>
                      ))}
                    </select>
                    <h6>Select Session</h6>
                    <select
                      name="sessionId"
                      onChange={handleChange}
                      defaultValue={
                        sessions.length > 0 ? sessions[0]._id : 'Select Session'
                      }
                      className={`${css.customSelect}`}
                    >
                      <option value="Select Session" disabled>
                        Select Session
                      </option>
                      {sessions ? (
                        sessions.map((p, key) => (
                          <option key={key} value={p._id}>
                            {p.title}
                          </option>
                        ))
                      ) : (
                        <option value="No sessions available" disabled>
                          No sessions available
                        </option>
                      )}
                    </select>
                  </form>
                </Col>
                <Col xs={12} className="d-flex">
                  <div className="mt-5 mx-auto justify-self-center">
                    <button
                      className="btn mx-2 btn-primary"
                      onClick={onRequestClose}
                    >
                      Close
                    </button>
                    <Link
                      className="btn btn-primary"
                      to={{
                        pathname: `/admin/dashboard/${programId}/edit-session/${sessionId}`,
                        state: {
                          createContentModalOpen: true,
                          type,
                          text: writeRichText(text),
                          title
                        }
                      }}
                    >
                      Use Template
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default withRouter(SelectionModal);
