import React, { useContext } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Avatar from 'react-avatar';
import css from './Navbar.module.css';
import { caret } from '../../assets/icons/export';
import { GlobalContext } from '../../global/GlobalContext';

function Navbar(props) {
  const context = useContext(GlobalContext);
  const { firstName, lastName } = context.state;
  const fullName = firstName + ' ' + lastName;
  const { navLinks } = props;
  const inAdmin = props.location.pathname.includes('/admin') ? true : false;
  return (
    <nav
      className={`navbar navbar-expand-md navbar-light bg-white fixed-top p-0 ${css.navbar}`}
      style={{
        paddingBottom: window.innerWidth <= 768 ? '3.7rem' : '3.5rem'
      }}
    >
      <Link
        to={inAdmin ? '/admin/dashboard' : '/participant/dashboard'}
        //
        className={`${css.navbarBrand} font-weight-normal`}
        style={
          window.innerWidth <= 768
            ? {
                padding: inAdmin ? '0.6rem' : '1rem',
                width: inAdmin ? '30%' : '60%'
              }
            : {
                padding: inAdmin ? '0.6rem' : '1rem',
                width: inAdmin ? '16.67%' : '25%'
              }
        }
      >
        <p
          className="p-0 m-0 text-center g-header text-white"
          style={{
            fontSize: inAdmin ? '0.85rem' : '1rem',
            letterSpacing: inAdmin ? '0.2rem' : '0.35rem'
          }}
        >
          Entertain Me Well
        </p>
        {inAdmin && (
          <p
            className="p-0 m-0 text-center g-header text-white"
            style={{
              fontSize: '0.75rem',
              letterSpacing: '0.35rem'
            }}
          >
            Team Manager
          </p>
        )}
      </Link>
      <button
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse py-2 px-4"
        id="navbarNavDropdown"
      >
        <ul className={`navbar-nav ml-auto ${css.navbarNav}`}>
          {navLinks
            ? navLinks.map((navLink, key) => {
                return (
                  <li className="nav-item" key={key}>
                    <NavLink
                      className="nav-link font-weight-normal"
                      to={navLink.path}
                      activeClassName="active"
                    >
                      {navLink.name}
                    </NavLink>
                  </li>
                );
              })
            : null}
          <li className={`nav-item ${css.dropdown}`}>
            <button
              className="nav-link"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                backgroundColor: 'transparent',
                backgroundRepeat: 'no-repeat',
                border: 'none',
                cursor: 'pointer',
                overflow: 'hidden',
                outline: 'none'
              }}
            >
              <div>
                <span
                  style={{
                    borderLeft: '1px solid lightgray',
                    paddingLeft: '1.5rem'
                  }}
                >
                  {fullName ? fullName : 'EMW User'}
                </span>
                <img
                  src={caret}
                  style={{
                    width: '0.6rem',
                    marginRight: '0.25rem',
                    marginLeft: '0.7rem',
                    marginBottom: '1px',
                    transform: 'rotate(90deg)'
                  }}
                  alt="Caret icon"
                />
              </div>
            </button>
            <div
              className={`${css.dropdownContent}`}
              aria-labelledby="navbarDropdownMenuLink"
            >
              <Link
                className="g-button-override"
                style={{
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '4px'
                }}
                to="/participant/contact"
              >
                Contact Us
              </Link>
              <button
                onClick={context.logout}
                className="g-button-override"
                style={{
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '4px'
                }}
              >
                Sign Out
              </button>
            </div>
          </li>
          <li>
            <Avatar
              className="pt-1"
              name={fullName ? fullName : 'EMW User'}
              color="#393366"
              background="#393366"
              size="35px"
              textSizeRatio={1.5}
              textMarginRatio={0.25}
              round
            />
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default withRouter(Navbar);
