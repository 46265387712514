import React from 'react';
import css from './LogoFileDrop.module.css';
import { useDropzone } from 'react-dropzone';

const LogoFileDrop = props => {
  const { fileDropWrapper, fileDrop } = css;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxSize: '6000000',
    multiple: false,
    // When the upload is successful, update state
    onDrop: logo => props.updateLogo(logo[0])
  });

  const renderLogo = acceptedFiles.map(file => (
    <img
      key={file.path}
      src={URL.createObjectURL(file)}
      className={css.logoStyle}
      alt="Logo"
    />
  ));

  return (
    <div className={fileDropWrapper} style={props.style && props.style}>
      <div {...getRootProps({ className: `dropzone ${fileDrop}` })}>
        <input {...getInputProps()} />
        {acceptedFiles.length < 1 ? <UploadBox /> : renderLogo}
      </div>
    </div>
  );
};

const UploadBox = () => (
  <>
    <p className="text-center">
      Click or drag and drop a .png, .jpg, or .jpeg file under 5mb to upload an
      image
    </p>
    <span className="btn btn-primary my-auto w-100">+ Add a Logo</span>
  </>
);

export default LogoFileDrop;
