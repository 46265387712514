import React, { Component } from 'react';
import { verify } from 'jsonwebtoken';
import { request } from '../utils/export';

const GlobalContext = React.createContext();

class GlobalContextProvider extends Component {
  state = {
    isLoggedIn: true,
    isAdmin: false,
    firstName: '',
    lastName: '',
    role: ''
  };

  componentWillMount() {
    // Grab current token in localStorage
    const jwt = localStorage.getItem('token');
    if (!jwt) return this.login();
    this.handleJWT(jwt);
  }

  handleJWT = jwt => {
    // Verify that the jwt token is not tampered with
    const token = verify(
      jwt,
      process.env.REACT_APP_AUTH_PUBLIC_KEY.replaceAll('\\\\', '\\').replaceAll(/\n/g, ''),
      { algorithms: ['RS256'] },
      (err, verifiedToken) => {
        if (err)
          return this.setState({
            isLoggedIn: false
          });
        return verifiedToken;
      }
    );
    // If the token is valid, set isLoggedIn to true
    if (token) {
      return this.setState({
        isLoggedIn: true,
        isAdmin: token.isAdmin,
        firstName: token.firstName,
        lastName: token.lastName,
        role: token.role
      });
    } else {
      return this.logout();
    }
  };

  login = () => {
    const jwt = localStorage.getItem('token');
    if (jwt) {
      this.handleJWT(jwt);
    } else {
      request('/auth/logout', this.props, 'GET');
      this.setState({ isLoggedIn: false, isAdmin: false });
    }
  };

  logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  render() {
    const { state, login, logout } = this;
    // This actually passes the context to consumer components
    return (
      <GlobalContext.Provider value={{ state, login, logout }}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

export { GlobalContext, GlobalContextProvider };
