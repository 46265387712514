import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ContinueButton, Editor } from '../../../../components/export';
import css from '../Content.module.css';

const FullText = props => {
  const { title, text, to } = props;
  return (
    <Container style={{ backgroundColor: 'white' }} fluid>
      <Row className="p-0 pb-5">
        <Col xs={12} className="p-0">
          <div className={`${css.fullTextBoxWrapper}`}>
            <h1>{title}</h1>
            <Editor
              readOnly
              toolbarHidden
              transparent
              editorState={text}
              editorClassName={`${css.textBox}`}
            />
            <div className="mt-4">
              <ContinueButton to={to} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FullText;
