import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { NotificationManager } from 'react-notifications';
import { GlobalContext } from '../global/GlobalContext';

export default class TimeTrackerWrapper extends Component {
  constructor(props, context) {
    super(props, context);

    this.componentCleanup = this.componentCleanup.bind(this);
  }
  static contextType = GlobalContext;

  idleTimer = null;

  start = 0;
  end = 0;
  total = 0;

  onPause = () => {
    this.end = this.getCurrentTime();

    this.calculateDiff();
  };
  onResume = () => {
    this.start = this.getCurrentTime();
  };
  calculateDiff = () => {
    this.total += this.end - this.start;
  };

  getCurrentTime = () => {
    if (typeof performance !== 'undefined') {
      return performance.now();
    }

    return Date.now();
  };

  componentWillMount() {
    this.start = this.getCurrentTime();
    // add event listeners

    document.addEventListener('pause', this.onPause, false);
    document.addEventListener('resume', this.onResume, false);
  }

  componentCleanup() {
    document.removeEventListener('pause', this.onPause, false);
    document.removeEventListener('resume', this.onResume, false);
    this.end = this.getCurrentTime();

    this.calculateDiff();

    this.onSave(this.total);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  onSave = time => {
    navigator.sendBeacon(
      '/patients/analytics',
      JSON.stringify({ time: time, path: this.props.path })
    );
  };

  onAction = e => {};

  onActive = e => {};

  onIdle = e => {
    NotificationManager.warning(
      'Session timed out after 20 minutes of inactivity. Logging you out now!'
    );
    this.context.logout();
  };

  render() {
    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={60 * 1000 * 20}
        />
      </>
    );
  }
}
