import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProgramCard from './ProgramCard/ProgramCard';
import SessionCard from './SessionCard/SessionCard';
import { caret } from '../../../../assets/icons/export';
import { NotificationManager } from 'react-notifications';
import { DeleteModal } from '../../../../components/export';
import { Link } from 'react-router-dom';
import { request } from '../../../../utils/export';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const contentText = type =>
  `
    Are you sure you want to delete this ${type}?
    This action cannot be undone and your work will
    be lost forever.
  `;

export default class EditProgram extends React.Component {
  state = {
    programId: this.props.match.params.programId,
    // Dynamically set for view/edit/delete
    sessionId: '',
    sessionDeleteModalOpen: false,
    programDeleteModalOpen: false,
    sessionData: [],
    programData: {
      title: '',
      createdAt: '',
      numParticipants: '',
      logo: '',
      status: ''
    }
  };

  componentDidMount() {
    request(`/admins/programs/${this.state.programId}`, this.props, 'GET').then(
      json => {
        const { sessionData, programData } = json.data;
        this.setState({ sessionData, programData });
      }
    );
  }

  // Programs
  deleteProgram = () => {
    const { programId } = this.state;
    this.setState({ isLoading: true });
    const successMessage = 'Program was successfully deleted.';
    request(
      `/admins/programs/${programId}/delete`,
      this.props,
      'DELETE',
      successMessage,
      {
        programId
      }
    ).then(res => this.props.history.push('/admin/dashboard'));
    this.setState({ isLoading: false, programDeleteModalOpen: false });
  };

  handleProgramDeleteModalOpen = () =>
    this.setState({ programDeleteModalOpen: true });

  handleProgramEditModalOpen = () => {};

  switchStatus = () => {
    this.setState({ isLoading: true });
    const { programId, programData } = this.state;
    fetch(`/admins/programs/${programId}/status`, {
      method: 'PUT',
      'Content-Type': 'application/json',
      body: JSON.stringify({ id: programId })
    })
      .then(res => {
        if (res.ok) {
          let newProgramData = programData;
          newProgramData.status =
            newProgramData.status === 'active' ? 'inactive' : 'active';
          this.setState({ programData: newProgramData });
          return NotificationManager.success(
            `Program status was successfully changed to ${newProgramData.status}.`
          );
        } else new Error();
      })
      .catch(err => {
        return NotificationManager.error(
          'An error occurred while trying to change the status of this program.'
        );
      });
    this.setState({ isLoading: false });
  };

  // Sessions
  deleteSession = () => {
    const { programId, sessionId } = this.state;
    this.setState({ isLoading: true });
    const successMessage = 'Session was successfully deleted.';
    request(
      `/admins/programs/${programId}/sessions/${sessionId}/delete`,
      this.props,
      'DELETE',
      successMessage,
      { sessionId }
    ).then(res => res.ok && window.location.reload());
    this.setState({ isLoading: false, sessionDeleteModalOpen: false });
  };

  handleSessionDeleteModalOpen = e => {
    this.setState({ sessionDeleteModalOpen: true, sessionId: e.target.name });
  };

  handleSessionEditModalOpen = e => {};

  removeSessionFromState = sessionId => {
    this.setState({
      sessionData: this.state.sessionData.filter(item => item.id !== sessionId)
    });
  };

  onSortEnd = movementData => {
    const { oldIndex, newIndex } = movementData;
    this.setState(({ sessionData }) => ({
      sessionData: arrayMove(sessionData, oldIndex, newIndex)
    }));
    this.updateContentOrder();
  };

  updateContentOrder = () => {
    const { originalSessionDataOrder, programId } = this.state;
    const newSessionDataOrder = this.state.sessionData.map(d => d.id);
    if (
      JSON.stringify(newSessionDataOrder) !==
      JSON.stringify(originalSessionDataOrder)
    ) {
      request(`/admins/programs/${programId}/move`, this.props, 'PUT', null, {
        sessionOrder: newSessionDataOrder
      });
    }
  };

  render() {
    const {
      title,
      createdAt,
      numParticipants,
      logo,
      status
    } = this.state.programData;

    const { programId, sessionData } = this.state;

    const {
      deleteSession,
      deleteProgram,
      switchStatus,
      handleSessionDeleteModalOpen,
      handleProgramDeleteModalOpen
    } = this;

    return (
      <Container fluid style={{ marginTop: '4rem' }}>
        <Row>
          <Col xs={12}>
            <div className="d-flex align-content-center">
              <button
                className="btn p-0 mr-1"
                onClick={() => this.props.history.push(`/admin/dashboard`)}
              >
                <span className="font-weight-normal">
                  <img
                    src={caret}
                    style={{
                      width: '0.75rem',
                      transform: 'rotate(180deg)',
                      marginRight: '0.25rem',
                      marginBottom: '1px'
                    }}
                    alt="Back arrow"
                  />
                  Back
                </span>
              </button>
              <h6 className="m-0" style={{ lineHeight: '2rem' }}>
                / Current Programs / <strong>Edit {title}</strong>
              </h6>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <ProgramCard
                image={logo}
                name={title}
                status={status}
                date={createdAt}
                numParticipants={numParticipants}
                switchStatus={switchStatus}
              />
              <div className="align-self-center">
                <Link
                  className="btn btn-primary px-4 mr-5"
                  to={`/admin/dashboard/${programId}/create-session`}
                >
                  <h6 className="m-0">New Session</h6>
                </Link>
              </div>
            </div>
            {/* Delete Session Modal */}
            <DeleteModal
              isOpen={this.state.sessionDeleteModalOpen}
              contentLabel="Delete session"
              onRequestClose={() =>
                this.setState({ sessionDeleteModalOpen: false })
              }
              onDelete={() => deleteSession()}
              ariaHideApp={false}
              contentText={contentText('session')}
            />
            {/* Delete Program Modal */}
            <DeleteModal
              isOpen={this.state.programDeleteModalOpen}
              contentLabel="Delete program"
              onRequestClose={() =>
                this.setState({ programDeleteModalOpen: false })
              }
              onDelete={deleteProgram}
              ariaHideApp={false}
              contentText={contentText('program')}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <SortableList
              onSortEnd={this.onSortEnd}
              axis="xy"
              pressDelay={100}
              sessionData={sessionData}
              programId={programId}
              handleModalOpen={e => handleSessionDeleteModalOpen(e)}
            />
          </Col>
          <button
            className="btn btn-link text-danger py-0"
            onClick={() => handleProgramDeleteModalOpen()}
          >
            <h6>Delete Program</h6>
          </button>
        </Row>
      </Container>
    );
  }
}

const SortableList = SortableContainer(props => {
  const { programId, sessionData, handleModalOpen } = props;
  return (
    <Row>
      {sessionData.map((s, index) => {
        return (
          <SortableItem
            key={index}
            index={index}
            programId={programId}
            sessionId={s.id}
            title={s.title}
            image={s.logo}
            handleModalOpen={handleModalOpen}
          />
        );
      })}
    </Row>
  );
});

const SortableItem = SortableElement(props => {
  const { programId, sessionId, title, image, handleModalOpen } = props;
  return (
    <Col lg={3} sm={4} xs={12}>
      <SessionCard
        programId={programId}
        sessionId={sessionId}
        title={title}
        image={image}
        handleModalOpen={e => handleModalOpen(e)}
      />
    </Col>
  );
});
